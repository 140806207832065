import React from 'react';
import { useImageLoader } from '../hooks/useImageLoader';

interface LogoProps {
	logoUrl: string;
}

const Logo: React.FC<LogoProps> = React.memo(({ logoUrl }) => {
	const image = useImageLoader(logoUrl);

	return image ? (
		<img src={image} alt="Kickoff Logo" width={32} height={32} className="mr-2 md:mr-4" />
	) : (
		<div className="w-8 h-8 bg-gray-200 rounded-full mr-2 md:mr-4"></div>
	);
});

export default Logo;
