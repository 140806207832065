import { useState, useEffect, useMemo } from 'react';
import { Notification, NotificationAPI, ConnectionStatus } from '../models/notification';

interface UseNotificationsOptions {
    accountId: string;
    limit?: number;
}

export const useNotifications = ({ accountId, limit = 20 }: UseNotificationsOptions) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('offline');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleNotification = (notification: Notification) => {
            setNotifications(prev => {
                const updated = [notification, ...prev];
                return limit ? updated.slice(0, limit) : updated;
            });
        };

        const handleStatus = (status: ConnectionStatus) => {
            setConnectionStatus(status);
            setLoading(status === 'connecting');
        };

        // Subscribe to notifications and status updates
        const unsubscribe = NotificationAPI.subscribe(handleNotification);
        const unsubscribeStatus = NotificationAPI.subscribeToStatus(handleStatus);

        // Connect to the notification service
        NotificationAPI.connect(accountId).catch(error => {
            console.error('Failed to connect to notification service:', error);
        });

        return () => {
            unsubscribe();
            unsubscribeStatus();
            NotificationAPI.disconnect();
        };
    }, [accountId, limit]);

    const markAsRead = async (notificationId: string) => {
        try {
            await NotificationAPI.markAsRead(notificationId);
            setNotifications(prev =>
                prev.map(n =>
                    n.notification_id === notificationId
                        ? { ...n, read: true }
                        : n
                )
            );
        } catch (error) {
            console.error('Failed to mark notification as read:', error);
        }
    };

    const deleteNotification = async (notificationId: string) => {
        try {
            await NotificationAPI.deleteNotification(notificationId);
            setNotifications(prev =>
                prev.filter(n => n.notification_id !== notificationId)
            );
        } catch (error) {
            console.error('Failed to delete notification:', error);
        }
    };

    // Sort notifications by priority and time
    const sortedNotifications = useMemo(() => 
        [...notifications].sort((a, b) => 
            b.priority - a.priority || 
            b.notification_time.getTime() - a.notification_time.getTime()
        ),
        [notifications]
    );

    // Calculate unread count
    const unreadCount = useMemo(() => 
        notifications.filter(n => !n.read).length,
        [notifications]
    );

    return {
        notifications: sortedNotifications,
        connectionStatus,
        loading,
        markAsRead,
        deleteNotification,
        unreadCount
    };
};
