import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FriendRequest, User } from '../models/user';
import Loading from '../components/Loading';
import TopNavBar from '../components/TopNavBar';
import { FaUser, FaEdit, FaUserPlus, FaCheck, FaUserTimes, FaHourglassHalf, FaUserFriends, FaEllipsisV, FaEnvelope } from 'react-icons/fa';
import { useImageLoader } from '../hooks/useImageLoader';
import { useFriends } from '../hooks/useFriends';
import { useFriendRequests } from '../hooks/useFriendRequests';
import LoadingButton from '../components/LoadingButton';
import { useOtherUserGames } from '../hooks/useOtherUserGames';
import GamesList from '../components/GamesList';
import { createPortal } from 'react-dom';
import ChatInterface from '../components/ChatInterface';
import { useDMChatroom } from '../models/chatroom';
import { ChatroomService } from '../models/chatroom';

interface UserProfilePageProps {}

const UserProfilePage: React.FC<UserProfilePageProps> = () => {
  const { username } = useParams<{ username: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [creatingDM, setCreatingDM] = useState(false);

  const { incomingRequests, sentRequests, loading: requestsLoading, refresh: refreshRequests } = useFriendRequests();
  const [friendRequestSent, setFriendRequestSent] = useState<boolean>(false);
  const [friendRequestReceived, setFriendRequestReceived] = useState<boolean>(false);
  const { friendStatus, refresh: refreshFriendStatus, loading: friendStatusLoading } = useFriends(user?.user_id);
  const [sendingFriendRequest, setSendingFriendRequest] = useState<boolean>(false);
  const [acceptingFriendRequest, setAcceptingFriendRequest] = useState<boolean>(false);
  const [unfriending, setUnfriending] = useState<boolean>(false);
  const [showUnfriendConfirm, setShowUnfriendConfirm] = useState<boolean>(false);
  const [isUnfriendHovered, setIsUnfriendHovered] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const friendButtonRef = useRef<HTMLDivElement>(null);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [chatRoomId, setChatRoomId] = useState<string>('');


  const handleMessageClick = useCallback(async () => {
    if (!user?.user_id || !currentUser?.user_id) return;

    try {
      setCreatingDM(true);
        // Use the DM chatroom hook
      let chatroom =  await ChatroomService.searchDMChatroom(user.user_id);
      // If no DM exists, create one
      if (!chatroom || !chatroom?.chatroom_id) {
        await ChatroomService.createChatroom({
          name: `${currentUser.username} and ${user.username}`,
          description: '',
          admin_ids: [],
          chatroom_type: 'direct_message',
          members: [currentUser.user_id, user.user_id]
        });
        chatroom =  await ChatroomService.searchDMChatroom(user.user_id);
      }

      // Navigate to the chat page with chatroom ID
      navigate(`/chat/${chatroom.chatroom_id}`);
      setCreatingDM(false);
    } catch (err) {
      console.error('Failed to create or get DM chatroom:', err);
    }
  }, [user, currentUser, navigate]);

  // Add click outside handler for dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.user-actions-dropdown')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Add useEffect for global mouse move tracking
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (friendButtonRef.current) {
        const rect = friendButtonRef.current.getBoundingClientRect();
        const isHovering = (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        );
        setIsUnfriendHovered(isHovering);
      }
    };

    if (friendStatus) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [friendStatus]);

  // Memoize the friend request handlers
  const handleAddFriend = useCallback(async () => {
    if (!user) return;
    setSendingFriendRequest(true);
    try {
      await currentUser?.createFriendRequest(user.user_id);
      setFriendRequestSent(true);
      refreshRequests(); // Refresh friend requests after sending
    } catch (error) {
      console.error(error);
    } finally {
      setSendingFriendRequest(false);
    }
  }, [user, currentUser, refreshRequests]);

  // Update handleUnfriend to use the updated friend status
  const handleUnfriend = useCallback(async () => {
    if (!user) return;
    setUnfriending(true);

    try {
      await User.unfriend(user.user_id);
      refreshFriendStatus();
      refreshRequests(); // Refresh friend requests after unfriending
    } catch (error) {
      console.error(error);
    } finally {
      setUnfriending(false);
      setShowUnfriendConfirm(false);
    }
  }, [user, refreshRequests]);

  // Update handleAcceptFriendRequest
  const handleAcceptFriendRequest = useCallback(async () => {
    if (!user) return;
    setAcceptingFriendRequest(true);

    try {
      const friendRequest = incomingRequests.find(
        (request) => request.requester_id === user.user_id
      );
      if (!friendRequest) return;

      await User.acceptFriendRequest(friendRequest);
      // First update friend status
      await refreshFriendStatus();
      // Then update friend request state
      setFriendRequestReceived(false);
      // Finally refresh requests
      refreshRequests();
    } catch (error) {
      console.error(error);
    } finally {
      setAcceptingFriendRequest(false);
    }
  }, [user, incomingRequests, refreshFriendStatus, refreshRequests]);

  // Add this function to generate a consistent chat room ID
  const getChatRoomId = (id1: string, id2: string): string => {
    // Sort IDs to ensure consistent room ID regardless of order
    const sortedIds = [id1, id2].sort();
    return `${sortedIds[0]}_${sortedIds[1]}`;
  };

  // Update handleStartDM to show chat modal instead of navigating
  const handleStartDM = useCallback(() => {
    if (user && currentUser) {
      setShowChatModal(true);
    }
  }, [user, currentUser]);

  // Memoize the data fetching effect dependencies
  const fetchDependencies = useMemo(() => ({
    username,
    navigate,
    incomingRequests,
    requestsLoading,
  }), [username, navigate, incomingRequests, requestsLoading]);

  // Update the data fetching effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!username) {
          navigate('/404');
          return;
        }
        const userData = await User.fetchUserByUsername(username);
        if (!userData) {
          navigate('/404');
          return;
        }
        setUser(userData);

        const currentUserData = await User.fetchCurrentUser();
        setCurrentUser(currentUserData);

        // Wait for friend requests to load before ending loading state
        if (!requestsLoading) {
          setLoading(false);
        }
      } catch (err) {
        console.error('Failed to load user profile', err);
        setLoading(false);
      }
    };

    fetchData();
  }, [username, navigate, requestsLoading]);

  // Effect for friend request status
  useEffect(() => {
    if (!user || !currentUser || user.user_id === currentUser.user_id || requestsLoading) {
      return;
    }

    const requestReceived = Array.isArray(incomingRequests) && incomingRequests.some(
      (request) =>
        request.requester_id === user.user_id &&
        request.status === 'pending'
    );
    setFriendRequestReceived(requestReceived);

    const requestSent = Array.isArray(sentRequests) && sentRequests.some(
      (request) =>
        request.recipient_id === user.user_id &&
        request.status === 'pending'
    );
    setFriendRequestSent(requestSent);
  }, [user, currentUser, incomingRequests, sentRequests, requestsLoading]);

  const loadedImage = useImageLoader(user ? user.profile_picture_url : null);

  const { games, loading: gamesLoading, error: gamesError, forceRefresh: refreshGames } = useOtherUserGames(user?.user_id);

  // useEffect(() => {
  //   // Call refreshGames on initial load and refresh
  //   refreshGames();

  //   const handleBeforeUnload = () => {
  //     refreshGames();
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  // }, [refreshGames]);

  useEffect(() => {
    if (gamesError) {
      console.error('Failed to load user games:', gamesError);
    }
  }, [gamesError]);

  // Add this function to calculate dropdown position
  const calculateDropdownPosition = (buttonElement: HTMLElement) => {
    const rect = buttonElement.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.right - 192, // 48px (width of dropdown) * 4
    });
  };

  // Update the dropdown toggle function
  const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newShowDropdown = !showDropdown;
    setShowDropdown(newShowDropdown);
    if (newShowDropdown) {
      calculateDropdownPosition(event.currentTarget);
    }
  };

  // Memoize the profile content
  const profileContent = useMemo(() => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-[calc(100vh-64px)] bg-transparent">
          <Loading />
        </div>
      );
    }

    if (error) {
      return <div className="text-red-500 text-center p-4">{error}</div>;
    }

    if (!user) {
      return null;
    }

    return (
      <div className="container mx-auto pt-8 px-4">
        {/* Profile Card */}
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Cover Image */}
          <div className="h-32 bg-gradient-to-r from-gray-200 to-gray-300 relative">
            {/* Edit Profile Icon */}
            {currentUser && user && currentUser.user_id === user.user_id && (
              <Link
                to="/editprofile"
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 border rounded p-1"
              >
                <FaEdit size={24} />
              </Link>
            )}
          </div>

          {/* Profile Info */}
          <div className="relative px-6 pb-6">
            {/* Profile Picture */}
            <div className="absolute -top-16 left-6">
              <div className="relative">
                {loadedImage ? (
                  <img
                    src={loadedImage}
                    alt={`${user.first_name}'s profile`}
                    className="h-32 w-32 rounded-full border-4 border-white object-cover"
                  />
                ) : (
                  <div className="h-32 w-32 rounded-full bg-gray-200 flex items-center justify-center border-4 border-white">
                    <FaUser className="text-gray-400 text-4xl" />
                  </div>
                )}
                {friendStatus && (
                  <div className="absolute bottom-0 right-0 h-8 w-8 rounded-full bg-rose-500 flex items-center justify-center border-2 border-white">
                    <FaUserFriends className="text-white" />
                  </div>
                )}
              </div>
            </div>

            {/* User Info */}
            <div className="pt-20">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-3xl font-semibold text-gray-800">
                    {user.first_name} {user.last_name}
                  </h1>
                  <p className="text-gray-400 text-lg">@{user.username}</p>
                </div>
                {user.location && (
                  <div className="flex items-center text-gray-500">
                    <svg
                      className="h-5 w-5 mr-2 text-gray-400"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    {user.location}
                  </div>
                )}
              </div>

              {/* Tagline */}
              {user.tagline && (
                <p className="mt-4 text-gray-500 italic">"{user.tagline}"</p>
              )}

              {/* Bio */}
              {user.bio && (
                <div className="mt-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-2">About</h2>
                  <p className="text-gray-700 whitespace-pre-wrap">{user.bio}</p>
                </div>
              )}

              {/* Friend Status or Add Friend Button */}
              {currentUser && user && currentUser.user_id !== user.user_id && (
                <div className="mt-4 flex items-center space-x-1">
                  {friendStatusLoading && friendStatus === null ? (
                    <div className="inline-flex items-center px-4 py-2 bg-gray-200 text-gray-600 font-semibold rounded-md shadow-md min-w-[140px] justify-center">
                      <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                          fill="none"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Loading
                    </div>
                  ) : friendStatus?.friended_at ? (
                    <div ref={friendButtonRef}>
                      <button
                        onClick={() => setShowUnfriendConfirm(true)}
                        className={`
                          inline-flex items-center justify-center px-4 py-2 font-semibold rounded-md 
                          shadow-md focus:outline-none transition-all duration-500 ease-in-out
                          min-w-[140px]
                          ${isUnfriendHovered 
                            ? 'bg-red-500 hover:bg-red-600 text-white' 
                            : 'bg-emerald-500 hover:bg-emerald-600 text-white'
                          }
                        `}
                      >
                        <span className="inline-flex items-center justify-center w-full transition-all duration-500 ease-in-out">
                          {isUnfriendHovered ? (
                            <>
                              <FaUserTimes className="w-6 h-4 mr-2" />
                              <span className="w-[64px] text-center mr-2">Unfriend</span>
                            </>
                          ) : (
                            <>
                              <FaUserFriends className="w-6 h-5 mr-2" />
                              <span className="w-[64px] text-center mr-2">Friends</span>
                            </>
                          )}
                        </span>
                      </button>

                      {/* Unfriend Confirmation Modal */}
                      {showUnfriendConfirm && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div
                            className="fixed inset-0 bg-black opacity-50"
                            onClick={() => setShowUnfriendConfirm(false)}
                          ></div>
                          <div className="bg-white rounded-lg shadow-lg p-6 w-80 z-60 relative">
                            <h2 className="text-lg font-semibold mb-4">Unfriend {user.first_name}?</h2>
                            <p className="text-gray-600 mb-6">Are you sure you want to remove this friend?</p>
                            <div className="flex justify-end space-x-2">
                              <LoadingButton
                                isLoading={unfriending}
                                onClick={() => {
                                  setShowUnfriendConfirm(false);
                                  handleUnfriend();
                                }}
                                buttonClass="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none"
                              >
                                Unfriend
                              </LoadingButton>
                              <button
                                onClick={() => {
                                  setShowUnfriendConfirm(false);
                                  setIsUnfriendHovered(false);
                                }}
                                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : friendRequestReceived ? (
                    <LoadingButton
                      isLoading={acceptingFriendRequest}
                      onClick={handleAcceptFriendRequest}
                      buttonClass="inline-flex items-center px-4 py-2 bg-emerald-500 text-white font-semibold rounded-md shadow-md hover:bg-emerald-600 focus:outline-none min-w-[140px]"
                    >
                      <FaCheck className="w-5 h-5 mr-2" />
                      Accept
                    </LoadingButton>
                  ) : friendRequestSent ? (
                    <LoadingButton
                      isLoading={true}
                      onClick={() => {}}
                      buttonClass="inline-flex items-center px-4 py-2 bg-indigo-500 text-white font-semibold rounded-md shadow-md min-w-[140px] justify-center"
                    >
                      <FaHourglassHalf className="w-5 h-5 mr-2" />
                      Requested
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      isLoading={sendingFriendRequest}
                      onClick={handleAddFriend}
                      buttonClass="inline-flex items-center px-4 py-2 bg-rose-500 text-white font-semibold rounded-md shadow-md hover:bg-rose-600 focus:outline-none min-w-[140px] justify-center"
                    >
                      <FaUserPlus className="w-5 h-5 mr-2" />
                      Add Friend
                    </LoadingButton>
                  )}
                  <div className="relative user-actions-dropdown">
                    <button
                      onClick={toggleDropdown}
                      className="p-1 rounded-md hover:bg-gray-100 focus:outline-none transition-colors duration-200"
                    >
                      <FaEllipsisV className="text-gray-600 w-5 h-5" />
                    </button>
                    {showDropdown && createPortal(
                      <div 
                        className="fixed w-48 bg-white rounded-md shadow-lg z-[9999] py-1 font-sans"
                        style={{ 
                          top: `${dropdownPosition.top}px`, 
                          left: `${dropdownPosition.left}px`,
                          fontFamily: 'inherit'
                        }}
                      >
                        <button
                          onClick={() => {
                            // Handle report action
                            setShowDropdown(false);
                          }}
                          className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 transition-colors duration-200 truncate"
                          style={{ fontFamily: 'inherit' }}
                        >
                          Report @{user.username}
                        </button>
                        <button
                          onClick={() => {
                            // Handle block action
                            setShowDropdown(false);
                          }}
                          className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 transition-colors duration-200 truncate"
                          style={{ fontFamily: 'inherit' }}
                        >
                          Block @{user.username}
                        </button>
                      </div>,
                      document.body
                    )}
                  </div>
                  <button
                    onClick={handleMessageClick}
                    className="rounded-md hover:bg-gray-100 focus:outline-none transition-colors duration-200"
                    title="Send Message"
                  >
                    <FaEnvelope className="text-gray-600 w-5 h-5" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* User Games Section */}
        {!gamesLoading && !gamesError && games && games.length > 0 && (
          <div className="mt-6">
            <GamesList games={games} userID={user?.user_id} />
          </div>
        )}
      </div>
    );
  }, [
    loading,
    error,
    user,
    currentUser,
    loadedImage,
    friendStatusLoading,
    friendRequestReceived,
    friendRequestSent,
    sendingFriendRequest,
    acceptingFriendRequest,
    unfriending,
    games,
    gamesLoading,
    gamesError,
    showUnfriendConfirm,
    isUnfriendHovered,
    showDropdown,
    dropdownPosition,
    friendStatus
  ]);

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavBar />
      {profileContent}
    </div>
  );
};

// Memoize the entire component
export default React.memo(UserProfilePage); 