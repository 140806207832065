/**
 * useImageLoader Hook with Persistent Cache
 *
 * This hook manages the loading and caching of images, providing optimized
 * performance and offline capabilities.
 *
 * Functionality:
 * 1. Immediately returns cached image data if available and not expired.
 * 2. Asynchronously loads and caches new images when needed.
 * 3. Caches images in-memory as base64-encoded strings.
 * 4. Implements a cache expiration mechanism (24 hours by default).
 *
 * Usage:
 * const loadedUrl = useImageLoader(url);
 *
 * - url: The source URL of the image to load
 * - loadedUrl: The loaded image data as a base64-encoded string, or null if not loaded
 *
 * The hook will cause a re-render when:
 * - The initial cached image is retrieved
 * - A new image is loaded and cached
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, a new image is fetched and cached
 * - Cache expiration is set to 24 hours by default
 *
 * Note: This hook uses an in-memory cache for caching, so it's subject
 * to storage limitations and should be used for appropriately sized images.
 */

import { useState, useEffect } from 'react';

// Helper functions to interact with localStorage
const loadFromLocalStorage = (key: string): string | null => {
	try {
		const data = localStorage.getItem(key);
		return data;
	} catch (error) {
		console.error('Failed to load from localStorage:', error);
		return null;
	}
};

const saveToLocalStorage = (key: string, data: string) => {
	try {
		localStorage.setItem(key, data);
	} catch (error) {
		console.error('Failed to save to localStorage:', error);
	}
};

export const useImageLoader = (imageUrl: string | null) => {
	const [loadedImage, setLoadedImage] = useState<string | null>(loadFromLocalStorage(imageUrl));

	useEffect(() => {
		if (!imageUrl) {
			setLoadedImage(null);
			return;
		}

		// Check if the image is cached in localStorage
		const cachedImage = loadFromLocalStorage(imageUrl);
		if (cachedImage) {
			if (cachedImage !== loadedImage) {
				setLoadedImage(cachedImage);
			}
			return;
		}

		// Fetch and cache the image
		const img = new Image();
		img.crossOrigin = 'anonymous'; // Enable CORS
		img.src = imageUrl;
		img.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			canvas.width = img.width;
			canvas.height = img.height;
			ctx?.drawImage(img, 0, 0);
			try {
				const base64Data = canvas.toDataURL('image/png');
				// Save to localStorage
				if (base64Data !== loadFromLocalStorage(imageUrl)) {
					saveToLocalStorage(imageUrl, base64Data);
					setLoadedImage(base64Data);
				}
			} catch (error) {
				console.error('Error exporting canvas:', error);
				setLoadedImage(null);
			}
		};
		img.onerror = () => {
			setLoadedImage(null);
		};
	}, [imageUrl]);

	return loadedImage;
};
