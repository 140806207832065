import React from 'react';
import TopNavBar from '../components/TopNavBar';
import { Game } from '../models/games';
import { useNavigate } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import GameForm from '../components/GameForm';

const CreateGamePage: React.FC = () => {
	const navigate = useNavigate();

	const handleCreateGame = async (gameData: Partial<Game>) => {
		try {
			const newGame = new Game(gameData);
			await newGame.create();
			navigate(`/dashboard`);
		} catch (error) {
			console.error('Failed to create game:', error);
		}
	};

	return (
		<div className="min-h-screen bg-gray-50">
			<TopNavBar />
			<div className="container mx-auto px-4 py-8">
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
					<div className="lg:col-span-2"></div>
					<div className="lg:col-span-8 bg-white rounded-lg shadow-lg p-6">
						<GameForm onSubmit={handleCreateGame} onCancel={() => navigate('/dashboard')} />
					</div>
				</div>
			</div>
			<div className="lg:col-span-2"></div>
		</div>
	);
};

export default CreateGamePage;
