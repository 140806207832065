import React from 'react';
import { User } from '../models/user';
import { FaEnvelope } from 'react-icons/fa';

interface AccountSettingsProps {
  user: User;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({ user }) => {
  return (
    <div className="col-span-1">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Account Settings</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700">Email</label>
          <div className="mt-1 flex items-center border border-gray-300 rounded-md bg-gray-100 p-2">
            <FaEnvelope className="ml-2 mr-2 text-gray-400" />
            <p className="text-gray-600">{user.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
