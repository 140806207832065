import React, { useState } from 'react';
import { User } from '../models/user';
import LoadingButton from './LoadingButton';
import { FaLock, FaUnlockAlt } from 'react-icons/fa';
import FormField from './FormField'; // Import the new FormField component

interface SecuritySettingsProps {
  user: User;
  onUpdate: (currentPassword: string, newPassword: string) => Promise<void>;
}

const SecuritySettings: React.FC<SecuritySettingsProps> = ({ user, onUpdate }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);    // Add isUpdating state

  const handleSave = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError('New passwords do not match.');
      return;
    }

    if (newPassword.length < 8) {
      setPasswordError('New password must be at least 8 characters long.');
      return;
    }

    setPasswordError('');
    setIsUpdating(true);    // Start loading

    try {
      await onUpdate(currentPassword, newPassword);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      // You can add a success message here if needed
    } catch (error) {
      console.error('Failed to update password:', error);
      setPasswordError(
        'Failed to update password. Please check your current password and try again.'
      );
    } finally {
      setIsUpdating(false);    // End loading
    }
  };

  return (
    <div className="col-span-1">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Security Settings</h2>
      <div className="space-y-4">
        {/* Current Password */}
        <FormField
          label="Current Password"
          icon={<FaLock className="text-gray-400" />}
          error={passwordError}
        >
          <input
            type="password"
            className="w-full p-2 focus:outline-none rounded-md"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </FormField>

        {/* New Password */}
        <FormField label="New Password" icon={<FaUnlockAlt className="text-gray-400" />}>
          <input
            type="password"
            className="w-full p-2 focus:outline-none rounded-md"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormField>

        {/* Confirm New Password */}
        <FormField label="Confirm New Password" icon={<FaUnlockAlt className="text-gray-400" />}>
          <input
            type="password"
            className="w-full p-2 focus:outline-none rounded-md"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormField>

        {/* Updated LoadingButton */}
        <LoadingButton
          isLoading={isUpdating}
          onClick={handleSave}
          buttonClass="bg-rose-500 hover:bg-rose-600 text-white transform hover:scale-105 shadow-lg"
          loadingText="Updating..."
          aria-label="Updating..."
        >
          Update Password
        </LoadingButton>
      </div>
    </div>
  );
};

export default SecuritySettings;
