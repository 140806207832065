import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
	FaUserCircle,
	FaUserEdit,
	FaSignOutAlt,
	FaTimes,
	FaPlusCircle,
	FaChevronDown,
	FaUserPlus,
	FaFutbol,
	FaEllipsisV,
	FaBell,
	FaUser,
	FaCog,
	FaCheck,
	FaTrash,
	FaComments,
	FaBars,
} from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { User } from '../models/user';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';
import Logo from './Logo';
import SearchBar from './SearchBar';
import { useNotifications } from '../hooks/useNotifications';
import { formatDistanceToNow } from 'date-fns';
import NotificationItem from './NotificationItem';
import { Notification as NotificationType } from '../models/notification';

interface TopNavBarProps {
	onMenuClick?: () => void;
	className?: string;
}

const TopNavBar: React.FC<TopNavBarProps> = ({ onMenuClick, className }) => {
	const navigate = useNavigate();
	const [showDropdown, setShowDropdown] = useState(false);
	const [showMobileSettings, setShowMobileSettings] = useState(false);
	const [showCreateDropdown, setShowCreateDropdown] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const mobileSettingsRef = useRef<HTMLDivElement>(null);
	const createDropdownRef = useRef<HTMLDivElement>(null);
	const [currentUsername, setCurrentUsername] = useState<string>('');
	const [showNotifications, setShowNotifications] = useState(false);
	const notificationsRef = useRef<HTMLDivElement>(null);

	const {
		notifications,
		connectionStatus,
		loading: notificationsLoading,
		markAsRead,
		deleteNotification,
		unreadCount
	} = useNotifications({
		accountId: currentUsername,
		limit: 20
	});

	// Dummy friend requests data for testing
	const dummyFriendRequests = {
		incomingRequests: [],
		sentRequests: [],
		loading: false,
	};

	const { incomingRequests, sentRequests, loading: friendRequestsLoading } = dummyFriendRequests;

	const handleMarkAsRead = async (notificationId: string) => {
		await markAsRead(notificationId);
	};

	const handleSignOut = async () => {
		try {
			const currentUser = await User.fetchCurrentUser();

			await currentUser.signOut();
		} catch (error) {
			console.error('Sign out failed', error);
		}
		navigate('/signin');
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (createDropdownRef.current && !createDropdownRef.current.contains(event.target as Node)) {
				setShowCreateDropdown(false);
			}
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setShowDropdown(false);
			}
			if (notificationsRef.current && !notificationsRef.current.contains(event.target as Node)) {
				setShowNotifications(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		async function fetchUsername() {
			try {
				const currentUser = await User.fetchCurrentUser();
				setCurrentUsername(currentUser.username);
			} catch (error) {
				console.error('Failed to fetch user', error);
			}
		}
		fetchUsername();
	}, []);

	const closeMobileSettings = () => {
		setShowMobileSettings(false);
	};

	const logoUrl = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/2af30e91-2bd6-4d9b-fb3c-b0b9d8bac800/240x240';

	const logo = useMemo(() => <Logo logoUrl={logoUrl} />, [logoUrl]);

	const handleNotificationClick = (requestId: string) => {
		console.log('Friend request clicked:', requestId);
		setShowNotifications(false);
	};

	return (
		<div className={`bg-white shadow-md py-3 px-4 md:px-6 flex items-center justify-between relative ${className} bottom-nav`}>
			{RedirectIfAuthenticated && <RedirectIfAuthenticated />}
			
			{/* Remove mobile menu button and start with Logo */}
			<Link to="/dashboard" className="flex items-center cursor-pointer hidden md:flex">
				{logo}
				<h1 className="text-xl font-bold text-gray-800 -ml-2 hidden sm:block">Kickoff</h1>
			</Link>

			{/* Search bar - hidden on mobile */}
			<div className="hidden md:block flex-1 max-w-xl mx-4">
				<SearchBar />
			</div>

			{/* Bottom Navigation for Mobile */}
			<div className="flex items-center justify-around w-full md:hidden">
				<Link to="/dashboard" className="text-gray-600 hover:text-gray-700 transition duration-300">
					{logo}
				</Link>
				<button 
					onClick={() => {
						navigate('/chat', { replace: true });
						window.scrollTo(0, 0);
					}} 
					className="text-gray-600 hover:text-gray-700 transition duration-300"
				>
					<FaComments size={24} />
				</button>
				<div className="relative" ref={createDropdownRef}>
					<Link
						to="/create-game"
						className="flex items-center text-rose-500 hover:text-rose-600 p-2 rounded-full transition duration-300"
					>
						<FaPlusCircle size={24} />
					</Link>
				</div>
				<div className="relative" ref={notificationsRef}>
					<button
						className="relative flex items-center text-gray-600 hover:text-gray-700 p-2 rounded-full transition duration-300"
						onClick={() => setShowNotifications(!showNotifications)}
					>
						<FaBell size={24} />
						{(unreadCount > 0) && (
							<span className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />
						)}
					</button>
				</div>
				<div className="relative flex items-center" ref={dropdownRef}>
					<button
						onClick={() => setShowDropdown(!showDropdown)}
						className="flex items-center justify-center text-indigo-600 hover:text-indigo-700 p-2 rounded-full transition duration-300"
					>
						<FaUserCircle size={24} />
					</button>
					{showDropdown && (
						<div
							onClick={() => setShowDropdown(false)}
							className="absolute bottom-full right-0 mb-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
						>
							<Link to={`/editprofile`} className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300">
								<FaCog className="mr-3 text-indigo-600" size={18} />
								<span className="align-middle">Settings</span>
							</Link>

							<button
								onClick={handleSignOut}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={18} />
								<span className="align-middle">Sign Out</span>
							</button>
						</div>
					)}
				</div>
			</div>

			{/* Right Side Menu for Desktop */}
			<div className="hidden md:flex items-center space-x-2 md:space-x-4">
				<button 
					onClick={() => {
						navigate('/chat', { replace: true });
						window.scrollTo(0, 0);
					}} 
					className="text-gray-600 hover:text-gray-700 transition duration-300"
				>
					<FaComments size={24} />
				</button>
				
				{/* Create button - simplified on mobile */}
				<div className="relative" ref={createDropdownRef}>
					<Link
						to="/create-game"
						className="flex items-center text-rose-500 hover:text-rose-600 p-2 rounded-full transition duration-300"
					>
						<FaPlusCircle size={24} />
					</Link>
				</div>

				{/* Notification Bell Icon - simplified for mobile */}
				<div className="relative" ref={notificationsRef}>
					<button
						className="relative flex items-center text-gray-600 hover:text-gray-700 p-2 rounded-full transition duration-300"
						onClick={() => setShowNotifications(!showNotifications)}
					>
						<FaBell size={24} />
						{(unreadCount > 0) && (
							<span className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />
						)}
					</button>
					{showNotifications && (
						<div className="absolute right-0 mt-2 w-[calc(100vw-2rem)] md:w-96 max-h-[32rem] bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-auto">
							<div className="sticky top-0 bg-white px-4 py-2 border-b border-gray-200 flex justify-between items-center">
								<h3 className="font-semibold text-gray-700">Notifications</h3>
								{(notificationsLoading || friendRequestsLoading) && (
									<span className="text-sm text-gray-500">Loading...</span>
								)}
							</div>

							{/* Regular Notifications Section */}
							<div>
								{notifications.length > 0 ? (
									notifications.map((notification) => (
										<NotificationItem 
											key={notification.notification_id} 
											notification={notification} 
											onMarkAsRead={handleMarkAsRead}
											connectionStatus={connectionStatus}
										/>
									))
								) : (
									<div className="px-4 py-3 text-sm text-gray-600 text-center">
										{notificationsLoading ? 'Loading notifications...' : 'No notifications'}
									</div>
								)}
							</div>
						</div>
					)}
				</div>

				{/* Profile Dropdown - simplified for mobile */}
				<div className="relative flex items-center" ref={dropdownRef}>
					<button
						onClick={() => setShowDropdown(!showDropdown)}
						className="flex items-center justify-center text-indigo-600 hover:text-indigo-700 p-2 rounded-full transition duration-300"
					>
						<FaUserCircle size={24} />
					</button>

					{showDropdown && (
						<div
							onClick={() => setShowDropdown(false)}
							className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
							style={{ top: '100%' }}
						>
							<Link to={`/editprofile`} className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300">
								<FaCog className="mr-3 text-indigo-600" size={18} />
								<span className="align-middle">Settings</span>
							</Link>

							<button
								onClick={handleSignOut}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={18} />
								<span className="align-middle">Sign Out</span>
							</button>
						</div>
					)}
				</div>
			</div>

			{/* Mobile Settings Modal */}
			{showMobileSettings && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:hidden z-50">
					<div className="absolute inset-0" onClick={closeMobileSettings}></div>
					<div ref={mobileSettingsRef} className="bg-white rounded-t-xl w-full p-4 animate-slide-up relative">
						<div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
						<div className="space-y-4">
							<button
								onClick={() => {
									navigate('/profile');
									closeMobileSettings();
								}}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded-lg"
							>
								<FaUserCircle className="mr-3 text-indigo-600" size={20} />
								<span className="align-middle">Profile</span>
							</button>
							<button
								onClick={() => {
									navigate('/editprofile');
									closeMobileSettings();
								}}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded-lg"
							>
								<FaUserEdit className="mr-3 text-indigo-600" size={20} />
								<span className="align-middle">Edit Profile</span>
							</button>
							<button
								onClick={() => {
									handleSignOut();
									closeMobileSettings();
								}}
								className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300 rounded-lg"
							>
								<FaSignOutAlt className="mr-3 text-rose-600" size={20} />
								<span className="align-middle">Sign Out</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

// Add CSS for bottom navigation
const styles = `
	.bottom-nav {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 50;
		box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.05);
		height: 64px;
	}
	
	/* Add padding to main content area in mobile */
	@media (max-width: 767px) {
		main, .main-content {
			padding-bottom: calc(64px + env(safe-area-inset-bottom, 0px));
		}
	}

	@media (min-width: 768px) {
		.bottom-nav {
			position: static;
			box-shadow: none;
			height: auto;
		}
	}
`;

// Inject styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default React.memo(TopNavBar);
