import { useEffect, useState, useCallback } from 'react';
import { User, FriendRequest } from '../models/user';

interface FriendRequestWithUser extends FriendRequest {
  user: User;
}

export function useFriendRequests() {
  const [incomingRequests, setIncomingRequests] = useState<FriendRequestWithUser[]>([]);
  const [sentRequests, setSentRequests] = useState<FriendRequestWithUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Load user info with no caching
  const loadUserInfo = useCallback(async (userId: string): Promise<User> => {
    try {
      return await User.getUserById(userId);
    } catch (error) {
      console.error('Failed to load user info:', error);
      throw error;
    }
  }, []);

  // Wrap fetchFriendRequests with useCallback
  const fetchFriendRequests = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const requests = await User.getFriendRequests();

      // Fetch user info for each friend request
      const requestsWithUser = await Promise.all(
        requests.map(async (request) => {
          const userId = request.requester_id;
          const user = await loadUserInfo(userId);
          return { ...request, user };
        })
      );

      const sentRequestsWithUser = await Promise.all(
        (await User.getSentFriendRequests()).map(async (request) => {
          const userId = request.recipient_id;
          const user = await loadUserInfo(userId);
          return { ...request, user };
        })
      );

      setIncomingRequests(requestsWithUser);
      setSentRequests(sentRequestsWithUser);
    } catch (err) {
      setError('Failed to load friend requests');
    } finally {
      setLoading(false);
    }
  }, [loadUserInfo]);

  useEffect(() => {
    fetchFriendRequests();

    // Refresh data every 30 seconds
    // const interval = setInterval(() => {
    //   fetchFriendRequests();
    // }, 30000);

    // return () => clearInterval(interval);
  }, [fetchFriendRequests]);

  return {
    incomingRequests,
    sentRequests,
    loading,
    error,
    refresh: fetchFriendRequests, // Expose the fetch function for manual refreshes
  };
}