import React from 'react';
import Section from '../components/Section';
import TableOfContents from '../components/TableOfContents';

const TermsAndConditions: React.FC = () => {
  // List of sections with titles and IDs
  const sections = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'userResponsibilities', title: 'User Responsibilities' },
    { id: 'acceptableUse', title: 'Acceptable Use Policy' },
    { id: 'privacy', title: 'Privacy Policy' },
    { id: 'accountTermination', title: 'Account Termination' },
    { id: 'disclaimer', title: 'Disclaimer of Warranties' },
    { id: 'limitationOfLiability', title: 'Limitation of Liability' },
    { id: 'changes', title: 'Changes to Terms' },
    { id: 'contact', title: 'Contact Information' },
  ];

  return (
    <div className="bg-gradient-to-br from-green-700 to-blue-800 text-white min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white text-blue-900 p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-center mb-8">
            Terms and Conditions
          </h1>

          {/* Table of Contents */}
          <TableOfContents sections={sections} />

          {/* Terms Sections */}
          <div className="mt-8 space-y-8">
            {sections.map((section) => (
              <Section key={section.id} id={section.id} title={section.title}>
                {/* Placeholder for section content */}
                <p className="text-gray-700 leading-7">
                  {/* Add your legal text for {section.title} here. */}
                  [Your {section.title} content goes here.]
                </p>
              </Section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
