import React from 'react';

interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean;
  children: React.ReactNode;
  buttonClass?: string;    // Ensure this matches usage
  loadingText?: string;    // Ensure this matches usage
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  children,
  buttonClass = 'bg-indigo-600 hover:bg-indigo-700 text-white', // Default styles
  loadingText = 'Loading...',
  disabled,
  ...props
}) => {
  return (
    <button
      disabled={isLoading || disabled}
      className={`flex items-center justify-center px-4 py-2 rounded-md transition duration-300 ${
        isLoading || disabled ? 'opacity-50 cursor-not-allowed' : ''
      } ${buttonClass}`}
      {...props}
    >
      {isLoading ? (
        <>
          <svg
            className="animate-spin h-5 w-5 text-current mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          <span>{loadingText}</span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default LoadingButton;