import React from 'react';

interface TableOfContentsProps {
  sections: { id: string; title: string }[];
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ sections }) => {
  return (
    <nav className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Table of Contents</h2>
      <ul className="list-decimal list-inside space-y-2 text-indigo-600">
        {sections.map((section) => (
          <li key={section.id}>
            <a href={`#${section.id}`} className="hover:underline">
              {section.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableOfContents;
