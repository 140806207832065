import React from 'react';

const TournamentFormat: React.FC = () => {
    return (
        <div className="w-full max-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 p-6 w-full">
                <h3 className="text-2xl font-bold text-white flex items-center"><i className="fas fa-trophy mr-2"></i>Tournament Format</h3>
            </div>
            <div className="p-6">
                <div className="mb-8">
                    <h4 className="text-xl font-bold mb-4">9v9 Tournament</h4>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-8">
                    <div>
                        <h4 className="text-lg font-bold mb-4">Group Stage</h4>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h5 className="font-bold mb-2">Group A</h5>
                                <ul className="list-disc list-inside">
                                    <li>Team A</li>
                                    <li>Team B</li>
                                    <li>Team C</li>
                                </ul>
                            </div>
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h5 className="font-bold mb-2">Group B</h5>
                                <ul className="list-disc list-inside">
                                    <li>Team D</li>
                                    <li>Team E</li>
                                    <li>Team F</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className="text-lg font-bold mb-4">Knockout Stage</h4>
                        <div className="space-y-4">
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h5 className="font-bold">Semi-Finals</h5>
                                <p>Top 2 teams from each group advance</p>
                            </div>
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h5 className="font-bold">Finals</h5>
                                <p>Winners of semi-finals compete for the championship</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h4 className="text-lg font-bold mb-4">Tournament Rules</h4>
                    <ul className="list-disc list-inside text-gray-700">
                        <li>9 players per team on the field</li>
                        <li>Two 20-minute halves with a 5-minute break</li>
                        <li>Group stage: Round-robin format</li>
                        <li>Knockout stage: Single elimination</li>
                        <li>Complete rulebook can be viewed at <a href="/rules" className="text-blue-500">rules</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TournamentFormat;
