import React from 'react';
import GameCard from '../components/GameCard';
import { Game } from '../models/games';

interface GamesListProps {
  games: Game[];
  userID: string;
}

const GamesList: React.FC<GamesListProps> = ({ games, userID }) => {
  if (!games) return null;
  return (
    <div className="space-y-4">
      {games.map((game) => (
        <GameCard key={game.game_id} game={game} userID={userID} />
      ))}
    </div>
  );
};

export default GamesList;
