import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ChatInterface from '../components/ChatInterface';
import ChatList from '../components/ChatList';
import { useChatroom, useChatrooms } from '../models/chatroom';
import TopNavBar from '../components/TopNavBar';
import { User } from '../models/user';
import { useUserProfile } from '../hooks/useUserProfile';
import LoadingSpinner from '../components/LoadingSpinner';
import { useIsMobile } from '../hooks/useIsMobile';
import MobileChatLayout from '../components/mobile/MobileChatLayout';

const ChatPage: React.FC = () => {
  const { chatroomId } = useParams<{ chatroomId?: string }>();
  const { chatroom, loading: chatroomLoading, error: chatroomError } = useChatroom(chatroomId);
  const { chatrooms, loading: chatroomsLoading, error: chatroomsError } = useChatrooms();
  const { userProfile: currentUserProfile } = useUserProfile();
  const [otherProfiles, setOtherProfiles] = useState<{ [key: string]: User }>({});
  const isMobile = useIsMobile();

  useEffect(() => {
    const loadOtherProfiles = async () => {
      if (!currentUserProfile?.user_id || !chatrooms) return;
      
      const directMessages = chatrooms.filter(chat => chat.chatroom_type === 'direct_message');
      const otherUserIds = directMessages
        .map(chat => Object.keys(chat.members).find(id => id !== currentUserProfile.user_id))
        .filter((id): id is string => id !== undefined);

      // Add the current chatroom's members if it exists and is a direct message
      if (chatroom?.chatroom_type === 'direct_message') {
        const otherUserId = Object.keys(chatroom.members).find(id => id !== currentUserProfile.user_id);
        if (otherUserId) {
          otherUserIds.push(otherUserId);
        }
      }

      const uniqueUserIds = [...new Set(otherUserIds)];
      const profiles: { [key: string]: User } = {};

      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          try {
            const userProfile = await User.getUserById(userId);
            if (userProfile) {
              profiles[userId] = userProfile;
            }
          } catch (error) {
            console.error('Failed to load profile for user:', userId, error);
          }
        })
      );

      setOtherProfiles(profiles);
    };

    loadOtherProfiles();
  }, [chatrooms, chatroom, currentUserProfile?.user_id]);

  if (!currentUserProfile) {
    return (
      <div className="flex flex-col h-screen max-h-screen overflow-hidden bg-gray-100">
        <TopNavBar />
        <div className="flex justify-center items-center h-full">
        <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen max-h-screen overflow-hidden bg-gray-100">
      <TopNavBar />
      {isMobile ? (
        <MobileChatLayout
          chatrooms={chatrooms || []}
          loading={chatroomsLoading}
          error={chatroomsError}
          currentUserProfile={currentUserProfile}
          otherProfiles={otherProfiles}
          chatroom={chatroom}
          chatroomLoading={chatroomLoading}
          chatroomError={chatroomError}
        />
      ) : (
        <div className="flex flex-1 min-h-0">
          <ChatList
            chatrooms={chatrooms || []}
            loading={chatroomsLoading}
            error={chatroomsError}
            currentUserProfile={currentUserProfile}
            otherProfiles={otherProfiles}
          />
          <div className="flex-1 min-h-0">
            {!chatroomId ? (
              <div className="flex justify-center items-center h-full text-gray-500">
                Select a chat to start messaging
              </div>
            ) : chatroomLoading ? (
              <div className="flex justify-center items-center h-full">
                <LoadingSpinner />
              </div>
            ) : chatroomError ? (
              <div className="text-red-500 text-center p-4">{chatroomError.message}</div>
            ) : (
              chatroom && (
                <ChatInterface
                  chatRoomId={chatroom.chatroom_id}
                  chatRoomName={chatroom.name}
                  currentUser={{
                    id: currentUserProfile.user_id,
                    name: currentUserProfile.username,
                  }}
                  otherProfiles={otherProfiles}
                  chatroomType={chatroom.chatroom_type}
                  participants={Object.keys(chatroom.members)}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;