/**
 * useUserProfile Hook
 *
 * This hook manages the user profile data with caching and automatic updates.
 *
 * Functionality:
 * 1. Caches user profile data in localStorage with a 24-hour expiry.
 * 2. Automatically loads user profile on initial mount.
 * 3. Provides a function to manually reload the user profile.
 * 4. Manages loading state during profile fetching.
 *
 * Usage:
 * const { userProfile, isLoading, loadUserProfile } = useUserProfile();
 *
 * - userProfile: The current user profile data (null if not loaded)
 * - isLoading: Boolean indicating if the profile is being fetched
 * - loadUserProfile: Function to manually trigger a profile reload
 *
 * The hook will cause a re-render when:
 * - The initial profile is loaded
 * - The profile is manually reloaded using loadUserProfile
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, a new profile is fetched from the server
 */

import { useState, useEffect, useCallback } from 'react';
import { User } from '../models/user';

const getCurrentUserId = (): string | null => {
	const token = localStorage.getItem('accessToken');
	if (token) {
		const payloadBase64 = token.split('.')[1];
		const payload = atob(payloadBase64);
		const parsedPayload = JSON.parse(payload);
		return parsedPayload.userId;
	}
	return null;
};

export const useUserProfile = () => {
	const userId = getCurrentUserId();
	const CACHE_KEY = `userProfileCache_${userId}`;
	const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours
	const BACKGROUND_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

	const loadFromLocalStorage = (): { data: User | null; lastUpdated: number } => {
		try {
			const cachedData = localStorage.getItem(CACHE_KEY);
			if (cachedData) {
				const { data, timestamp } = JSON.parse(cachedData);
				if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
					return { data: data as User, lastUpdated: timestamp };
				} else {
					localStorage.removeItem(CACHE_KEY);
				}
			}
		} catch (error) {
			console.error('Failed to load user profile from localStorage:', error);
		}
		return { data: null, lastUpdated: 0 };
	};

	const [{ data: userProfile, lastUpdated }, setUserProfileData] = useState(() => loadFromLocalStorage());
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const saveToLocalStorage = useCallback((data: User) => {
		try {
			localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: Date.now() }));
		} catch (error) {
			console.error('Failed to save user profile to localStorage:', error);
		}
	}, [CACHE_KEY]);

	const fetchUserProfile = useCallback(async (showLoading = true) => {
		if (showLoading) {
			setIsLoading(true);
		}
		try {
			const userData = await User.fetchCurrentUser();
			saveToLocalStorage(userData);
			setUserProfileData({ data: userData, lastUpdated: Date.now() });
			setError(null);
			return userData;
		} catch (error) {
			console.error('Error fetching user profile:', error);
			setError(error as Error);
			throw error;
		} finally {
			if (showLoading) {
				setIsLoading(false);
			}
		}
	}, [saveToLocalStorage]);

	// Initial load and background updates
	useEffect(() => {
		if (!userId) {
			setUserProfileData({ data: null, lastUpdated: 0 });
			setIsLoading(false);
			return;
		}

		// Check if we have valid cached data
		const cachedData = loadFromLocalStorage();
		if (cachedData.data) {
			setUserProfileData(cachedData);
			setIsLoading(false);
			
			// Only do background update if cache is older than 5 minutes
			if (Date.now() - cachedData.lastUpdated >= BACKGROUND_UPDATE_INTERVAL) {
				fetchUserProfile(false).catch(console.error);
			}
		} else {
			// No valid cache, need to fetch
			fetchUserProfile(true).catch(console.error);
		}

		// Set up background updates
		const backgroundUpdate = async () => {
			const currentData = loadFromLocalStorage();
			// Only update if cache is older than 5 minutes
			if (Date.now() - currentData.lastUpdated >= BACKGROUND_UPDATE_INTERVAL) {
				try {
					await fetchUserProfile(false);
				} catch (error) {
					// Silent fail for background updates
					console.error('Background update failed:', error);
				}
			}
		};

		const intervalId = setInterval(backgroundUpdate, BACKGROUND_UPDATE_INTERVAL);

		return () => {
			clearInterval(intervalId);
		};
	}, [userId, fetchUserProfile, BACKGROUND_UPDATE_INTERVAL]);

	const refreshProfile = useCallback(() => fetchUserProfile(true), [fetchUserProfile]);

	return {
		userProfile,
		isLoading,
		error,
		refreshProfile,
		lastUpdated
	};
};
