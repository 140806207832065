import React from 'react';

const UserProfileCardSkeleton: React.FC = () => {
  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6 animate-pulse">
      <div className="flex items-center justify-center mb-4">
        <div className="w-24 h-24 bg-gray-300 rounded-full"></div>
      </div>
      <div className="space-y-3">
        <div className="h-6 bg-gray-300 rounded w-3/4 mx-auto"></div>
        <div className="h-4 bg-gray-300 rounded w-1/2 mx-auto"></div>
        <div className="flex justify-center space-x-3">
          <div className="h-8 bg-gray-300 rounded w-1/4"></div>
          <div className="h-8 bg-gray-300 rounded w-1/4"></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileCardSkeleton;