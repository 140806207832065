import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

class ApiClient {
  private api: AxiosInstance;
  private refreshApi: AxiosInstance;
  private accessToken: string | null;
  private refreshToken: string | null;
  private refreshTokenPromise: Promise<string | null> | null;
  private requestQueue: Array<{
    resolve: (value: any) => void;
    reject: (error: any) => void;
    config: AxiosRequestConfig;
  }> = [];
  private tokenExpirationTime: number | null = null;

  constructor() {
    // Initialize tokens from localStorage
    this.accessToken = localStorage.getItem('accessToken');
    this.refreshToken = localStorage.getItem('refreshToken');
    this.refreshTokenPromise = null;
    this.initializeTokenExpiration();

    // Create Axios instances
    this.api = axios.create({
      baseURL: 'https://kickoff.game',
      withCredentials: false,
    });

    this.refreshApi = axios.create({
      baseURL: 'https://kickoff.game',
      withCredentials: false,
    });

    // Set up interceptors
    this.api.interceptors.request.use(
      this.requestInterceptor.bind(this), 
      this.handleError.bind(this)
    );
    
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // If the error is due to an unauthorized request and we haven't retried
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            // Attempt to refresh the token
            const newToken = await this.refreshTokenRequest();
            
            if (newToken) {
              // Update the Authorization header with the new token
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
              
              // Retry the original request
              return this.api(originalRequest);
            } else {
              // If token refresh fails, handle auth failure
              this.handleAuthFailure();
              return Promise.reject(error);
            }
          } catch (refreshError) {
            this.handleAuthFailure();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private initializeTokenExpiration() {
    try {
      const token = this.accessToken;
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        if (payload.exp) {
          this.tokenExpirationTime = payload.exp * 1000; // Convert to milliseconds
        }
      }
    } catch (error) {
      console.error('Error parsing token:', error);
      this.tokenExpirationTime = null;
    }
  }

  private isTokenExpired(): boolean {
    if (!this.tokenExpirationTime) return true;
    // Refresh token if it expires in less than 30 seconds
    return this.tokenExpirationTime - Date.now() < 30000;
  }

  private async processQueue(error: any = null) {
    const queue = this.requestQueue;
    this.requestQueue = [];

    queue.forEach(({ resolve, reject, config }) => {
      if (error) {
        reject(error);
      } else {
        resolve(this.api(config));
      }
    });
  }

  private requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
    if (config.url && !config.url.includes('/api/auth/refresh')) {
      // Check if token is about to expire
      if (this.isTokenExpired() && this.refreshToken) {
        // Instead of returning a Promise, modify the config or throw an error
        if (this.accessToken) {
          config.headers = config.headers || {};
          config.headers['Authorization'] = `Bearer ${this.accessToken}`;
        }
        return config;
      }
    }
    
    // Add Authorization header if token exists
    if (this.accessToken) {
      config.headers = config.headers || {};
      config.headers['Authorization'] = `Bearer ${this.accessToken}`;
    }
    
    return config;
  }

  private async responseErrorInterceptor(error: any) {
    const { config, response } = error;
    const originalRequest = config;

    if (
      response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url?.includes('/api/auth/refresh')
    ) {
      originalRequest._retry = true;

      // If refresh token is not available, fail immediately
      if (!this.refreshToken) {
        this.handleAuthFailure();
        return Promise.reject(error);
      }

      if (!this.refreshTokenPromise) {
        this.refreshTokenPromise = this.refreshTokenRequest();
      }

      try {
        const newAccessToken = await this.refreshTokenPromise;

        if (newAccessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return this.api(originalRequest);
        } else {
          this.handleAuthFailure();
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // Only redirect to login if refresh token is invalid or expired
        if (refreshError.response?.status === 401) {
          this.handleAuthFailure();
        }
        return Promise.reject(refreshError);
      } finally {
        this.refreshTokenPromise = null;
      }
    }

    return Promise.reject(error);
  }

  private async refreshTokenRequest(): Promise<string | null> {
    try {
      const response = await this.refreshApi.post('/api/auth/refresh', null, {
        headers: { 
          'X-Refresh-Token': this.refreshToken || '',
        },
      });

      if (response.status === 200) {
        const newAccessToken = response.headers['x-access-token'];
        const newRefreshToken = response.headers['x-refresh-token'];

        this.setAccessToken(newAccessToken);
        this.setRefreshToken(newRefreshToken);
        this.initializeTokenExpiration();

        return newAccessToken;
      } else {
        this.handleAuthFailure();
        return null;
      }
    } catch (error) {
      // Only clear tokens and redirect if refresh token is invalid
      if (error.response?.status === 401) {
        this.handleAuthFailure();
      }
      throw error;
    }
  }

  public setAccessToken(token: string | null) {
    this.accessToken = token;
    if (token) {
      localStorage.setItem('accessToken', token);
      this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('accessToken');
      delete this.api.defaults.headers.common['Authorization'];
    }
  }

  public setRefreshToken(token: string | null) {
    this.refreshToken = token;
    if (token) {
      localStorage.setItem('refreshToken', token);
    } else {
      localStorage.removeItem('refreshToken');
    }
  }

  private handleAuthFailure() {
    this.setAccessToken(null);
    this.setRefreshToken(null);
    const currentPath = window.location.pathname + window.location.search;
    if (!currentPath.includes('/signin')) {
      localStorage.setItem('redirectAfterSignIn', currentPath);
      window.location.href = '/signin';
    }
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  public get instance(): AxiosInstance {
    return this.api;
  }
}

const apiClient = new ApiClient();

export default apiClient.instance;
export { apiClient };
