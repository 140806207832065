/**
 * useOtherUserProfile Hook
 *
 * This hook manages other users' profile data with caching and automatic updates.
 * Similar to useUserProfile but for viewing other users' profiles.
 *
 * Functionality:
 * 1. Caches user profile data in localStorage with a 24-hour expiry
 * 2. Automatically loads user profile on initial mount
 * 3. Provides a function to manually reload the user profile
 * 4. Manages loading state during profile fetching
 *
 * Usage:
 * const { userProfile, isLoading, error } = useOtherUserProfile(userId);
 *
 * - userProfile: The user profile data (null if not loaded)
 * - isLoading: Boolean indicating if the profile is being fetched
 * - error: Error object if the fetch failed
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, a new profile is fetched from the server
 */

import { useState, useEffect, useCallback } from 'react';
import { User } from '../models/user';

export const useOtherUserProfile = (userId: string | undefined | null) => {
  const CACHE_KEY = userId ? `userProfileCache_${userId}` : null;
  const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours
  const BACKGROUND_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

  const loadFromLocalStorage = (): { data: User | null; lastUpdated: number } => {
    if (!CACHE_KEY) return { data: null, lastUpdated: 0 };
    
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
          return { data: data as User, lastUpdated: timestamp };
        } else {
          localStorage.removeItem(CACHE_KEY);
        }
      }
    } catch (error) {
      console.error('Failed to load user profile from localStorage:', error);
    }
    return { data: null, lastUpdated: 0 };
  };

  const [{ data: userProfile, lastUpdated }, setUserProfileData] = useState(() => loadFromLocalStorage());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const saveToLocalStorage = (data: User) => {
    if (!CACHE_KEY) return;
    
    try {
      localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: Date.now() }));
    } catch (error) {
      console.error('Failed to save user profile to localStorage:', error);
    }
  };

  const fetchUserProfile = useCallback(async (showLoading = true) => {
    if (!userId) return null;
    
    if (showLoading) {
      setIsLoading(true);
    }
    try {
      const userData = await User.getUserById(userId);
      saveToLocalStorage(userData);
      setUserProfileData({ data: userData, lastUpdated: Date.now() });
      setError(null);
      return userData;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError(error as Error);
      throw error;
    } finally {
      if (showLoading) {
        setIsLoading(false);
      }
    }
  }, [userId]);

  // Initial load and background updates
  useEffect(() => {
    if (!userId) {
      setUserProfileData({ data: null, lastUpdated: 0 });
      setIsLoading(false);
      return;
    }

    // If we don't have cached data or it's stale, fetch immediately
    if (!userProfile || Date.now() - lastUpdated > CACHE_EXPIRY_TIME) {
      fetchUserProfile();
    }

    // Set up background updates
    // const backgroundUpdate = async () => {
    //   try {
    //     await fetchUserProfile(false);
    //   } catch (error) {
    //     // Silent fail for background updates
    //     console.error('Background update failed:', error);
    //   }
    // };

    // const intervalId = setInterval(backgroundUpdate, BACKGROUND_UPDATE_INTERVAL);

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [userId, userProfile, lastUpdated, fetchUserProfile]);

  return {
    userProfile,
    isLoading,
    error,
    refreshProfile: () => fetchUserProfile(true),
    lastUpdated
  };
};
