import { useState, useEffect } from 'react';
import { Game } from '../models/games';

export const useOtherUserGames = (userId: string | undefined) => {
  const [games, setGames] = useState<Game[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const fetchGames = async () => {
      try {
        const fetchedGames = await Game.getByUserId(userId);
        const sortedGames = fetchedGames.sort(
          (a, b) => new Date(b.scheduled_at).getTime() - new Date(a.scheduled_at).getTime()
        );
        setGames(sortedGames);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error occurred'));
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [userId, refreshTrigger]);

  const forceRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return {
    games,
    loading,
    error,
    forceRefresh,
  };
};
