import api from './api';

export interface GameMember {
    game_id: string;
    member_type: string;
    member_id: string;
    role: string;
    joined_at: Date;
    left_at?: Date;
    left_reason?: string;
    status: string;
    checked_in: boolean;
    performance_metrics: Record<string, number>;
}

export interface GameSearchParams {
    latitude?: number;
    longitude?: number;
    radius?: number;
    start_date?: Date;
    end_date?: Date;
    sport?: string;
    skill_level?: string;
}


export class Game {
    game_id: string;
    name: string;
    description: string;
    scheduled_at: Date;
    location: string;
    visibility: string;
    creator_id: string;
    created_at: Date;
    updated_at: Date;
    creator_type: string;
    admin_id: string;
    require_payment: boolean;
    payment_amount: number;
    joined_member_count: number;
    left_member_count: number;
    waitlisted_member_count: number;
    members: Map<string, GameMember>;
    max_members: number;
    team_size: number;
    reserve_count: number;
    status: string;
    location_id: string;
    visibility_settings_id: string;
    performance_id: string;
    payment_id: string;
    communication_settings_id: string;
    latitude: number;
    longitude: number;
    geohash: string;
    deleted_at: Date | null;

    constructor(data: any) {
        Object.assign(this, data);
        this.scheduled_at = new Date(this.scheduled_at);
        this.created_at = new Date(this.created_at);
        this.updated_at = new Date(this.updated_at);
        this.creator_type = data.creator_type || 'user';
        this.admin_id = data.admin_id || '';
        this.require_payment = data.require_payment || false;
        this.payment_amount = data.payment_amount || 0;
        this.joined_member_count = data.joined_member_count || 0;
        this.left_member_count = data.left_member_count || 0;
        this.waitlisted_member_count = data.waitlisted_member_count || 0;
        this.members = new Map<string, GameMember>(Object.entries(data.members || {}));
        this.max_members = data.max_members || 0;
        this.team_size = data.team_size || 1;
        this.reserve_count = data.reserve_count || 0;
        this.status = data.status || 'scheduled';
        this.location_id = data.location_id || '';
        this.visibility_settings_id = data.visibility_settings_id || '';
        this.performance_id = data.performance_id || '';
        this.payment_id = data.payment_id || '';
        this.communication_settings_id = data.communication_settings_id || '';
        this.latitude = data.latitude || 0;
        this.longitude = data.longitude || 0;
        this.geohash = data.geohash || '';
        this.deleted_at = data.deleted_at || null;
    }

    static async fetchAll(): Promise<Game[]> {
        try {
            const response = await api.get('/api/v1/games');
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            throw new Error('Failed to fetch games');
        }
    }

    static async fetchById(gameId: string): Promise<Game> {
        try {
            const response = await api.get(`/api/v1/games?game_id=${gameId}`);
            return new Game(response.data);
        } catch (error) {
            throw new Error('Failed to fetch game');
        }
    }

    static async search(params: GameSearchParams): Promise<Game[]> {
        try {
            const response = await api.post('/api/v1/games/search', { params });
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            throw new Error('Failed to search games');
        }
    }

    static async getByUserId(userId: string): Promise<Game[]> {
        try {
            const response = await api.get(`/api/v1/users/games?user_id=${userId}`);
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            throw new Error(`Failed to fetch games by user: ${error}`);
        }
    }

    static async getNearby(userId: string): Promise<Game[]> {
        try {
            const response = await api.post(`/api/v1/games/search`);
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            throw new Error('Failed to fetch nearby games');
        }
    }

    async create(): Promise<Game> {
        try {
            // Ensure latitude and longitude are included in the request
            if (!this.latitude || !this.longitude) {
                throw new Error('Location coordinates are required');
            }
            
            const gameData = {
                ...this,
                latitude: this.latitude,
                longitude: this.longitude,
            };
            
            const response = await api.post('/api/v1/games', gameData);
            return new Game(response.data);
        } catch (error) {
            console.error('Failed to create game:', error);
            throw new Error('Failed to create game');
        }
    }

    async update(updateData?: Partial<Game>): Promise<Game> {
        try {
            const dataToUpdate = updateData ? { ...this, ...updateData } : this;
            const response = await api.put(`/api/v1/games`, dataToUpdate);
            return new Game(response.data);
        } catch (error) {
            throw new Error('Failed to update game');
        }
    }

    async delete(): Promise<void> {
        try {
            await api.delete(`/api/v1/games?game_id=${this.game_id}`);
        } catch (error) {
            throw new Error('Failed to delete game');
        }
    }

    static async getGameEvents(gameId: string): Promise<string> {
        try {
            const response = await api.get(`/api/v1/games/events?game_id=${gameId}`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to fetch game events');
        }
    }

    static async updateGameEvents(gameId: string, events: string): Promise<void> {
        try {
            await api.post(`/api/v1/games/events`, { game_id: gameId, events });
        } catch (error) {
            throw new Error('Failed to update game events');
        }
    }

    async joinGame(): Promise<void> {
        try {
            await api.post(`/api/v1/games/join?game_id=${this.game_id}`);
        } catch (error) {
            throw new Error('Failed to join game');
        }
    }

    async leaveGame(): Promise<void> {
        try {
            await api.post(`/api/v1/games/leave?game_id=${this.game_id}`);
        } catch (error) {
            throw new Error('Failed to leave game');
        }
    }

    async joinWaitlist(): Promise<void> {
        try {
            await api.post(`/api/v1/games/${this.game_id}/waitlist/join`);
        } catch (error) {
            throw new Error('Failed to join waitlist');
        }
    }

    async leaveWaitlist(): Promise<void> {
        try {
            await api.post(`/api/v1/games/${this.game_id}/waitlist/leave`);
        } catch (error) {
            throw new Error('Failed to leave waitlist');
        }
    }

    isUserJoined(userId: string): boolean {
        const member = this.members.get(userId);
        return member !== undefined && member.status === 'joined' && !member.left_at;
    }

    isUserWaitlisted(userId: string): boolean {
        const member = this.members.get(userId);
        return member !== undefined && member.status === 'waitlisted';
    }

    isFull(): boolean {
        return this.joined_member_count >= this.max_members;
    }
}
