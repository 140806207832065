import React from 'react';

const UserFriendsCardSkeleton: React.FC = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg mb-6 p-6">
      <div className="h-8 bg-gray-300 rounded w-1/4 mb-6"></div>
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div key={i} className="flex items-center space-x-4">
            <div className="w-12 h-12 bg-gray-300 rounded-full"></div>
            <div className="flex-1">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2 mt-2"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserFriendsCardSkeleton;
