/**
 * UserProfileCard Component
 * 
 * This component displays the user's profile card with their cover image,
 * profile picture, name, and username.
 * 
 * Behavior:
 * 1. Shows a skeleton loader (UserProfileCardSkeleton) if any data is loading.
 * 2. Displays the full profile card when all data is available.
 * 3. Automatically updates with the latest data as it becomes available.
 * 
 * Dependencies:
 * - useUserProfile hook for user data
 * - useUserImages hook for profile and cover images
 * - UserProfileCardSkeleton component for loading state
 * 
 * Note: This component will re-render when the user profile or image data
 * is updated asynchronously by the hooks.
 */

import React, { memo, useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { User } from '../models/user';
import UserProfileCardSkeleton from './UserProfileCardSkeleton';
import { useUserProfile } from '../hooks/useUserProfile';
import { useImageLoader } from '../hooks/useImageLoader';
import { Link } from 'react-router-dom';

const UserProfileCard: React.FC = memo(() => {
  const { userProfile } = useUserProfile() ;
  const profileImageUrl = userProfile?.profile_picture_url || null;
  const coverImageUrl = userProfile?.cover_picture_url || "https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/a2b87806-07d0-495f-1ed5-a7bfd0c22200/public";

  const profileImage = useImageLoader(profileImageUrl);
  const coverImage = useImageLoader(coverImageUrl);

  // Show skeleton only if there is no cached data
  if (!userProfile) {
    return <UserProfileCardSkeleton />;
  }

  return (
    <Link to={`/u/${userProfile.username}`}>
    <div className="bg-white rounded-lg shadow-lg mb-6 transition-all duration-300 hover:shadow-xl overflow-hidden">
      <div className="relative">
        <img
          src={coverImage || profileImage}
          alt="Cover"
          className="w-full h-32 object-cover"
        />
        <div className="absolute -bottom-8 left-6">
          {profileImage ? (
            <img
              src={profileImage}
              alt={`${userProfile.first_name} ${userProfile.last_name}`}
              className="w-16 h-16 rounded-full object-cover border-4 border-white"
            />
          ) : (
            <FaUserCircle className="w-16 h-16 text-gray-400 bg-white rounded-full border-4 border-white" />
          )}
        </div>
      </div>
      <div className="pt-10 px-6 pb-6 text-center">
        <h2 className="text-2xl font-bold text-gray-800">
          {userProfile.first_name} {userProfile.last_name}
        </h2>
        <p className="text-sm text-gray-500">@{userProfile.username}</p>
      </div>
    </div>
    </Link>
  );
});

export default UserProfileCard;
