import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaFutbol, FaCheck, FaUserPlus, FaSpinner, FaEnvelope, FaCheckDouble } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { Notification, NotificationType, ConnectionStatus } from '../models/notification';
import { useOtherUserProfile } from '../hooks/useOtherUserProfile';
import LoadingSpinner from './LoadingSpinner';

interface NotificationItemProps {
  notification: Notification;
  onMarkAsRead?: (notificationId: string) => Promise<void>;
  connectionStatus: ConnectionStatus;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onMarkAsRead,
  connectionStatus
}) => {
  const { userProfile: sourceUser } = useOtherUserProfile(
    ['friend_request', 'friend_accepted'].includes(notification.type) ? notification.source_id : null
  );

  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
  const [isOfflineQueued, setIsOfflineQueued] = useState(false);

  // Reset offline queued state when connection is restored
  useEffect(() => {
    if (connectionStatus === 'online' && isOfflineQueued) {
      handleMarkAsRead();
    }
  }, [connectionStatus]);

  const getNotificationIcon = (type: NotificationType) => {
    switch (type) {
      case 'friend_request':
        return <FaUserPlus className="w-10 h-10 text-indigo-400" />;
      case 'friend_accepted':
        return <FaCheck className="w-10 h-10 text-green-400" />;
      case 'chat_update':
        return <FaEnvelope className="w-10 h-10 text-gray-400" />;
      case 'game_update':
      case 'game_created':
      case 'game_joined':
      case 'game_canceled':
        return <FaFutbol className="w-10 h-10 text-rose-400" />;
      default:
        return <FaUserCircle className="w-10 h-10 text-gray-400" />;
    }
  };

  const getNotificationLink = (type: NotificationType, sourceId: string) => {
    switch (type) {
      case 'friend_request':
      case 'friend_accepted':
        return sourceUser ? `/u/${sourceUser.username}` : '#';
      case 'game_update':
      case 'game_created':
      case 'game_joined':
      case 'game_canceled':
        return `/game/${sourceId}`;
      default:
        return '#';
    }
  };

  const getNotificationContent = () => {
    if (!notification) return '';

    switch (notification.type) {
      case 'friend_request':
        return sourceUser ? (
          <span>
            <span className="font-medium text-indigo-600">{sourceUser.username}</span>
            <span className="text-gray-600"> wants to be your friend!</span>
          </span>
        ) : notification.content;
      case 'friend_accepted':
        return sourceUser ? (
          <span>
            <span className="font-medium text-indigo-600">{sourceUser.username}</span>
            <span className="text-gray-600"> accepted your friend request</span>
          </span>
        ) : notification.content;
      case 'chat_update':
        return sourceUser ? (
          <span>
            <span className="text-gray-600"> you have a new message!</span>
          </span>
        ) : notification.content;
      default:
        return notification.content;
    }
  };

  const handleMarkAsRead = async (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!onMarkAsRead) return;

    // If offline, queue the action
    if (connectionStatus !== 'online') {
      setIsOfflineQueued(true);
      return;
    }

    setIsMarkingAsRead(true);
    try {
      await onMarkAsRead(notification.notification_id);
      setIsOfflineQueued(false);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
      // If connection lost during the request, queue it
      if (connectionStatus !== 'online') {
        setIsOfflineQueued(true);
      }
    } finally {
      setIsMarkingAsRead(false);
    }
  };

  const shouldShowMarkAsRead = () => {
    return onMarkAsRead != null && !notification.read;
  };

  const getDeliveryStatus = () => {
    if (notification.read) {
      return <FaCheckDouble className="text-blue-500" size={14} />;
    }
    return <FaCheck className="text-gray-400" size={14} />;
  };

  return (
    <Link
      to={getNotificationLink(notification.type, notification.source_id)}
      className={`px-4 py-3 flex items-start space-x-3 border-b border-gray-100 last:border-b-0 transition duration-300 hover:bg-gray-50 ${
        !notification.read ? 'bg-blue-50' : ''
      } ${connectionStatus !== 'online' ? 'opacity-75' : ''}`}
    >
      <div className="flex-shrink-0">
        {getNotificationIcon(notification.type)}
      </div>
      <div className="flex-1 min-w-0">
        <div className="text-sm text-gray-800">{getNotificationContent()}</div>
        <div className="flex items-center space-x-2 mt-1">
          <p className="text-xs text-gray-500">
            {formatDistanceToNow(notification.notification_time, { addSuffix: true })}
          </p>
          {getDeliveryStatus()}
        </div>
      </div>
      <div className="flex items-center space-x-2 ml-2">
        {shouldShowMarkAsRead() && (
          <button
            onClick={handleMarkAsRead}
            className={`p-1 text-gray-400 hover:text-blue-500 transition-colors ${
              isMarkingAsRead || isOfflineQueued ? 'cursor-wait' : 'cursor-pointer'
            } ${connectionStatus !== 'online' ? 'opacity-50' : ''}`}
            disabled={isMarkingAsRead || isOfflineQueued}
            title={connectionStatus !== 'online' ? 'Will mark as read when online' : 'Mark as read'}
          >
            {isMarkingAsRead ? (
              <LoadingSpinner size={16} />
            ) : isOfflineQueued ? (
              <FaSpinner className="animate-spin" size={16} />
            ) : (
              <FaCheck size={16} />
            )}
          </button>
        )}
      </div>
    </Link>
  );
};

export default React.memo(NotificationItem);
