import api from './api';

export interface TeamMember {
    team_id: string;
    member_type: string;
    member_id: string;
    role: string;
    joined_at: Date;
    left_at?: Date;
    left_reason?: string;
    status: string;
    checked_in: boolean;
    performance_metrics: Record<string, number>;
}

export interface Standing {
    position: number;
    team_id: string;
    team_name: string;
    played: number;
    won: number;
    drawn: number;
    lost: number;
    goals_for: number;
    goals_against: number;
    goal_difference: number;
    points: number;
}

export interface TeamSearchParams {
    latitude?: number;
    longitude?: number;
    radius?: number;
    start_date?: Date;
    end_date?: Date;
    sport?: string;
    skill_level?: string;
}

export class Team {
    team_id: string;
    name: string;
    description: string;
    visibility: string;
    owner_id: string; // Renamed from creator_id
    require_payment: boolean;
    membership_fee: number; // Renamed from payment_amount
    max_members: number;
    created_at: Date;
    updated_at: Date;
    location_id: string;
    visibility_settings_id: string;
    members: Map<string, TeamMember>;
    member_count: number;
    logo_url: string;
    
    constructor(data: any) {
        Object.assign(this, data);
        this.visibility = data.visibility || 'public';
        this.created_at = new Date();
        this.updated_at = new Date();
    }

    static async fetchAll(): Promise<Team[]> {
        try {
            const response = await api.get('/api/v1/teams');
            return response.data.map((teamData: any) => new Team(teamData));
        } catch (error) {
            throw new Error('Failed to fetch teams');
        }
    }

    static async fetchById(teamId: string): Promise<Team> {
        try {
            const response = await api.get(`/api/v1/teams/${teamId}`);
            return new Team(response.data);
        } catch (error) {
            throw new Error('Failed to fetch team');
        }
    }

    static async search(params: TeamSearchParams): Promise<Team[]> {
        try {
            const response = await api.post('/api/v1/teams/search', { params });
            return response.data.map((teamData: any) => new Team(teamData));
        } catch (error) {
            throw new Error('Failed to search teams');
        }
    }

    static async getByUserId(userId: string): Promise<Team[]> {
        try {
            const response = await api.get(`/api/v1/users/teams`);
            return response.data.map((teamData: any) => new Team(teamData));
        } catch (error) {
            throw new Error(`Failed to fetch teams by user: ${error}`);
        }
    }

    static async getNearby(userId: string): Promise<Team[]> {
        try {
            const response = await api.post(`/api/v1/teams/search`);
            return response.data.map((teamData: any) => new Team(teamData));
        } catch (error) {
            throw new Error('Failed to fetch nearby teams');
        }
    }

    async create(): Promise<Team> {
        try {
            const response = await api.post('/api/v1/teams', this);
            return new Team(response.data);
        } catch (error) {
            throw new Error('Failed to create team');
        }
    }

    async update(): Promise<Team> {
        try {
            const response = await api.put(`/api/v1/teams/${this.team_id}`, this);
            return new Team(response.data);
        } catch (error) {
            throw new Error('Failed to update team');
        }
    }

    async delete(): Promise<void> {
        try {
            await api.delete(`/api/v1/teams/${this.team_id}`);
        } catch (error) {
            throw new Error('Failed to delete team');
        }
    }

    static async updateStandings(teamId: string, standing: Standing): Promise<void> {
        try {
            await api.post(`/api/v1/teams/standings?team_id=${teamId}`, standing);
        } catch (error) {
            throw new Error('Failed to update standings');
        }
    }

    async getStanding(): Promise<Standing> {
        try {
            const response = await api.get(`/api/v1/teams/standings?team_id=${this.team_id}`);
            return response.data as Standing;
        } catch (error) {
            throw new Error('Failed to get standings');
        }
    }

    async uploadTeamImage(file: File): Promise<string> {
        try {
            // Generate upload URL from the backend
            const uploadResponse = await api.post(`/api/v1/teams/${this.team_id}/image`);
            const { upload_url } = uploadResponse.data;

            // Upload file to Cloudflare or your storage service
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(upload_url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload team image');
            }

            const data = await response.json();

            if (!data.success) {
                throw new Error('Failed to upload team image');
            }

            if (data.errors && data.errors.length > 0) {
                throw new Error(`Failed to upload team image: ${data.errors[0].message}`);
            }

            if (data.result.variants && data.result.variants.length > 0) {
                // Return the variant URL (choose appropriate variant)
                return data.result.variants[0];
            } else {
                throw new Error('No image URL returned');
            }
        } catch (error: any) {
            throw new Error(error.message || 'Failed to upload team image');
        }
    }
}
