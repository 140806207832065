import { useState, useCallback, useEffect } from 'react';
import api from './api';

export interface ChatroomVisibility {
    public: boolean;
    searchable: boolean;
    joinable: boolean;
}

export interface ChatroomMember {
    chatroom_id: string;
    member_id: string;
    joined_at: string;
    status: string;
    role: string;
    member_type: string;
    last_read_message: string;
}

export interface Chatroom {
    chatroom_id: string;
    name: string;
    description?: string;
    last_message_timestamp?: string;
    visibility: ChatroomVisibility;
    admin_ids: string[];
    media_items?: string[];
    membership_rules?: string;
    chatroom_type: string;
    created_at: string;
    updated_at: string;
    members: { [key: string]: ChatroomMember };
}

export interface CreateChatroomRequest {
    name: string;
    description?: string;
    admin_ids: string[];
    membership_rules?: string;
    chatroom_type: string;
    members?: string[];
}

export interface UpdateChatroomRequest {
    chatroom_id: string;
    name?: string;
    description?: string;
    visibility?: ChatroomVisibility;
    admin_ids?: string[];
    membership_rules?: string;
}

export interface ListChatroomsResponse {
    chatrooms: Chatroom[];
    next_page: string;
}

export class ChatroomService {
    static async listUserChatrooms(pageState?: string, limit: number = 20): Promise<ListChatroomsResponse> {
        const params = new URLSearchParams();
        if (pageState) params.append('page_state', pageState);
        params.append('limit', limit.toString());

        const response = await api.get(`/api/v1/users/chatrooms?${params.toString()}`);
        return response.data;
    }

    static async searchDMChatroom(userId: string): Promise<Chatroom | null> {
      try {
        const response = await api.get(`/api/v1/users/chatrooms?user_id=${userId}`);
        return response.data?.chatroom_id ? response.data as Chatroom : null;
      } catch (error) {
        throw new Error('Failed to fetch chatrooms');
      }
    }

    static async createChatroom(data: CreateChatroomRequest): Promise<Chatroom> {
        const response = await api.post('/api/v1/chatrooms', data);
        return response.data;
    }

    static async getChatroom(chatroomId: string): Promise<Chatroom> {
        const response = await api.get(`/api/v1/chatrooms?chatroom_id=${chatroomId}`);
        return response.data;
    }

    static async updateChatroom(data: UpdateChatroomRequest): Promise<Chatroom> {
        const response = await api.put(`/api/v1/chatrooms?chatroom_id=${data.chatroom_id}`, data);
        return response.data;
    }

    static async deleteChatroom(chatroomId: string): Promise<void> {
        await api.delete(`/api/v1/chatrooms?chatroom_id=${chatroomId}`);
    }

    static async joinChatroom(chatroomId: string): Promise<Chatroom> {
        const response = await api.post(`/api/v1/chatrooms/join?chatroom_id=${chatroomId}`);
        return response.data;
    }

    static async leaveChatroom(chatroomId: string): Promise<void> {
        await api.post(`/api/v1/chatrooms/leave?chatroom_id=${chatroomId}`);
    }

    static async updateLastReadMessage(chatroomId: string, lastReadTimestamp: string): Promise<void> {
        await api.put(`/api/v1/chatrooms/last-read-message?chatroom_id=${chatroomId}&last_read_timestamp=${lastReadTimestamp}`, { last_read_timestamp: lastReadTimestamp });
    }
}

interface UseChatroomsProps {
    limit?: number;
    pageState?: string;
}

export function useChatrooms({ limit = 20, pageState }: UseChatroomsProps = {}) {
    const [chatrooms, setChatrooms] = useState<Chatroom[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchChatrooms = useCallback(async () => {
        try {
            setLoading(true);
            const response = await ChatroomService.listUserChatrooms(pageState, limit);
            setChatrooms(response.chatrooms);
            setNextPage(response.next_page);
            setError(null);
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, [pageState, limit]);

    useEffect(() => {
        fetchChatrooms();
    }, [fetchChatrooms]);

    return {
        chatrooms,
        nextPage,
        loading,
        error,
        refetch: fetchChatrooms
    };
}

export function useDMChatroom(userId: string) {
    const [chatroom, setChatroom] = useState<Chatroom | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchDMChatroom = useCallback(async () => {
        if (!userId) return;

        try {
            setLoading(true);
            const dmChatroom = await ChatroomService.searchDMChatroom(userId);
            setChatroom(dmChatroom);
            setError(null);
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        fetchDMChatroom();
    }, [fetchDMChatroom]);

    return {
        chatroom,
        loading,
        error,
        refetch: fetchDMChatroom
    };
}

export function useChatroom(chatroomId: string) {
    const [chatroom, setChatroom] = useState<Chatroom | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchChatroom = useCallback(async () => {
        if (!chatroomId) return;

        try {
            setLoading(true);
            const response = await ChatroomService.getChatroom(chatroomId);
            setChatroom(response);
            setError(null);
        } catch (err) {
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, [chatroomId]);

    useEffect(() => {
        fetchChatroom();
    }, [fetchChatroom]);

    const updateChatroom = async (data: UpdateChatroomRequest) => {
        try {
            setLoading(true);
            const updated = await ChatroomService.updateChatroom(data);
            setChatroom(updated);
            return updated;
        } catch (err) {
            setError(err as Error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteChatroom = async () => {
        try {
            setLoading(true);
            await ChatroomService.deleteChatroom(chatroomId);
            setChatroom(null);
        } catch (err) {
            setError(err as Error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        chatroom,
        loading,
        error,
        refetch: fetchChatroom,
        updateChatroom,
        deleteChatroom
    };
}

export function useCreateChatroom() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const createChatroom = async (data: CreateChatroomRequest) => {
        try {
            setLoading(true);
            const chatroom = await ChatroomService.createChatroom(data);
            setError(null);
            return chatroom;
        } catch (err) {
            setError(err as Error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        createChatroom,
        loading,
        error
    };
}

export function useJoinChatroom(chatroomId: string) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const joinChatroom = async () => {
        try {
            setLoading(true);
            const chatroom = await ChatroomService.joinChatroom(chatroomId);
            setError(null);
            return chatroom;
        } catch (err) {
            setError(err as Error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        joinChatroom,
        loading,
        error
    };
}

export function useLeaveChatroom(chatroomId: string) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const leaveChatroom = async () => {
        try {
            setLoading(true);
            await ChatroomService.leaveChatroom(chatroomId);
            setError(null);
        } catch (err) {
            setError(err as Error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        leaveChatroom,
        loading,
        error
    };
}

export function useUpdateLastReadMessage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const updateLastRead = async (chatroomId: string, lastReadTimestamp: string) => {
        try {
            setLoading(true);
            await ChatroomService.updateLastReadMessage(chatroomId, lastReadTimestamp);
            setError(null);
        } catch (err) {
            setError(err as Error);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        updateLastRead,
        loading,
        error
    };
}
