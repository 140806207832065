import { useEffect, useState, useMemo, useCallback } from 'react';
import { User, Friend } from '../models/user';

export function useFriends(targetUserId?: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [friendStatus, setFriendStatus] = useState<Friend | null>(null);

  const getFriendStatus = useCallback(async () => {
    if (!targetUserId) return;

    setLoading(true);
    try {
      const status = await User.getFriendStatus(targetUserId);
      setFriendStatus(status);
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error('Failed to get friend status:', err);
      setError('Failed to get friend status');
      setFriendStatus(null); // Reset friend status on error
    } finally {
      setLoading(false);
    }
  }, [targetUserId]);

  useEffect(() => {
    getFriendStatus();

    // Refresh friend status every 30 seconds
    // const interval = setInterval(() => {
    //   getFriendStatus();
    // }, 30000);

    // return () => clearInterval(interval);
  }, [getFriendStatus]);

  return useMemo(() => ({
    loading,
    error,
    friendStatus,
    refresh: getFriendStatus
  }), [loading, error, friendStatus, getFriendStatus]);
}
