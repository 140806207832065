import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import { Game } from '../models/games';
import Loading from '../components/Loading';
import LoadingButton from '../components/LoadingButton';
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaUsers,
  FaClock,
  FaShare,
  FaCalendarPlus,
  FaInfoCircle,
  FaExternalLinkAlt,
  FaComments,
  FaCheckCircle,
  FaTimesCircle,
  FaUserCircle,
  FaPen,
  FaCheck,
  FaTimes,
  FaEdit,
} from 'react-icons/fa';
import { useUserProfile } from '../hooks/useUserProfile';
import { useOtherUserProfile } from '../hooks/useOtherUserProfile';
import { User } from '../models/user';
import { GameMember } from '../models/games';
import Modal from '../components/Modal';
import GameForm from '../components/GameForm';

const GameDetailsPage: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [showLeaveConfirm, setShowLeaveConfirm] = useState<boolean>(false);
  const [hasJoined, setHasJoined] = useState<boolean>(false);
  const [isJoining, setIsJoining] = useState<boolean>(false);
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [isJoiningWaitlist, setIsJoiningWaitlist] = useState<boolean>(false);
  const [isLeavingWaitlist, setIsLeavingWaitlist] = useState<boolean>(false);
  const { userProfile } = useUserProfile();
  const { userProfile: creator, isLoading: isCreatorLoading } = useOtherUserProfile(game?.creator_id);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const navigate = useNavigate();

  const checkIfUserHasJoined = (game: Game) => {
    if (!userProfile) return false;
    return game.members.has(userProfile.user_id);
  };

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const fetchedGame = await Game.fetchById(gameId);
        setGame(fetchedGame);
        setHasJoined(checkIfUserHasJoined(fetchedGame));
      } catch (error) {
        console.error('Failed to fetch game:', error);
        window.location.reload();
        // setError('Failed to load game details.');
      } finally {
        setLoading(false);
      }
    };

    if (gameId) {
      fetchGame();
    }
  }, [gameId]);

  const handleJoinGame = async () => {
    if (!game || !userProfile) return;
    setIsJoining(true);
    try {
      await game.joinGame();
      // Refresh game data
      const updatedGame = await Game.fetchById(gameId);
      setGame(updatedGame);
      setHasJoined(checkIfUserHasJoined(updatedGame));
    } catch (error) {
      console.error('Failed to join game:', error);
      setError('Failed to join game. Please try again.');
    } finally {
      setIsJoining(false);
    }
  };

  const handleLeaveGame = async () => {
    if (!game || !userProfile) return;
    setIsLeaving(true);
    try {
      await game.leaveGame();
      setShowLeaveConfirm(false);
      // Refresh game data
      const updatedGame = await Game.fetchById(gameId);
      setGame(updatedGame);
      setHasJoined(checkIfUserHasJoined(updatedGame));
    } catch (error) {
      console.error('Failed to leave game:', error);
      setError('Failed to leave game. Please try again.');
    } finally {
      setIsLeaving(false);
    }
  };

  const handleJoinWaitlist = async () => {
    if (!game || !userProfile) return;
    setIsJoiningWaitlist(true);
    try {
      await game.joinWaitlist();
      // Refresh game data
      const updatedGame = await Game.fetchById(gameId);
      setGame(updatedGame);
    } catch (error) {
      console.error('Failed to join waitlist:', error);
      setError('Failed to join waitlist. Please try again.');
    } finally {
      setIsJoiningWaitlist(false);
    }
  };

  const handleLeaveWaitlist = async () => {
    if (!game || !userProfile) return;
    setIsLeavingWaitlist(true);
    try {
      await game.leaveWaitlist();
      // Refresh game data
      const updatedGame = await Game.fetchById(gameId);
      setGame(updatedGame);
    } catch (error) {
      console.error('Failed to leave waitlist:', error);
      setError('Failed to leave waitlist. Please try again.');
    } finally {
      setIsLeavingWaitlist(false);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: game?.name,
        text: game?.description,
        url: window.location.href,
      });
    }
  };

  const handleGameUpdate = async (updatedData: Partial<Game>) => {
    try {
      if (!game) return;
      await game.update(updatedData);
      const updatedGame = await Game.fetchById(gameId);
      setGame(updatedGame);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Failed to update game:', error);
      setError('Failed to update game details. Please try again.');
    }
  };

  const handleCancelGame = async () => {
    if (!game) return;
    setIsCancelling(true);
    try {
      await game.delete();
      setShowCancelConfirm(false);
      setIsEditModalOpen(false);
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to cancel game:', error);
      setError('Failed to cancel game. Please try again.');
    } finally {
      setIsCancelling(false);
    }
  };

  const isCreatorOrAdmin = userProfile && (game?.creator_id === userProfile.user_id || game?.admin_id === userProfile.user_id);

  if (loading) {
    return <Loading />;
  }

  if (error || !game) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <p className="text-red-500">{error || 'Game not found.'}</p>
      </div>
    );
  }

  const isWaitlisted = userProfile ? game.isUserWaitlisted(userProfile.user_id) : false;
  const isFull = game.isFull();

  const JoinedPlayers: React.FC<{ members: Map<string, GameMember> }> = ({ members }) => {
    const joinedPlayers = Array.from(members.entries())
      // .filter(([_, member]) => member.status === 'joined' && !member.left_at)
      .sort(([_, a], [__, b]) => new Date(a.joined_at).getTime() - new Date(b.joined_at).getTime());

    if (joinedPlayers.length === 0) {
      return (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold mb-4">Players (0)</h3>
        </div>
      );
    }

    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold mb-4">Players ({joinedPlayers.length})</h3>
        <div className="space-y-3">
          {joinedPlayers.map(([userId, _]) => (
            <PlayerCard key={userId} userId={userId} />
          ))}
        </div>
      </div>
    );
  };

  const PlayerCard: React.FC<{ userId: string }> = ({ userId }) => {
    const { userProfile: player, isLoading } = useOtherUserProfile(userId);

    if (isLoading || !player) {
      return (
        <div className="flex items-center space-x-3 p-2 animate-pulse">
          <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
          <div className="flex-1">
            <div className="h-4 bg-gray-200 rounded w-24"></div>
          </div>
        </div>
      );
    }

    return (
      <Link
        to={`/u/${player.username}`}
        className="flex items-center space-x-3 p-2 hover:bg-gray-50 rounded-lg transition-colors"
      >
        {player.profile_picture_url ? (
          <img
            src={player.profile_picture_url}
            alt={player.username}
            className="w-8 h-8 rounded-full object-cover"
          />
        ) : (
          <FaUserCircle className="w-8 h-8 text-gray-400" />
        )}
        <div className="font-medium text-gray-900">{player.username}</div>
      </Link>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavBar />

      {/* Game Banner */}
      <div className="relative h-64 bg-gradient-to-r from-blue-600 to-indigo-700">
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="container mx-auto px-4 h-full flex items-center justify-center relative">
          <div className="text-center text-white">
            <div className="mb-4">
              <span className="inline-block px-3 py-1 bg-green-500 text-white text-sm font-semibold rounded-full">
                {new Date(game.scheduled_at) > new Date() ? 'Upcoming Match' : 'Past Match'}
              </span>
            </div>
            <h1 className="text-4xl font-bold mb-2">{game.name}</h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Game Card */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
              {/* Date and Time */}
              <div className="flex items-center mb-6">
                <FaCalendarAlt className="text-gray-400 mr-3" />
                <span className="text-gray-700">
                  {new Date(game.scheduled_at).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </span>
              </div>

              {/* Location */}
              <div className="flex items-center mb-6">
                <FaMapMarkerAlt className="text-gray-400 text-xl mr-3" />
                <div className="flex-grow">
                    <div className="flex items-center justify-between w-full">
                      <div>
                        <p className="text-gray-600">{game.location}</p>
                        <a
                          href={`https://maps.google.com/?q=${encodeURIComponent(game.location)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-indigo-700 text-sm flex items-center mt-1"
                        >
                          Get Directions <FaExternalLinkAlt className="ml-1 text-xs" />
                        </a>
                      </div>
                    </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-wrap gap-4 mb-8">
                <div className="flex-1 flex items-center gap-3">
                  <div className="flex-1">
                    {hasJoined ? (
                      <button
                        className="w-full bg-green-500 text-white px-6 py-3 rounded-lg flex items-center justify-center gap-2 cursor-default"
                      >
                        <FaCheckCircle className="text-lg" />
                        Joined
                      </button>
                    ) : isFull ? (
                      isWaitlisted ? (
                        <LoadingButton
                          onClick={handleLeaveWaitlist}
                          isLoading={isLeavingWaitlist}
                          className="w-full bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition duration-300 flex items-center justify-center gap-2"
                        >
                          <FaTimesCircle />
                          Leave Waitlist
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          onClick={handleJoinWaitlist}
                          isLoading={isJoiningWaitlist}
                          className="w-full bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 transition duration-300 flex items-center justify-center gap-2"
                        >
                          <FaCalendarPlus />
                          Join Waitlist
                        </LoadingButton>
                      )
                    ) : (
                      <LoadingButton
                        onClick={handleJoinGame}
                        isLoading={isJoining}
                        className="w-full bg-gradient-to-r from-blue-600 to-indigo-700 text-white px-6 py-3 rounded-lg hover:from-blue-700 hover:to-indigo-800 transition duration-300 flex items-center justify-center gap-2"
                      >
                        <FaUsers className="text-lg" />
                        Join Game
                      </LoadingButton>
                    )}
                  </div>
                  {hasJoined && (
                    <div className="relative">
                      <button
                        onClick={() => setShowLeaveConfirm(true)}
                        className="h-12 w-12 flex items-center justify-center text-gray-400 hover:text-red-500 hover:bg-gray-100 rounded-full transition-colors"
                        title="Leave Game"
                      >
                        <FaTimesCircle className="text-xl" />
                      </button>
                      {showLeaveConfirm && (
                        <>
                          <div className="absolute right-0 top-0 mt-14 w-72 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50">
                            <div className="text-gray-800 font-medium mb-2">Leave this game?</div>
                            <div className="text-gray-600 text-sm mb-4">
                              You can always join back if spots are available.
                            </div>
                            <div className="flex gap-2">
                              <LoadingButton
                                onClick={handleLeaveGame}
                                isLoading={isLeaving}
                                className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors flex items-center justify-center gap-1"
                              >
                                <FaTimesCircle className="text-sm" />
                                Leave
                              </LoadingButton>
                              <button
                                onClick={() => setShowLeaveConfirm(false)}
                                className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                          <div
                            onClick={() => setShowLeaveConfirm(false)}
                            className="fixed inset-0 w-full h-full bg-black/30 cursor-default z-40"
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
                <button
                  onClick={handleShare}
                  className="h-12 w-12 flex items-center justify-center text-gray-400 hover:text-indigo-600 hover:bg-gray-100 rounded-full transition-colors"
                  title="Share Game"
                >
                  <FaShare className="text-xl" />
                </button>
                {isCreatorOrAdmin && (
                  <button
                    onClick={() => setIsEditModalOpen(true)}
                    className="h-12 px-4 flex items-center justify-center text-gray-500 hover:text-indigo-600 hover:bg-gray-100 rounded-lg transition-colors"
                    title="Edit Game"
                  >
                    <FaEdit className="text-xl" />
                    <span className="ml-2">Edit Game</span>
                  </button>
                )}
              </div>

              {/* Game Description */}
              <div>
                <p className="text-gray-600 leading-relaxed">
                  {game.description || `Join us for an exciting match at ${game.location}. This game promises to be a thrilling encounter
                  with skilled players and great sportsmanship. Don't miss out on the action!`}
                </p>
              </div>

              {/* Fan Engagement */}
              <div className="border-t mt-8 pt-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-semibold">Discussion</h2>
                </div>
                <button className="w-full bg-gray-50 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center">
                  <FaComments className="mr-2" /> Join Match Discussion
                </button>
              </div>
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-1">
            {/* Quick Info Card */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-6">
              <h3 className="text-lg font-semibold mb-4">Quick Information</h3>
              <ul className="space-y-4">
                <li className="flex items-center text-gray-600">
                  <FaUsers className="mr-3 text-gray-400" />
                  <span>{game.joined_member_count}/{game.max_members} Players</span>
                </li>
                <li className="flex items-center space-x-2">
                  <Link
                    to={creator ? `/u/${creator.username}` : '#'}
                    className="flex items-center space-x-2 hover:text-indigo-600"
                  >
                    {creator?.profile_picture_url ? (
                      <img
                        src={creator.profile_picture_url}
                        alt={creator.username}
                        className="w-6 h-6 rounded-full object-cover"
                      />
                    ) : (
                      <FaUserCircle className="w-6 h-6 text-gray-400" />
                    )}
                    <span className="text-sm">
                      Hosted by @{creator?.username || '...'}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* Players Card */}
            <JoinedPlayers members={game.members} />
          </div>
        </div>
      </div>

      {/* Edit Game Modal */}
      {isEditModalOpen && (
        <Modal 
          onClose={() => setIsEditModalOpen(false)}
          className="w-full max-w-3xl mx-auto"
        >
          <GameForm
            initialGameData={game}
            onSubmit={handleGameUpdate}
            onCancel={handleCancelGame}
          />
        </Modal>
      )}
    </div>
  );
};

export default GameDetailsPage;