import React from 'react';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTrophy, faUsers, faClock, faFlag, faClipboardList, faUserPlus, 
  faBook, faShoePrints, faShieldAlt, faExclamationTriangle, 
  faFileSignature, faCalculator, faTimesCircle, faChartLine, 
  faExchangeAlt, faBullhorn, faFlagCheckered, faHandsHelping, 
  faHourglassHalf, faTasks
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faBell } from '@fortawesome/free-regular-svg-icons';

const Rules: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-green-700 to-blue-800 text-white py-12 min-h-screen">
    <Header />

      <main className="py-20 flex items-center justify-center">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-center">Official Rules and Regulations</h2>
          <div className="bg-white text-blue-900 rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
            <h3 className="text-2xl font-bold mb-6">
              <FontAwesomeIcon icon={faTrophy} className="mr-2" />Tournament Rules
            </h3>
            <ul className="list-none pl-6 space-y-4">
              <li><FontAwesomeIcon icon={faUsers} className="mr-2 text-green-600" />Match Format: 9 vs. 9</li>
              <li><FontAwesomeIcon icon={faClock} className="mr-2 text-green-600" />Duration: Two 20-minute halves with a 5-minute half-time break</li>
              <li><FontAwesomeIcon icon={faFlag} className="mr-2 text-green-600" />Team Reporting: Captains must report to the Referee 15 minutes before kickoff. Forfeit will be declared if a team has fewer than 7 players at kickoff</li>
              <li>
                <FontAwesomeIcon icon={faClipboardList} className="mr-2 text-green-600" />Roster Composition: Maximum 20 players of Indian Origin (9 infield, 11 substitutes)
                <ul className="list-disc pl-6 mt-2">
                  <li>All players must meet OCI eligibility criteria</li>
                </ul>
              </li>
              <li><FontAwesomeIcon icon={faUserPlus} className="mr-2 text-green-600" />Player Addition: Only permitted for injury replacements, supported by a doctor's note</li>
              <li><FontAwesomeIcon icon={faEnvelope} className="mr-2 text-green-600" />Replacement Requests: Must be sent to info@kickoff.game by 5 PM PST on the Thursday before a Saturday game</li>
              <li><FontAwesomeIcon icon={faBook} className="mr-2 text-green-600" />Governing Rules: FIFA rules apply unless otherwise specified</li>
              <li>
                <FontAwesomeIcon icon={faShoePrints} className="mr-2 text-green-600" />Sliding Tackles: 
                <ul className="list-disc pl-6 mt-2">
                  <li>Clean sliding tackles that clearly win the ball are permitted</li>
                  <li>Goalkeepers: May slide-tackle only when clearly going for the ball within their penalty area</li>
                </ul>
              </li>
              <li><FontAwesomeIcon icon={faShieldAlt} className="mr-2 text-green-600" />Equipment: Shin guards are mandatory without exception</li>
              <li>
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-yellow-600" />Disciplinary Actions:
                <ul className="list-disc pl-6 mt-2">
                  <li>Red card: Player misses the next match</li>
                  <li>Three yellow cards: Player misses the next match</li>
                </ul>
              </li>
              <li><FontAwesomeIcon icon={faFileSignature} className="mr-2 text-green-600" />Liability Waiver: By joining the roster, players acknowledge the risks and waive their right to sue the organizers or associated parties</li>
              <li><FontAwesomeIcon icon={faCalculator} className="mr-2 text-green-600" />Point System: Win = 3 points; Tie = 1 point; Loss = 0 points; Forfeit = -3 points</li>
              <li><FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-red-600" />Forfeit Score: 3-0 to the winning team</li>
              <li><FontAwesomeIcon icon={faBell} className="mr-2 text-green-600" />Forfeit Notification: Inform organizers and opposing team by 5 PM PST Friday before the Saturday game</li>
            </ul>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faChartLine} className="mr-2" />Playoff Seeding
            </h4>
            <ul className="list-disc pl-6 space-y-2">
              <li>Top two teams from each group qualify for semi-finals</li>
              <li>Tiebreakers (in order): Points, Goal Differential, Goals For, Goals Against, Fair Play (Red card: -3 points, Yellow card: -1 point), Coin Toss</li>
            </ul>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />Substitutions and Conduct
            </h4>
            <ul className="list-disc pl-6 space-y-2">
              <li>Unlimited substitutions permitted when play is stopped (throw-ins and goal kicks)</li>
              <li>Referee abuse results in automatic ejection</li>
              <li>Maintain good sportsmanship; unacceptable behavior may lead to suspension or ejection</li>
            </ul>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faBullhorn} className="mr-2" />Verbal Abuse
            </h4>
            <p>Abuse directed at the referee results in a direct Red Card. Friendly banter between teams is acceptable, but abusive language towards the opposing team, if heard by the referee or reported to the Game Manager, will result in a direct red card for the involved player(s).</p>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faFlagCheckered} className="mr-2" />Fouls & Cards
            </h4>
            <p>Goalkeepers who are not team captains should remain in their box and refrain from protesting decisions or engaging in discussions with the referee or opposing team. Violations will result in a yellow card. No substitute players, team supporters, or others are allowed behind the goal line or adjacent to it up to the corner flag.</p>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faHandsHelping} className="mr-2" />Goalkeeper Equipment & Substitution
            </h4>
            <ul className="list-disc pl-6 space-y-2">
              <li>Goalkeepers must wear a jersey of a different color from their team (minimum requirement: bib)</li>
              <li>Mandatory equipment: Gloves, shin guards, and proper playing boots</li>
              <li>Substitutions allowed only during goal kicks or free-kicks in favor of the substituting team, except for serious injuries</li>
            </ul>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faHourglassHalf} className="mr-2" />Stoppage Time
            </h4>
            <p>A maximum of 5 minutes of stoppage time is available for both halves combined. Minimal stoppage time will be used at the end of the first half. Injured players, including goalkeepers, must be substituted within a reasonable time to prevent time-wasting tactics.</p>

            <h4 className="text-xl font-bold mt-8 mb-4">
              <FontAwesomeIcon icon={faTasks} className="mr-2" />Team Coordinator Responsibilities
            </h4>
            <ul className="list-disc pl-6 space-y-2">
              <li>Submit team roster two weeks before the tournament start date</li>
              <li>Confirm jersey colors at the time of registration</li>
              <li>Ensure team brings water, other hydration supplies, and a medical kit</li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Rules;