import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaComments, FaChevronLeft, FaChevronRight, FaSpinner, FaUserCircle } from 'react-icons/fa';
import { Chatroom } from '../models/chatroom';
import { User } from '../models/user';
import { useIsMobile } from '../hooks/useIsMobile';

interface ChatListProps {
  chatrooms: Chatroom[];
  loading?: boolean;
  error?: Error;
  currentUserProfile: User;
  otherProfiles: { [key: string]: User };
  className?: string;
}

const ChatList: React.FC<ChatListProps> = ({ 
  chatrooms,
  loading,
  error,
  currentUserProfile,
  otherProfiles,
  className = ''
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const isMobile = useIsMobile();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [chatrooms]);

  if (!currentUserProfile) return null;

  const areAllProfilesLoaded = (): boolean => {
    if (!chatrooms) return true;
    return chatrooms.every(chatroom => {
      if (chatroom.chatroom_type === 'direct_message') {
        const otherUserId = Object.keys(chatroom.members).find(
          (id) => id !== currentUserProfile.user_id
        );
        return otherUserId ? !!otherProfiles[otherUserId] : true;
      }
      return true;
    });
  };

  const hasUnreadMessages = (chatroom: Chatroom): boolean => {
    if (!currentUserProfile?.user_id) return false;
    const currentUserMember = chatroom.members[currentUserProfile.user_id];
    const lastReadMessage = currentUserMember?.last_read_message || '';
    return Object.values(chatroom.members).some(
      (member) => member.member_id !== currentUserProfile.user_id && member.last_read_message > lastReadMessage
    );
  };

  const getChatDisplayName = (chatroom: Chatroom): string => {
    if (chatroom.chatroom_type === 'direct_message' && currentUserProfile?.user_id) {
      const otherUserId = Object.keys(chatroom.members).find((id) => id !== currentUserProfile.user_id);
      if (!otherUserId) return 'Unknown User';
      const otherProfile = otherProfiles[otherUserId];
      return otherProfile?.username || 'Unknown User';
    }
    return chatroom.name || 'Unnamed Chat';
  };

  const getChatAvatar = (chatroom: Chatroom): string | null => {
    if (chatroom.chatroom_type === 'direct_message' && currentUserProfile?.user_id) {
      const otherUserId = Object.keys(chatroom.members).find((id) => id !== currentUserProfile.user_id);
      if (!otherUserId) return null;
      const otherProfile = otherProfiles[otherUserId];
      return otherProfile?.profile_picture_url || null;
    }
    return null;
  };

  const timeAgo = (timestamp: string | undefined): string => {
    if (!timestamp?.trim()) return '';
    if (timestamp === '0') return '';
    if (timestamp === '1970-01-01T00:00:00.000Z') return '';
    if (timestamp === '1970-01-01T00:00:00Z') return '';
    if (timestamp === '1970-01-01T00:00:00') return '';
    if (timestamp === '0001-01-01T00:00:00Z') return '';
    if (timestamp === '') return '';
    try {
      const timestampMs = Date.parse(timestamp);
      if (isNaN(timestampMs)) return '';
      
      const now = Date.now();
      const diff = Math.floor((now - timestampMs) / 1000);

      if (diff < 60) {
        return `${diff}s ago`;
      } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      } else if (diff < 86400) {
        const hours = Math.floor(diff / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else if (diff < 604800) {
        const days = Math.floor(diff / 86400);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
      } else if (diff < 2419200) {
        const weeks = Math.floor(diff / 604800);
        return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
      } else if (diff < 29030400) {
        const months = Math.floor(diff / 2419200);
        return `${months} month${months !== 1 ? 's' : ''} ago`;
      } else {
        const years = Math.floor(diff / 29030400);
        return `${years} year${years !== 1 ? 's' : ''} ago`;
      }
    } catch {
      return '';
    }
  };

  return (
    <div className={`flex h-full ${className}`}>
      {!isMobile && (
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label={isCollapsed ? 'Expand chat list' : 'Collapse chat list'}
        >
          {isCollapsed ? <FaChevronRight size={20} /> : <FaChevronLeft size={20} />}
        </button>
      )}
      {(!isCollapsed || isMobile) && (
        <div 
          ref={containerRef}
          className={`bg-white ${isMobile ? 'w-full' : 'w-72'} p-4 border-r overflow-y-auto`}
          style={isMobile ? {
            height: '100%',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            paddingBottom: 'calc(16px + env(safe-area-inset-bottom, 0px))'
          } : undefined}
        >
          {(loading || !areAllProfilesLoaded()) ? (
            <div className="flex justify-center items-center h-20">
              <FaSpinner className="animate-spin text-blue-500" size={24} />
            </div>
          ) : error ? (
            <div className="text-red-500 p-4">{error.message}</div>
          ) : chatrooms?.length === 0 ? (
            <div className="text-gray-500 text-center p-4">No chats available</div>
          ) : (
            <ul className="space-y-2">
              {chatrooms.filter(chatroom => timeAgo(chatroom.last_message_timestamp)).map((chatroom) => (
                <li key={chatroom.chatroom_id}>
                  <Link
                    to={`/chat/${chatroom.chatroom_id}`}
                    className={`flex items-center p-4 rounded-lg relative ${
                      location.pathname === `/chat/${chatroom.chatroom_id}` 
                        ? 'bg-blue-50 border border-blue-100' 
                        : 'hover:bg-gray-50 active:bg-gray-100'
                    } ${isMobile ? 'min-h-[72px]' : ''}`}
                  >
                    {hasUnreadMessages(chatroom) && (
                      <div className="absolute w-2.5 h-2.5 bg-red-500 rounded-full top-4 right-3" />
                    )}
                    <div className="flex items-center space-x-4 flex-1 min-w-0">
                      <div className={`${isMobile ? 'w-12 h-12' : 'w-10 h-10'} rounded-full bg-gray-100 flex items-center justify-center overflow-hidden flex-shrink-0`}>
                        {getChatAvatar(chatroom) ? (
                          <img
                            src={getChatAvatar(chatroom) || ''}
                            alt={getChatDisplayName(chatroom)}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <FaUserCircle className="w-full h-full text-gray-400" />
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="font-medium text-gray-900 truncate">
                          {getChatDisplayName(chatroom)}
                        </div>
                        {/* {chatroom.last_message_timestamp && (
                          <div className="text-sm text-gray-500 truncate">
                            {chatroom.last_message_timestamp}
                          </div>
                        )} */}
                        {chatroom.last_message_timestamp && (
                          <div className="text-xs text-gray-400 mt-1">
                            {timeAgo(chatroom.last_message_timestamp)}
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatList;
