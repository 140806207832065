import React from 'react';
import { FaClock, FaMapMarkerAlt, FaUsers } from 'react-icons/fa';
import { Game } from '../models/games';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface GameCardProps {
  game: Game;
  userID: string;
}

const GameCard: React.FC<GameCardProps> = ({ game, userID }) => {
  const navigate = useNavigate();
  const date = new Date(game.scheduled_at);
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const [gameEvents, setGameEvents] = useState('');

  useEffect(() => {
    // Fetch game events when component mounts
    Game.getGameEvents(game.game_id).then((events) => {
      setGameEvents(events);
    });
  }, [game.game_id]);

  const handleCardClick = () => {
    navigate(`/game/${game.game_id}`);
  };

  return (
    <div
      className="flex flex-col md:flex-row items-stretch bg-white shadow-md rounded-lg overflow-hidden mb-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer"
      onClick={handleCardClick}
    >
      {/* Date Section */}
      <div className="flex flex-col items-center justify-center w-full md:w-20 bg-gradient-to-br from-blue-600 to-indigo-700 text-white hidden md:flex p-2">
        <div className="text-2xl font-bold">{date.getDate()}</div>
        <div className="text-sm uppercase">{date.toLocaleString('en-US', { month: 'short' })}</div>
      </div>

      {/* Divider Line */}
      <div className="h-px md:h-full w-full md:w-px bg-gray-200"></div>

      {/* Content Section */}
      <div className="flex flex-col justify-center p-4 flex-grow">
        {/* Game Title */}
        <div className="flex justify-between items-start mb-2">
          <h2 className="text-xl font-semibold text-gray-800 truncate">{game.name}</h2>
        </div>

        {/* Game Details */}
        <div className="flex items-center text-gray-600 text-sm mb-2">
          <FaClock className="mr-2 text-indigo-600" />
          <span>{`${formattedDate} at ${formattedTime}`}</span>
        </div>
        {game.location && (
          <div className="flex items-center text-gray-600 text-sm mb-2 truncate">
            <FaMapMarkerAlt className="mr-2 text-indigo-600" />
            <span>{game.location}</span>
          </div>
        )}
        <div className="flex items-center text-gray-600 text-sm">
          <FaUsers className="mr-2 text-indigo-600" />
          <span>{`${game.joined_member_count}/${game.max_members} players joined`}</span>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
