import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { User } from '../models/user';
import { Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const SearchBar: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<{
    [key: string]: Array<User & { resultType: string }>;
  }>({});
  const [activeTab, setActiveTab] = useState<string>('all');
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults({
        all: [],
        user: [],
        location: [],
        game: [],
        tournament: [],
        club: []
      });
      setShowDropdown(false);
      return;
    }

    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const keyMatch = searchQuery.match(/^(user:|location:|game:|tournament:|club:)\s*(.*)$/i);
      let key = null;
      let query = searchQuery;

      if (keyMatch) {
        key = keyMatch[1].replace(':', '').toLowerCase();
        query = keyMatch[2];
      }

      setSearchKey(key);
      setActiveTab(key || 'all');

      User.searchUsers(query).then((results: User[]) => {
        const resultsWithType = results.map(result => ({
          ...result,
          resultType: 'user'
        })) as Array<User & { resultType: string }>;
        
        setSearchResults({
          all: resultsWithType,
          user: resultsWithType,
          location: [],
          game: [],
          tournament: [],
          club: []
        });
        setLoading(false);
        setShowDropdown(true);
      }).catch(() => {
        setLoading(false);
      });
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
      setLoading(false);
    };
  }, [searchQuery]);

  return (
    <div className="flex-1 mx-4 relative" ref={dropdownRef}>
      <div className="relative">
        <input
          type="text"
          className="w-full bg-gray-100 rounded-full pl-10 pr-4 py-2 focus:outline-none focus:bg-white focus:ring-2 focus:ring-indigo-600 transition duration-300"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setSearchResults({}); // Clear previous results on input change
          }}
          onFocus={() => {
            setShowDropdown(true); // Always show dropdown on focus
          }}
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
      </div>
      {showDropdown && (
        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-50">
          {/* Tabs */}
          <div className="flex border-b">
            {['all', 'user', 'location', 'game', 'tournament', 'club'].map((type) => (
              <button
                key={type}
                className={`flex-1 px-4 py-2 text-sm font-medium ${
                  activeTab === type ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-600'
                } hover:bg-gray-100`}
                onClick={() => setActiveTab(type)}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>
          {/* Results */}
          <div className="max-h-60 overflow-y-auto">
            {loading ? (
              <div className="flex items-center justify-center py-4">
                <LoadingSpinner size={32} colorClass="text-indigo-600" />
              </div>
            ) : (
              searchResults[activeTab] && searchResults[activeTab].length > 0 ? (
                searchResults[activeTab]
                  .filter(result => activeTab === 'all' || result.resultType === activeTab.toLowerCase())
                  .map((result: any, index: number) => {
                    const routes: Record<string, string> = {
                      user: `/u/${result.username}`,
                      game: `/g/${result.game_id}`,
                      club: `/c/${result.club_id}`,
                      team: `/t/${result.team_id}`,
                      tournament: `/tt/${result.tournament_id}`
                    };
                    
                    return (
                      <Link
                        to={routes[result.resultType] || '/'}
                        key={index}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-3"
                        onClick={() => setShowDropdown(false)}
                      >
                        {result.resultType === 'user' ? (
                          <>
                            <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                              <span className="text-gray-500 text-sm">
                                {result.username?.charAt(0).toUpperCase()}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-800">{result.username}</span>
                              {(result.first_name || result.last_name) && (
                                <span className="text-gray-500 text-sm">
                                  {[result.first_name, result.last_name].filter(Boolean).join(' ')}
                                </span>
                              )}
                            </div>
                          </>
                        ) : (
                          <span className="text-gray-800">{result.title}</span>
                        )}
                      </Link>
                    );
                  })
              ) : (
                <div className="px-4 py-2 text-gray-500">No results found.</div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
