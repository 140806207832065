import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Tournament from './Tournament';
import Contact from './Contact';

const Home: React.FC = () => {
  const [isContactFormVisible, setIsContactFormVisible] = useState(false);

  const handleContactButtonClick = () => {
    setIsContactFormVisible(true);
  };

  const handleCloseContactForm = () => {
    setIsContactFormVisible(false);
  };

  const handleSignIn = () => {
    window.location.href = '/signin';
  };

  return (
    <div className="bg-gradient-to-r from-green-700 to-blue-800 py-12 text-white">
      <Header />

      <main>
        <section id="hero" className="py-20 text-center">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center justify-center mb-12">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <h2 className="text-4xl sm:text-6xl font-bold mb-6">Experience Soccer Glory</h2>
                <p className="text-xl sm:text-2xl mb-8">Join us for an unforgettable tournament</p>
                <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                  <button
                    onClick={handleSignIn}
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-full transition transform hover:scale-105"
                  >
                    Sign In/Register
                  </button>
                  {/* Button to show Contact Us form */}
                  <button
                    onClick={handleContactButtonClick}
                    className="bg-transparent border-2 border-white hover:bg-white hover:text-blue-900 font-bold py-3 px-6 rounded-full transition transform hover:scale-105"
                  >
                    Contact Us
                  </button>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="calendar-artwork w-64 h-64 mx-auto bg-white text-blue-800 rounded-lg shadow-lg overflow-hidden">
                  <div className="bg-red-500 text-white py-2 text-2xl font-bold">
                    September 2024
                  </div>
                  <div className="p-4">
                    <div className="text-8xl font-bold">22</div>
                    <div className="text-2xl mt-2">Sunday</div>
                  </div>
                  <div className="bg-yellow-400 text-blue-900 py-2 text-xl font-bold">
                    9:00 AM - 6:30 PM
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Tournament />

        {/* Contact Us Overlay */}
        {isContactFormVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white text-blue-900 rounded-lg shadow-lg p-6 max-w-2xl mx-auto relative">
              {/* Close button */}
              <button
                onClick={handleCloseContactForm}
                className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
              >
                &#x2715; {/* Close icon */}
              </button>
              <Contact/>
            </div>
          </div>
        )}
      </main>

      <footer className="bg-gradient-to-r from-green-700 to-blue-800 py-12 text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-8 md:mb-0">
              <h3 className="text-2xl font-bold mb-4">Kickoff Championship</h3>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <button
                onClick={handleContactButtonClick}
                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full transition transform hover:scale-105"
              >
                Contact Us
              </button>
              <div className="flex space-x-4">
                {/* Social media links can be added here if needed */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
