import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const UnderConstruction: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 8, textAlign: 'center' }}>
      <Paper elevation={3} sx={{ p: 4, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
        <ConstructionIcon sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
        <Typography variant="h3" component="h1" gutterBottom>
          Under Construction
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Exciting New Features Coming Soon!
        </Typography>
        <Box sx={{ my: 4 }}>
          <Typography variant="body1" paragraph>
            We're working hard to bring you an enhanced registration experience.
          </Typography>
          <Typography variant="body1" paragraph>
            Stay tuned for updates and get ready to join the championship!
          </Typography>
        </Box>
        <Typography variant="subtitle1" color="text.secondary">
          Thank you for your patience and interest in our tournaments.
        </Typography>
      </Paper>
    </Container>
  );
};

export default UnderConstruction;
