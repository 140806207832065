import React, { useState, useEffect, useMemo } from 'react';
import TopNavBar from '../components/TopNavBar';
import UserProfileCard from '../components/UserProfileCard';
import Memberships from '../components/Memberships';
import FriendsList from '../components/FriendsList';
import GamesList from '../components/GamesList';
import BottomNavBar from '../components/BottomNavBar';
import FloatingActionButtons from '../components/FloatingActionButtons';
import { useUserGames } from '../hooks/useUserGames';
import { Waiver } from '../components/Waiver';
import Modal from '../components/Modal';
// Import the API function to fetch user data
import { User } from '../models/user';
import { Game } from '../models/games';
import Loading from '../components/Loading'; // Import the Loading component
// import { useUserTeams } from '../hooks/useUserTeams';
// Import useNavigate for navigation
import { useNavigate } from 'react-router-dom';
import TeamStandings from '../components/TeamStandings';
import { Team, Standing } from '../models/teams';
import UserFriendsCard from 'components/UserFriendsCard';
import { useUserProfile } from '../hooks/useUserProfile'; // Import the improved useUserProfile hook

const Dashboard: React.FC = () => {
	const { userGames, isGamesLoading, error: gamesError, refreshGames } = useUserGames();
	const navigate = useNavigate();
	const { userProfile, isLoading: isProfileLoading, error: profileError, refreshProfile } = useUserProfile();

	// const [activeTab, setActiveTab] = useState('forYou');
	// const [filter, setFilter] = useState('all');
	// const { userTeams, isTeamsLoading, error: teamsError, addTeam } = useUserTeams();
	const [standings, setStandings] = useState<Standing[]>([]);
	const [isWaiverSigned, setIsWaiverSigned] = useState<boolean | null>(true); // Set default to true to skip waiver check

	const [cachedGames, setCachedGames] = useState<Game[]>([]);

	useEffect(() => {
		if (userGames && userGames.length > 0) {
			setCachedGames(userGames);
		}
	}, [userGames]);

	// Filter games based on the current filter
	const filteredGames = useMemo(() => {
		if (!userGames) return [];
		
		const oneHourAgo = new Date(Date.now() - 3600000);
		
		return userGames
			.filter(game => new Date(game.scheduled_at) > oneHourAgo)
			.sort((a, b) => new Date(a.scheduled_at).getTime() - new Date(b.scheduled_at).getTime());
	}, [userGames]);

	// Function to calculate distance between two points using Haversine formula
	const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
		const R = 6371; // Earth's radius in km
		const dLat = (lat2 - lat1) * Math.PI / 180;
		const dLon = (lon2 - lon1) * Math.PI / 180;
		const a = 
			Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
			Math.sin(dLon/2) * Math.sin(dLon/2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		return R * c;
	};

	// Log any errors to console
	// useEffect(() => {
	// 	if (gamesError) console.error('Games Error:', gamesError);
	// 	if (profileError) console.error('Profile Error:', profileError);
	// 	if (teamsError) console.error('Teams Error:', teamsError);
	// }, [gamesError, profileError, teamsError]);

	// useEffect(() => {
	// 	const fetchAllStandings = async () => {
	// 		if (userTeams && userTeams.length > 0) {
	// 			try {
	// 				// Commented out standings fetch
	// 				/*
	// 				const standingsData = await Promise.all(
	// 					userTeams.map(async (teamData) => {
	// 						const team = new Team(teamData);
	// 						const teamStanding = await team.getStanding();
	// 						return {
	// 							...teamStanding,
	// 							team_name: team.name,
	// 						};
	// 					})
	// 				);
	// 				const sortedStandings = standingsData.slice().sort((a, b) => a.position - b.position);
	// 				setStandings(sortedStandings);
	// 				*/
	// 				setStandings([]); // Set empty standings
	// 			} catch (error) {
	// 				console.error('Error fetching standings:', error);
	// 			}
	// 		}
	// 	};

	// 	fetchAllStandings();
	// }, [userTeams]);

	// Add InlineLoader component
	const InlineLoader: React.FC = () => {
		return (
			<div className="flex justify-center items-center py-10" role="status" aria-live="polite">
				<svg
					className="animate-spin h-8 w-8 text-indigo-600"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					aria-hidden="true"
				>
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
					<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
				</svg>
				<span className="ml-2 text-gray-600">Loading games...</span>
			</div>
		);
	};

	// Function to handle waiver signed status
	const handleWaiverSigned = () => {
		setIsWaiverSigned(true);
	};

	return (
		<div className="min-h-screen bg-gray-50 relative">
			{/* Top Navigation Bar */}
			<TopNavBar />

			{/* Main Content */}
			<div className="container mx-auto px-4 py-8">
				<div className="md:flex md:gap-8">
					{/* Left Column */}
					<div className="md:w-1/3 lg:w-1/4 hidden md:block overflow-y-auto max-h-screen">
						<UserProfileCard />
						{userProfile && <UserFriendsCard userId={userProfile.user_id!} />}
						{/* <Memberships memberships={memberships} />
            <FriendsList friends={friends} /> */}
					</div>

					{/* Right Column */}
					<div className="md:w-2/3 lg:w-3/4">
						{/* Handle Games List */}
						{filteredGames && filteredGames.length > 0 && <GamesList games={filteredGames} userID={userProfile?.user_id} />}

						{/* Handle No Games Found */}
						{!isGamesLoading && filteredGames && filteredGames.length === 0 && (
							<div className="flex flex-col items-center justify-center py-20">
								<div
										className="flex flex-col items-center justify-center cursor-pointer transition-transform transform hover:scale-105 hover:bg-gray-100 p-4 rounded-xl"
										onClick={() => navigate('/create-game')}
								>
									<svg
										className="w-16 h-16 text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
									</svg>
								</div>
								<h2 className="text-xl font-semibold text-gray-700 mb-2 mt-2">No Games Found</h2>
								<p className="text-gray-500">Create a game to get started</p>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Bottom Navigation Bar for Mobile */}
			{/* <BottomNavBar activeTab={activeTab} setActiveTab={setActiveTab} /> */}

			{/* Floating Action Buttons */}
			{/* <FloatingActionButtons /> */}

			{/* Waiver Modal */}
			{/* {isWaiverSigned === false && (
				<Modal>
					<Waiver onWaiverSigned={handleWaiverSigned} />
				</Modal>
			)} */}
		</div>
	);
};

export default Dashboard;
