import React from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-700 to-blue-800 flex flex-col">
      {/* Top Navigation Bar */}
      {/* <TopNavBar /> */}

      {/* Main Content */}
      <div className="flex flex-col flex-grow items-center justify-center text-white px-4 text-center">
        <h1 className="text-6xl font-bold mb-4">
          4<span className="animate-bounce inline-block">0</span>4
        </h1>
        <p className="text-xl mb-8">
          Oops! The page you're looking for doesn't exist.
        </p>
        <button
          onClick={() => navigate('/')}
          className="px-6 py-3 bg-white text-blue-800 font-semibold rounded-full shadow-md hover:bg-gray-100 transition-colors"
        >
          Go Back Home
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage; 