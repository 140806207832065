import React, { useState, useEffect } from 'react';
import { User } from '../models/user';
import LoadingButton from './LoadingButton';    // Import LoadingButton
import { FaUserShield, FaSearch, FaUserFriends } from 'react-icons/fa';

interface PrivacySettingsProps {
  user: User;
  onUpdate: (updatedUser: Partial<User>) => Promise<void>;
}

const PrivacySettings: React.FC<PrivacySettingsProps> = ({ user, onUpdate }) => {
  const [publicProfile, setPublicProfile] = useState(false);
  const [allowSearch, setAllowSearch] = useState(false);
  const [allowInvites, setAllowInvites] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);    // Add isUpdating state

  useEffect(() => {
    if (user) {
      setPublicProfile(user.privacy_settings.public_profile || false);
      setAllowSearch(user.privacy_settings.allow_search || false);
      setAllowInvites(user.privacy_settings.allow_invites || false);
    }
  }, [user]);

  const handleSave = async () => {
    setIsUpdating(true);    // Start loading

    try {
      const updatedSetting: Partial<User> = {
        privacy_settings: {
          public_profile: publicProfile,
          allow_search: allowSearch,
          allow_invites: allowInvites,
        },
      };
      await onUpdate(updatedSetting);
      // You can add a success message here if needed
    } catch (error) {
      console.error('Failed to update privacy settings:', error);
      // You can add an error message here if needed
    } finally {
      setIsUpdating(false);    // End loading
    }
  };

  return (
    <div className="col-span-1 md:col-span-2">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Privacy Settings</h2>
      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={publicProfile}
            onChange={() => setPublicProfile(!publicProfile)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
          />
          <label className="ml-3 flex items-center text-gray-700">
            <FaUserShield className="mr-2 text-gray-400" />
            Make my profile public
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={allowSearch}
            onChange={() => setAllowSearch(!allowSearch)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
          />
          <label className="ml-3 flex items-center text-gray-700">
            <FaSearch className="mr-2 text-gray-400" />
            Allow others to find me in search
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={allowInvites}
            onChange={() => setAllowInvites(!allowInvites)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
          />
          <label className="ml-3 flex items-center text-gray-700">
            <FaUserFriends className="mr-2 text-gray-400" />
            Allow invites from other users
          </label>
        </div>
        <LoadingButton
          isLoading={isUpdating}
          onClick={handleSave}
          buttonClass="bg-indigo-600 hover:bg-indigo-700 text-white"
          aria-label="Saving..."
        >
          Save Changes
        </LoadingButton>
      </div>
    </div>
  );
};

export default PrivacySettings;
