export type NotificationType =
    | 'chat_update'
    | 'game_update'
    | 'game_created'
    | 'game_joined'
    | 'game_canceled'
    | 'friend_request'
    | 'friend_accepted';

export type ConnectionStatus = 'connecting' | 'online' | 'away' | 'offline';

export interface Notification {
    notification_id: string;
    user_id: string;
    notification_time: Date;
    type: NotificationType;
    source_id: string;
    content: string;
    read: boolean;
    priority: number;
    expires_at: Date;
}

export class NotificationAPI {
    private static ws: WebSocket | null = null;
    private static messageQueue: Array<any> = [];
    private static listeners: Set<(notification: Notification) => void> = new Set();
    private static statusListeners: Set<(status: ConnectionStatus) => void> = new Set();
    private static reconnectTimeout: NodeJS.Timeout | null = null;
    private static reconnectAttempts = 0;
    private static readonly RECONNECT_DELAYS = [1000, 2000, 5000, 10000, 30000];

    static async connect(accountId: string): Promise<void> {
        const token = localStorage.getItem('accessToken');
        if (!token) throw new Error('No authentication token found');

        console.log('[Notifications] Connecting to notifier:', accountId);

        try {
            this.notifyStatusListeners('connecting');
            const baseUrl = process.env.REACT_APP_API_URL ?? '';
            const url = new URL(`${baseUrl}/api/notifier/v1/${accountId}`);
            url.protocol = url.protocol.replace('http', 'ws');

            this.ws = new WebSocket(url.toString());
            
            this.ws.onopen = () => {
                console.log('[Notifications] Connected to notifier:', accountId);
                this.ws?.send(JSON.stringify({ 
                    type: 'auth', 
                    token,
                    accountId 
                }));
                this.notifyStatusListeners('online');
                this.reconnectAttempts = 0;
                this.processQueue();
            };

            this.ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data.type === 'notification') {
                        this.notifyListeners(this.transformNotification(data.payload));
                    }
                } catch (error) {
                    console.error('[Notifications] Failed to process message:', error);
                }
            };

            this.ws.onclose = (event) => {
                console.log('[Notifications] Connection closed:', {
                    code: event.code,
                    reason: event.reason,
                    wasClean: event.wasClean
                });
                this.notifyStatusListeners('offline');
                
                if (event.code !== 1000) { // Not a clean close
                    this.scheduleReconnect(accountId);
                }
            };

            this.ws.onerror = (error) => {
                console.error('[Notifications] WebSocket error:', error);
                // Check if error is due to incorrect upgrade
                if ((error as any)?.code === 426) {
                    console.error('[Notifications] WebSocket upgrade required - check server configuration');
                }
            };
        } catch (error) {
            console.error('[Notifications] Failed to establish connection:', error);
            this.notifyStatusListeners('offline');
            this.scheduleReconnect(accountId);
        }
    }

    static subscribe(callback: (notification: Notification) => void): () => void {
        this.listeners.add(callback);
        return () => this.listeners.delete(callback);
    }

    static subscribeToStatus(callback: (status: ConnectionStatus) => void): () => void {
        this.statusListeners.add(callback);
        return () => this.statusListeners.delete(callback);
    }

    static async markAsRead(notificationId: string): Promise<void> {
        const message = {
            type: 'status',
            payload: {
                messageId: notificationId,
                status: 'read',
                timestamp: Date.now()
            }
        };

        await this.sendMessage(message);
    }

    static async deleteNotification(notificationId: string): Promise<void> {
        const message = {
            type: 'delete',
            payload: {
                messageId: notificationId,
                timestamp: Date.now()
            }
        };

        await this.sendMessage(message);
    }

    private static async sendMessage(message: any): Promise<void> {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        } else {
            this.messageQueue.push(message);
        }
    }

    private static notifyListeners(notification: Notification): void {
        this.listeners.forEach(listener => {
            try {
                listener(notification);
            } catch (error) {
                console.error('[Notifications] Listener error:', error);
            }
        });
    }

    private static notifyStatusListeners(status: ConnectionStatus): void {
        this.statusListeners.forEach(listener => {
            try {
                listener(status);
            } catch (error) {
                console.error('[Notifications] Status listener error:', error);
            }
        });
    }

    private static processQueue(): void {
        while (this.messageQueue.length > 0) {
            const message = this.messageQueue.shift();
            if (message) {
                try {
                    this.sendMessage(message);
                } catch (error) {
                    console.error('[Notifications] Failed to process queued message:', error);
                    // Put the message back in the queue
                    this.messageQueue.unshift(message);
                    break;
                }
            }
        }
    }

    private static transformNotification(payload: any): Notification {
        return {
            notification_id: payload.id,
            user_id: payload.user_id,
            notification_time: new Date(payload.timestamp),
            type: payload.type,
            source_id: payload.source_id,
            content: payload.content,
            read: false,
            priority: payload.priority ?? 0,
            expires_at: new Date(payload.expires_at)
        };
    }

    private static scheduleReconnect(accountId: string): void {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }

        const delay = this.RECONNECT_DELAYS[
            Math.min(this.reconnectAttempts, this.RECONNECT_DELAYS.length - 1)
        ];
        console.log(`[Notifications] Reconnecting in ${delay}ms (attempt ${this.reconnectAttempts + 1})`);
        
        this.reconnectTimeout = setTimeout(() => {
            this.reconnectAttempts++;
            this.connect(accountId);
        }, delay);
    }

    static disconnect(): void {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }
        if (this.ws) {
            this.ws.close(1000, 'Client disconnecting');
            this.ws = null;
        }
        this.listeners.clear();
        this.statusListeners.clear();
        this.messageQueue = [];
        this.reconnectAttempts = 0;
    }
} 