import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaPaperPlane, FaSpinner, FaPlus, FaSync, FaChevronDown } from 'react-icons/fa';
import LoadingSpinner from './LoadingSpinner';
import { User } from '../models/user';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../hooks/useIsMobile';
import MobileChatInterface from './mobile/MobileChatInterface';

interface Attachment {
	type: 'link' | 'image' | 'document';
	url: string;
	title?: string;
	mimeType?: string;
	size?: number;
	previewUrl?: string;
}

interface ChatMessage {
	id: string;
	content: {
		text?: string;
		attachments?: Attachment[];
	};
	timestamp: number;
	sender: {
		id: string;
		name: string;
	};
	status?: {
		sent: number;
		delivered: { [userId: string]: number };
		read: { [userId: string]: number };
		reactions?: { [userId: string]: string };
	};
}

interface ChatInterfaceProps {
	chatRoomId: string;
	chatRoomName: string;
	chatroomType: string;
	participants: string[];
	currentUser: {
		id: string;
		name: string;
	};
	otherProfiles: { [key: string]: User };
	onRefresh?: () => void;
	refreshKey?: number;
}

const WS_URL = `wss://kickoff.game/api/chat/v1/`;

const ChatInterface: React.FC<ChatInterfaceProps> = ({ 
	chatRoomId, 
	chatRoomName, 
	chatroomType,
	participants,
	currentUser, 
	otherProfiles,
	onRefresh, 
	refreshKey = 0 
}) => {
	const isMobile = useIsMobile();
	const [messages, setMessages] = useState<ChatMessage[]>(() => {
		// Try to load initial messages from cache
		const cachedMessages = localStorage.getItem(`chat_messages_${chatRoomId}`);
		const parsedMessages = cachedMessages ? JSON.parse(cachedMessages) : [];
		
		// Initialize loading state for any images in cached messages
		if (parsedMessages.length > 0) {
			const imageUrls = parsedMessages
				.flatMap(msg => msg.content?.attachments || [])
				.filter(att => att.type === 'image')
				.map(att => att.url);
			
			if (imageUrls.length > 0) {
				setTimeout(() => {
					setLoadingImages(prev => {
						const newSet = new Set(prev);
						imageUrls.forEach(url => {
							newSet.add(url);
							preloadImage(url);
						});
						return newSet;
					});
				}, 0);
			}
		}
		
		return parsedMessages;
	});
	const [inputMessage, setInputMessage] = useState('');
	const [isConnected, setIsConnected] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [connectionError, setConnectionError] = useState(false);
	const [uploadingFiles, setUploadingFiles] = useState<Set<string>>(new Set());
	const [imagePreview, setImagePreview] = useState<{ file: File; preview: string } | null>(null);
	const [loadingImages, setLoadingImages] = useState<Set<string>>(new Set());
	const [imageAspectRatios, setImageAspectRatios] = useState<Map<string, number>>(new Map());
	const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
	const [emojiPickerPosition, setEmojiPickerPosition] = useState<{ visible: boolean } | null>(null);
	const [pendingReactions, setPendingReactions] = useState<{
		[messageId: string]: { [emoji: string]: boolean };
	}>({});
	const [visibleDate, setVisibleDate] = useState<number | null>(null);
	const [showScrollToBottom, setShowScrollToBottom] = useState(false);
	const [isLoadingMessages, setIsLoadingMessages] = useState(false);

	const modalMessageRef = useRef<HTMLDivElement>(null);
	const messagesContainerRef = useRef<HTMLDivElement>(null);

	const wsRef = useRef<WebSocket | null>(null);
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const scrollToBottom = (smooth = true) => {
		messagesEndRef.current?.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
	};

	const loadMoreMessages = () => {
		if (!wsRef.current) return;

		const oldestMessage = messages[0];
		wsRef.current.send(
			JSON.stringify({
				type: 'loadMessages',
				limit: 50,
				before: oldestMessage?.id,
			})
		);
	};

	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (!file) return;

		if (!wsRef.current) return;

		if (!file.type.startsWith('image/')) {
			setError('Only image files are allowed');
			if (fileInputRef.current) {
				fileInputRef.current.value = '';
			}
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			setImagePreview({
				file,
				preview: reader.result as string,
			});
			// Focus the input after setting preview
			inputRef.current?.focus();
		};
		reader.readAsDataURL(file);
	};

	const handleFileUploadToServer = async (file: File) => {
		if (!wsRef.current) return;

		const fileId = crypto.randomUUID();
		setUploadingFiles((prev) => new Set(prev).add(fileId));

		try {
			wsRef.current.send(
				JSON.stringify({
					type: 'requestUpload',
					fileType: 'image',
					mimeType: file.type,
				})
			);

			const uploadResponse = await new Promise<any>((resolve) => {
				const handler = (event: MessageEvent) => {
					const data = JSON.parse(event.data);
					if (data.type === 'uploadUrl') {
						wsRef.current?.removeEventListener('message', handler);
						resolve(data);
					}
				};
				wsRef.current?.addEventListener('message', handler);
			});

			const formData = new FormData();
			formData.append('file', file);

			const response = await fetch(uploadResponse.uploadUrl, {
				method: 'POST',
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to upload file');
			}

			const data = await response.json();

			if (!data.success || data.errors?.length > 0) {
				throw new Error('Failed to upload file');
			}

			const fileURL = data.result.variants.find((variant: string) => variant.endsWith('public'));

			wsRef.current.send(
				JSON.stringify({
					type: 'message',
					content: {
						text: inputMessage,
						attachments: [
							{
								type: 'image',
								url: fileURL,
								title: file.name,
								mimeType: file.type,
								size: file.size,
							},
						],
					},
					sender: {
						id: currentUser.id,
						name: currentUser.name,
					},
				})
			);

			setInputMessage('');
			setImagePreview(null);
			if (fileInputRef.current) {
				fileInputRef.current.value = '';
			}
			// Focus the input after upload completes
			inputRef.current?.focus();
		} catch (err) {
			setError('Failed to upload file');
		} finally {
			setUploadingFiles((prev) => {
				const newSet = new Set(prev);
				newSet.delete(fileId);
				return newSet;
			});
		}
	};

	const isChatMessage = (message: any): message is ChatMessage => {
		return (
			message &&
			typeof message.id === 'string' &&
			typeof message.timestamp === 'number' &&
			message.sender &&
			typeof message.sender.id === 'string' &&
			typeof message.sender.name === 'string' &&
			(message.content === undefined ||
				((typeof message.content.text === 'undefined' || typeof message.content.text === 'string') &&
					(message.content.attachments === undefined || Array.isArray(message.content.attachments))))
		);
	};

	const formatTimestamp = (timestamp: number) => {
		const date = new Date(timestamp);
		return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
	};

	const formatDate = (timestamp: number) => {
		const date = new Date(timestamp);
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);

		if (date.toDateString() === today.toDateString()) {
			return 'Today';
		} else if (date.toDateString() === yesterday.toDateString()) {
			return 'Yesterday';
		} else {
			return date.toLocaleDateString('en-US', {
				month: 'long',
				day: 'numeric',
				year: date.getFullYear() !== today.getFullYear() ? 'numeric' : undefined,
			});
		}
	};

	const isSameDay = (timestamp1: number, timestamp2: number) => {
		const date1 = new Date(timestamp1);
		const date2 = new Date(timestamp2);
		return date1.toDateString() === date2.toDateString();
	};

	const connectWebSocket = useCallback(() => {
		if (wsRef.current?.readyState === WebSocket.OPEN) {
			wsRef.current.close();
		}

		try {
			wsRef.current = new WebSocket(`${WS_URL}${chatRoomId}`);
			setConnectionError(false);
			setError(null);
			// Don't clear messages on reconnect since we have cache

			wsRef.current.onopen = () => {
				setIsConnected(true);
				setError(null);
				setConnectionError(false);

				wsRef.current.send(
					JSON.stringify({
						type: 'auth',
						token: localStorage.getItem('accessToken'),
                        chatroom_id: chatRoomId
					})
				);
			};

			wsRef.current.onclose = (event) => {
				console.log('WebSocket disconnected', event);
				setIsConnected(false);
				setIsAuthenticated(false);
				setConnectionError(true);

				if (event.code === 1008) {
					setError(`Authentication failed`);
					setMessages([]);
				}
			};

			wsRef.current.onerror = (error) => {
				console.error('WebSocket error:', error);
				setError('WebSocket connection error');
				setConnectionError(true);
			};

			wsRef.current.onmessage = (event) => {
				const data = JSON.parse(event.data);

				switch (data.type) {
					case 'auth':
						if (data.status === 'success') {
							setIsAuthenticated(true);
							setConnectionError(false);

							// Get last message ID from cache
							const cachedMessages = JSON.parse(localStorage.getItem(`chat_messages_${chatRoomId}`) || '[]');

							setIsLoadingMessages(true);
							wsRef.current.send(
								JSON.stringify({
									type: 'loadMessages',
									limit: 50,
									// ...(cachedMessages.length > 0 ? { after: cachedMessages[cachedMessages.length - 1].id } : { before: undefined }),
								})
							);
						}
						break;
					case 'loadMessages':
						if (Array.isArray(data.messages)) {
							const validMessages = data.messages.filter(isChatMessage);

							validMessages.forEach((message) => {
								if (message.sender.id !== currentUser.id && (!message.status?.delivered || !message.status.delivered[currentUser.id])) {
									wsRef.current.send(
										JSON.stringify({
											type: 'statusUpdate',
											messageId: message.id,
											status: 'delivered',
										})
									);
								}
							});

							if (validMessages.length > 0) {
								setMessages((prev) => {
									// Create a Map to ensure message uniqueness by ID
									const messageMap = new Map(prev.map((msg) => [msg.id, msg]));
									validMessages.forEach((msg) => messageMap.set(msg.id, msg));

									const uniqueMessages = Array.from(messageMap.values())
										// Sort by timestamp to maintain chronological order
										.sort((a, b) => a.timestamp - b.timestamp);

									// Cache the messages
									localStorage.setItem(`chat_messages_${chatRoomId}`, JSON.stringify(uniqueMessages));

									if (!showScrollToBottom) {
										setTimeout(() => scrollToBottom(true), 0);
									}
									return uniqueMessages;
								});
							}
							setIsLoadingMessages(false);
						}
						break;
					case 'error':
						setIsLoadingMessages(false);
						setError(data.message);
						break;
					case 'message':
					default:
						if (isChatMessage(data)) {
							setMessages((prev) => {
								// Use Map to ensure uniqueness
								const messageMap = new Map(prev.map((msg) => [msg.id, msg]));
								const existingMessage = messageMap.get(data.id);

								if (existingMessage) {
									messageMap.set(data.id, {
										...existingMessage,
										status: {
											...existingMessage.status,
											...data.status,
											// Handle empty reactions list by removing reactions
											reactions: data.status?.reactions === undefined ? existingMessage.status?.reactions : 
											Object.keys(data.status?.reactions || {}).length === 0 ? {} : 
											{
												...(existingMessage.status?.reactions || {}),
												...(data.status?.reactions || {}),
											},
										},
									});
								} else {
									wsRef.current.send(
										JSON.stringify({
											type: 'statusUpdate',
											messageId: data.id,
											status: 'delivered',
										})
									);
									messageMap.set(data.id, data);

									// Initialize loading state for any images in the message
									if (data.content?.attachments) {
										const imageAttachments = data.content.attachments.filter(att => att.type === 'image');
										if (imageAttachments.length > 0) {
											setLoadingImages(prev => {
												const newSet = new Set(prev);
												imageAttachments.forEach(att => {
													newSet.add(att.url);
													preloadImage(att.url);
												});
												return newSet;
											});
										}
									}

									// Only scroll to bottom for new messages
									setTimeout(() => scrollToBottom(true), 0);
								}

								const uniqueMessages = Array.from(messageMap.values()).sort((a, b) => a.timestamp - b.timestamp);

								// Cache the messages
								localStorage.setItem(`chat_messages_${chatRoomId}`, JSON.stringify(uniqueMessages));

								return uniqueMessages;
							});
							// Clear pending reactions
							setPendingReactions((prev) => {
								const newPendingReactions = { ...prev };
								delete newPendingReactions[data.id];
								return newPendingReactions;
							});
						}
						break;
				}
			};
		} catch (error) {
			console.error('WebSocket connection error:', error);
			setConnectionError(true);
			setError('Failed to establish connection');
		}
	}, [currentUser.id, chatRoomId]);

	const handleRefresh = () => {
		setConnectionError(false);
		setError(null);
		connectWebSocket();
		onRefresh?.();
	};

	const handleMessageInteraction = (e: React.MouseEvent | React.TouchEvent, messageId: string) => {
		// Don't allow reactions on own messages
		const message = messages.find((m) => m.id === messageId);
		if (message?.sender.id === currentUser.id) {
			return;
		}

		if (e.type === 'touchstart') {
			// Handle force press (for mobile)
			const touch = (e as React.TouchEvent).touches[0];
			if (touch && (touch as Touch).force > 0.8) {
				e.preventDefault();
				e.stopPropagation();
				showEmojiPicker(e as unknown as React.MouseEvent<HTMLElement>, messageId);
			}
		} else if (e.type === 'dblclick') {
			showEmojiPicker(e as React.MouseEvent<HTMLElement>, messageId);
		}
	};

	const handleReaction = useCallback(
		(messageId: string, emoji: string, action: 'add' | 'remove') => {
			console.log('handleReaction:', { messageId, emoji, action });

			if (!wsRef.current) {
				console.error('WebSocket not connected');
				return;
			}

			if (!isAuthenticated) {
				console.error('User not authenticated');
				return;
			}

			try {
				// Update pending reactions - only clear current user's reactions
				setPendingReactions((prev) => {
					const newState = { ...prev };
					if (!newState[messageId]) {
						newState[messageId] = {};
					}
					newState[messageId][emoji] = action === 'add';
					return newState;
				});

				// Update messages state locally
				setMessages((prev) =>
						prev.map((msg) => {
							if (msg.id === messageId) {
								const newReactions = { ...msg.status?.reactions } || {};
								if (action === 'add') {
									newReactions[currentUser.id] = emoji;
								} else {
									delete newReactions[currentUser.id];
								}
								return {
									...msg,
									status: {
										...msg.status,
										reactions: newReactions,
									},
								};
							}
							return msg;
						})
					);

				// Send update to server
				const message = {
					type: 'statusUpdate',
					messageId,
					status: 'react',
					reaction: emoji,
					action,
					chatRoomId,
				};

				wsRef.current.send(JSON.stringify(message));
			} catch (error) {
				console.error('Error in handleReaction:', error);
			}
		},
		[wsRef, isAuthenticated, chatRoomId]
	);

	const showEmojiPicker = (e: React.MouseEvent<HTMLElement>, messageId: string) => {
		setSelectedMessageId(messageId);
		setEmojiPickerPosition({ visible: true });
		// Scroll to bottom immediately after render
		setTimeout(() => {
			modalMessageRef.current?.scrollTo(0, modalMessageRef.current.scrollHeight);
		}, 0);
	};

	const handleEmojiSelect = (emoji: string) => {
		if (selectedMessageId) {
			handleReaction(selectedMessageId, emoji, 'add');
			setSelectedMessageId(null);
			setEmojiPickerPosition(null);
		}
	};

	const handleClickOutside = useCallback(
		(e: MouseEvent) => {
			if (selectedMessageId && !(e.target as HTMLElement).closest('.emoji-picker')) {
				setSelectedMessageId(null);
				setEmojiPickerPosition(null);
			}
		},
		[selectedMessageId]
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	useEffect(() => {
		connectWebSocket();
		return () => {
			if (wsRef.current) {
				wsRef.current.close();
			}
		};
	}, [connectWebSocket]);

	const getUserDisplayInfo = useCallback((userId: string) => {
		if (userId === currentUser.id) {
			return {
				name: currentUser.name,
				avatar: null // Add avatar URL from currentUser if available
			};
		}
		const profile = otherProfiles[userId];
		return {
			name: profile?.username || 'Unknown User',
			avatar: profile?.profile_picture_url || null
		};
	}, [currentUser, otherProfiles]);

	const renderMessageContent = (message: ChatMessage) => {
		const isCurrentUser = message.sender.id === currentUser.id;
		const { name, avatar } = getUserDisplayInfo(message.sender.id);
		
		return (
			<div
				className={`flex ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'} items-center gap-2 relative message-container my-6`}
				onDoubleClick={(e) => handleMessageInteraction(e, message.id)}
				onTouchStart={(e) => handleMessageInteraction(e, message.id)}
				data-can-react={message.sender.id !== currentUser.id}
			>
				{/* User Info */}
				{!isCurrentUser && (
					<div className="flex-shrink-0">
						<div className="w-10 h-10">
							{avatar ? (
								<img
									src={avatar}
									alt={name}
									className="w-full h-full rounded-full object-cover"
								/>
							) : (
								<div className="w-full h-full rounded-full bg-gray-300 flex items-center justify-center">
									<span className="text-sm font-medium text-gray-600">
										{name.charAt(0).toUpperCase()}
									</span>
								</div>
							)}
						</div>
					</div>
				)}

				{/* Message and Timestamp Container */}
				<div className={`flex flex-col ${isCurrentUser ? 'items-end' : 'items-start'} max-w-[70%]`}>
					{/* Emoji Picker Modal */}
					{selectedMessageId === message.id && emojiPickerPosition?.visible && message.sender.id !== currentUser.id && (
						<div
							className="fixed inset-0 z-50 flex items-center justify-center bg-black/30 backdrop-blur-sm"
							onMouseDown={(e) => {
								if (e.target === e.currentTarget) {
									setSelectedMessageId(null);
									setEmojiPickerPosition(null);
								}
							}}
						>
							<div
								className="bg-white/95 backdrop-blur-xl rounded-2xl shadow-2xl w-[360px] flex flex-col"
								onMouseDown={(e) => e.stopPropagation()}
							>
								{/* Message Preview */}
								<div ref={modalMessageRef} className="p-6 flex-1 overflow-y-auto max-h-[200px]">
									<div
										className={`relative break-words rounded-lg px-4 py-2 message-content cursor-pointer select-none font-normal ${
											message.sender.id === currentUser.id ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900'
										} font-['-apple-system',BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif]`}
									>
										<div className="whitespace-pre-wrap">{message.content?.text}</div>
										{message.content?.attachments?.map((attachment, index) => (
											<div key={index} className="mt-2">
												{/* Render attachment preview */}
												{attachment.type === 'image' ? (
													<div className="relative">
														<div 
															className={`w-full bg-gray-100 rounded-lg flex items-center justify-center ${
																!loadingImages.has(attachment.url) ? 'hidden' : ''
															}`}
															style={{
																paddingBottom: `${imageAspectRatios.has(attachment.url) 
																	? `${(1 / imageAspectRatios.get(attachment.url)!) * 100}%` 
																	: '75%'}`
															}}
														>
															<div className="absolute inset-0 flex items-center justify-center">
																<div className="w-8 h-8 text-blue-500">
																	<LoadingSpinner />
																</div>
															</div>
														</div>
														<img
															src={attachment.url}
															alt={attachment.title || 'Attachment'}
															className={`w-full rounded-lg ${loadingImages.has(attachment.url) ? 'hidden' : ''}`}
															onLoad={(e) => {
																const img = e.target as HTMLImageElement;
																setImageAspectRatios(prev => {
																	const newMap = new Map(prev);
																	newMap.set(attachment.url, img.naturalWidth / img.naturalHeight);
																	return newMap;
																});
																setLoadingImages(prev => {
																	const newSet = new Set(prev);
																	newSet.delete(attachment.url);
																	return newSet;
																});
															}}
															onError={() => {
																setLoadingImages(prev => {
																	const newSet = new Set(prev);
																	newSet.delete(attachment.url);
																	return newSet;
																});
															}}
														/>
													</div>
												) : attachment.type === 'document' ? (
													<div className="flex items-center space-x-2 p-2 bg-gray-50 rounded">
														<div className="flex-1 truncate">
															<div className="text-sm font-medium">{attachment.title}</div>
															<div className="text-xs text-gray-500">{(attachment.size / 1024 / 1024).toFixed(1)} MB</div>
														</div>
														<a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600">
															Download
														</a>
													</div>
												) : null}
											</div>
										))}
									</div>
								</div>

								{/* Fixed Emoji Grid */}
								<div className="border-t border-gray-100">
									<div className="grid grid-cols-6 gap-3 p-6">
										{['👍', '❤️', '😂', '😮', '😢', '😡'].map((emoji) => {
											const hasReacted = message.status?.reactions?.[currentUser.id] === emoji;
											const isPending = pendingReactions[message.id]?.[emoji];
											// If we have a pending state, use that, otherwise use hasReacted
											const isActive = isPending !== undefined ? isPending : hasReacted;

											return (
												<button
													key={emoji}
													type="button"
													onMouseDown={(e) => {
														e.preventDefault();
														e.stopPropagation();
														if (isActive) {
															handleReaction(message.id, emoji, 'remove');
														} else {
															handleReaction(message.id, emoji, 'add');
														}
														setEmojiPickerPosition(null);
													}}
													className={`w-11 h-11 flex items-center justify-center text-2xl rounded-xl
														transition-all duration-200 hover:scale-110 transform
														${
															isActive
																? 'bg-gradient-to-br from-blue-500/90 to-blue-600/90 text-white shadow-lg'
																: 'hover:bg-gradient-to-br hover:from-gray-50 hover:to-gray-100/80'
														}`}
												>
													<span>{emoji}</span>
												</button>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					)}

					<div
						className={`relative break-words rounded-lg px-4 py-2 message-content cursor-pointer select-none font-normal ${
							isCurrentUser ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900'
						}`}
					>
						{/* Message content */}
						{message.content?.text && <div className="whitespace-pre-wrap select-text">{message.content.text}</div>}
						{/* Attachments */}
						{message.content?.attachments?.map((attachment, index) => (
							<div key={index} className="mt-2">
								{attachment.type === 'image' ? (
									<div className="relative">
										<div 
											className={`w-full bg-gray-100 rounded-lg flex items-center justify-center ${
												!loadingImages.has(attachment.url) ? 'hidden' : ''
											}`}
											style={{
												paddingBottom: `${imageAspectRatios.has(attachment.url) 
													? `${(1 / imageAspectRatios.get(attachment.url)!) * 100}%` 
													: '75%'}`
											}}
										>
											<div className="absolute inset-0 flex items-center justify-center">
												<div className="w-8 h-8 text-blue-500">
													<LoadingSpinner />
												</div>
											</div>
										</div>
										<img
											src={attachment.url}
											alt={attachment.title || 'Attachment'}
											className={`w-full rounded-lg ${loadingImages.has(attachment.url) ? 'hidden' : ''}`}
											onLoad={(e) => {
												const img = e.target as HTMLImageElement;
												setImageAspectRatios(prev => {
													const newMap = new Map(prev);
													newMap.set(attachment.url, img.naturalWidth / img.naturalHeight);
													return newMap;
												});
												setLoadingImages(prev => {
													const newSet = new Set(prev);
													newSet.delete(attachment.url);
													return newSet;
												});
											}}
											onError={() => {
												setLoadingImages(prev => {
													const newSet = new Set(prev);
													newSet.delete(attachment.url);
													return newSet;
												});
											}}
										/>
									</div>
								) : attachment.type === 'document' ? (
									<div className="flex items-center space-x-2 p-2 bg-gray-50 rounded">
										<div className="flex-1 truncate">
											<div className="text-sm font-medium">{attachment.title}</div>
											<div className="text-xs text-gray-500">{(attachment.size / 1024 / 1024).toFixed(1)} MB</div>
										</div>
										<a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600">
											Download
										</a>
									</div>
								) : null}
							</div>
						))}
						{message.status?.reactions &&
							(() => {
								// Get all active reactions (excluding pending removals)
								const activeReactions = Object.entries(message.status.reactions).filter(([userId, emoji]) => {
									if (!emoji) return false;
									if (userId === currentUser.id && pendingReactions[message.id]?.[emoji] === false) return false;
									return true;
								});

								// If no active reactions, don't render anything
								if (activeReactions.length === 0) return null;

								// Render the first active reaction
								const [userId, emoji] = activeReactions[0];
								return (
									<div
										key={`${message.id}-${userId}`}
										className={`inline-flex items-center justify-center
											${
												message.sender.id === currentUser.id
													? 'bg-gradient-to-br from-gray-800/0 to-gray-900/0 text-white'
													: 'bg-gradient-to-br from-gray-500/0 to-gray-600/0 text-white'
											} 
											transition-all duration-200 absolute shadow-lg hover:shadow-xl
											${
												message.sender.id === currentUser.id
													? 'top-0 left-0 -translate-x-1/2 -translate-y-1/2'
													: 'top-0 right-0 translate-x-1/2 -translate-y-1/2'
											}
											w-8 h-8 rounded-full backdrop-blur-sm border border-white/10
											hover:scale-110 transform cursor-pointer`}
										style={{
											zIndex: 2,
											fontSize: '1.6rem',
											backdropFilter: 'blur(8px)',
											WebkitBackdropFilter: 'blur(8px)',
										}}
										onMouseDown={(e) => {
											e.stopPropagation();
											e.preventDefault();
											if (userId === currentUser.id) {
												handleReaction(message.id, emoji, 'remove');
											}
										}}
									>
										<span className="transform hover:scale-110 transition-transform duration-200">{emoji}</span>
									</div>
								);
							})()}
					</div>

					{/* Timestamp and Status */}
					<div className="text-xs text-gray-500 mt-1 px-1 flex items-center space-x-1">
						<span>{formatTimestamp(message.timestamp)}</span>
						{isCurrentUser && message.status && (
							<span className="ml-1 relative">
								{Object.entries(message.status.read || {}).some(([userId]) => userId !== currentUser.id) ? (
									<span className="relative">
										<span className="absolute text-blue-400" style={{ left: '-0.3em' }}>
											✓
										</span>
										<span className="text-blue-400">✓</span>
									</span>
								) : Object.entries(message.status.delivered || {}).some(([userId]) => userId !== currentUser.id) ? (
									<span className="relative">
										<span className="absolute text-gray-400" style={{ left: '-0.3em' }}>
											✓
										</span>
										<span className="text-gray-400">✓</span>
									</span>
								) : (
									<span className="text-gray-400">✓</span>
								)}
							</span>
						)}
					</div>
				</div>
			</div>
		);
	};

	const updateVisibleDate = useCallback(() => {
		const container = messagesContainerRef.current;
		if (!container || messages.length === 0) return;

		// Get all date separator elements
		const dateSeparators = container.querySelectorAll('[data-date-separator]');
		if (dateSeparators.length === 0) {
			setVisibleDate(messages[0].timestamp);
			return;
		}

		// Find the last date separator that's above the viewport
		let lastVisibleDate = messages[0].timestamp;
		dateSeparators.forEach((separator) => {
			const rect = separator.getBoundingClientRect();
			const containerRect = container.getBoundingClientRect();
			if (rect.top <= containerRect.top + 60) {
				// Add some offset for better UX
				lastVisibleDate = parseInt(separator.getAttribute('data-timestamp') || '0', 10);
			}
		});

		setVisibleDate(lastVisibleDate);
	}, [messages]);

	const updateScrollPosition = useCallback(() => {
		const container = messagesContainerRef.current;
		if (!container) return;

		// Update visible date
		updateVisibleDate();

		// Check if we're not at the bottom
		const isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 50;
		setShowScrollToBottom(!isAtBottom);
	}, [updateVisibleDate]);

	// Add scroll event listener
	useEffect(() => {
		const container = messagesContainerRef.current;
		if (!container) return;

		container.addEventListener('scroll', updateScrollPosition);
		// Initial update
		updateScrollPosition();

		return () => {
			container.removeEventListener('scroll', updateScrollPosition);
		};
	}, [updateScrollPosition]);

	// Scroll to bottom on initial render if we have cached messages
	useEffect(() => {
		if (messages.length > 0) {
			scrollToBottom(false);
		}
	}, []); // Empty dependency array for initial render only

	const sendMessage = (text: string = '') => {
		if (!wsRef.current || !isAuthenticated) return;

		if (imagePreview) {
			handleFileUploadToServer(imagePreview.file);
		} else if (text) {
			wsRef.current.send(
				JSON.stringify({
					type: 'message',
					content: { text },
					sender: {
						id: currentUser.id,
						name: currentUser.name,
					},
				})
			);
			setInputMessage('');
		}
	};

	const preloadImage = (url: string) => {
		const img = new Image();
		img.src = url;
		img.onload = () => {
			setImageAspectRatios(prev => {
				const newMap = new Map(prev);
				newMap.set(url, img.naturalWidth / img.naturalHeight);
				return newMap;
			});
			setLoadingImages(prev => {
				const newSet = new Set(prev);
				newSet.delete(url);
				return newSet;
			});
		};
		img.onerror = () => {
			setLoadingImages(prev => {
				const newSet = new Set(prev);
				newSet.delete(url);
				return newSet;
			});
		};
	};

	return isMobile ? (
		<MobileChatInterface
			messages={messages}
			inputMessage={inputMessage}
			setInputMessage={setInputMessage}
			sendMessage={sendMessage}
			imagePreview={imagePreview}
			setImagePreview={setImagePreview}
			handleFileUpload={handleFileUpload}
			handleFileUploadToServer={handleFileUploadToServer}
			fileInputRef={fileInputRef}
			inputRef={inputRef}
			messagesEndRef={messagesEndRef}
			messagesContainerRef={messagesContainerRef}
			showScrollToBottom={showScrollToBottom}
			setShowScrollToBottom={setShowScrollToBottom}
			scrollToBottom={scrollToBottom}
			selectedMessageId={selectedMessageId}
			setSelectedMessageId={setSelectedMessageId}
			emojiPickerPosition={emojiPickerPosition}
			setEmojiPickerPosition={setEmojiPickerPosition}
			handleEmojiSelect={handleEmojiSelect}
			currentUser={currentUser}
			loadingImages={loadingImages}
			imageAspectRatios={imageAspectRatios}
			uploadingFiles={uploadingFiles}
			isAuthenticated={isAuthenticated}
			otherProfiles={otherProfiles}
			handleReaction={handleReaction}
			pendingReactions={pendingReactions}
			connectionError={connectionError}
			error={error}
			handleRefresh={handleRefresh}
		/>
	) : (
		<div className="flex flex-col h-full bg-white font-['-apple-system', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif] font-normal">
			{/* Header */}
			<div className="p-4 border-b flex justify-between items-center bg-white">
				<div>
					{chatroomType === 'direct_message' ? (
						(() => {
							const otherUserId = participants.find((id) => id !== currentUser.id);
							const otherUser = otherProfiles[otherUserId || ''];
							return otherUser ? (
								<Link to={`/u/${otherUser.username}`} className="text-lg font-semibold text-blue-600 hover:underline">
									{otherUser.username || 'Unknown User'}
								</Link>
							) : (
								<span className="text-lg font-semibold">Unknown User</span>
							);
						})()
					) : (
						<h2 className="text-lg font-semibold">{chatRoomName}</h2>
					)}
				</div>
				{connectionError && (
					<div className="flex items-center justify-between bg-red-50 p-2 rounded-lg z-50">
						<div className="flex items-center space-x-2">
							<span className="text-red-500 text-sm">{error || 'Connection lost'}</span>
							<button
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									handleRefresh();
								}}
								className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
								title="Refresh connection"
							>
								<FaSync className="w-4 h-4" />
							</button>
						</div>
					</div>
				)}
			</div>

			{/* Messages Container */}
			<div className="flex-1 min-h-0 relative">
				<div ref={messagesContainerRef} className="absolute inset-0 overflow-y-auto p-4 space-y-4">
					{/* Sticky date header */}
					{messages.length > 0 && visibleDate && (
						<div className="sticky top-0 z-10 bg-transparent py-2 text-center">
							<span className="text-sm font-medium text-gray-500 px-4 py-1 bg-white/80 backdrop-blur-sm rounded-full shadow-sm">
								{formatDate(visibleDate)}
							</span>
						</div>
					)}

					{messages.length > 0 ? (
						messages
							.filter((message) => message !== null && isChatMessage(message))
							.sort((a, b) => a.timestamp - b.timestamp)
							.map((message, index, array) => (
								<React.Fragment key={message.id}>
									{/* Date separator */}
									{index > 0 && !isSameDay(message.timestamp, array[index - 1].timestamp) && (
										<div className="flex items-center my-6" data-date-separator data-timestamp={message.timestamp}>
											<div className="flex-grow border-t border-gray-200"></div>
											<div className="mx-4 text-sm text-gray-500 font-medium">{formatDate(message.timestamp)}</div>
											<div className="flex-grow border-t border-gray-200"></div>
										</div>
									)}

									{/* Message */}
									{renderMessageContent(message)}
								</React.Fragment>
							))
					) : (
						<div className="text-center text-gray-500 mt-4">
							No messages yet
						</div>
					)}

					{showScrollToBottom && (
						<button
							onClick={() => scrollToBottom(true)}
							className="fixed bottom-20 right-4 bg-black shadow-lg rounded-full p-2 z-20"
						>
							<FaChevronDown className="text-white hover:text-gray-200" />
						</button>
					)}

					<div ref={messagesEndRef} />
				</div>
			</div>

			{/* Input */}
			<div className="p-4 border-t bg-white">
				{imagePreview && (
					<div className="mb-4 relative">
						<div className="relative inline-block">
							<img src={imagePreview.preview} alt="Preview" className="max-h-[150px] rounded-lg object-contain" />
							<button
								onMouseDown={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setImagePreview(null);
									if (fileInputRef.current) {
										fileInputRef.current.value = '';
									}
								}}
								className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
							>
								<svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
					</div>
				)}
				<div className="flex items-center space-x-2">
					<input type="file" ref={fileInputRef} onChange={handleFileUpload} className="hidden" accept="image/*" />
					<button
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							fileInputRef.current?.click();
						}}
						className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
						disabled={!isAuthenticated}
					>
						{uploadingFiles.size > 0 ? <FaSpinner className="w-5 h-5 animate-spin" /> : <FaPlus className="w-5 h-5" />}
					</button>
					<input
						ref={inputRef}
						type="text"
						value={inputMessage}
						onChange={(e) => setInputMessage(e.target.value)}
						onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage(inputMessage)}
						placeholder="Type a message..."
						className="flex-1 p-2 border rounded-lg focus:outline-none focus:border-blue-500 font-normal font-['-apple-system',BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif]"
						disabled={!isAuthenticated}
					/>
					<button
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							sendMessage(inputMessage);
						}}
						className="p-2 text-blue-500 hover:text-blue-700 focus:outline-none disabled:opacity-50"
						disabled={!isAuthenticated || (!inputMessage && !imagePreview)}
					>
						<FaPaperPlane className="w-5 h-5" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ChatInterface;
