import { useEffect, useState, useCallback, useMemo } from 'react';
import { User } from '../models/user';

const CACHE_KEY = 'userFriendsCache';

interface CachedFriends {
  [userId: string]: {
    friends: User[];
    timestamp: number;
  };
}

export function useUserFriends(userId?: string) {
  const loadFromCacheSync = (targetUserId: string): User[] => {
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const cache: CachedFriends = JSON.parse(cachedData);
        const userData = cache[targetUserId];
        if (userData) {
          return userData.friends;
        }
      }
    } catch (error) {
      console.error('Failed to load friends from cache:', error);
    }
    return [];
  };

  // Initialize with cached data
  const initialCachedFriends = userId ? loadFromCacheSync(userId) : [];
  const [friends, setFriends] = useState<User[]>(initialCachedFriends);
  const [loading, setLoading] = useState<boolean>(initialCachedFriends.length === 0);
  const [error, setError] = useState<string | null>(null);

  const loadFromCache = useCallback((targetUserId: string): User[] | null => {
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const cache: CachedFriends = JSON.parse(cachedData);
        const userData = cache[targetUserId];
        if (userData) {
          return userData.friends;
        }
      }
    } catch (error) {
      console.error('Failed to load friends from cache:', error);
    }
    return null;
  }, []);

  const saveToCache = useCallback((targetUserId: string, friendsData: User[]) => {
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const cache: CachedFriends = cachedData ? JSON.parse(cachedData) : {};
      cache[targetUserId] = {
        friends: friendsData,
        timestamp: Date.now(),
      };
      localStorage.setItem(CACHE_KEY, JSON.stringify(cache));
    } catch (error) {
      console.error('Failed to save friends to cache:', error);
    }
  }, []);

  const fetchFriendsFromServer = useCallback(async () => {
    try {
      const friendsList = await User.getFriends();
      const friendsArray = await Promise.all(
        friendsList.map((friendId: string) => User.getUserById(friendId))
      );
      return friendsArray;
    } catch (err) {
      console.error('Failed to fetch friends from server:', err);
      throw err;
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    let timeoutId: NodeJS.Timeout;

    const updateFriendsInBackground = async (shouldSetLoading = true) => {
      if (!userId) return;

      // Only set loading if explicitly requested and we don't have data
      if (shouldSetLoading && friends.length === 0) {
        setLoading(true);
      }

      try {
        const friendsArray = await fetchFriendsFromServer();
        if (isSubscribed) {
          setFriends(prev => {
            // Only update if the data has changed
            const prevJson = JSON.stringify(prev.map(p => p.user_id).sort());
            const newJson = JSON.stringify(friendsArray.map(p => p.user_id).sort());
            
            if (prevJson !== newJson) {
              saveToCache(userId, friendsArray);
              return friendsArray;
            }
            return prev;
          });
        }
      } catch (err) {
        console.error('Background refresh failed:', err);
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };

    // Initial update without showing loading state if we have cached data
    if (userId) {
      updateFriendsInBackground(friends.length === 0);
    }

    const intervalId = setInterval(() => updateFriendsInBackground(false), 60000);

    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
    };
  }, [userId, fetchFriendsFromServer, saveToCache, friends.length]);

  const fetchFriends = useCallback(async () => {
    setLoading(true);
    try {
      const friendsArray = await fetchFriendsFromServer();
      setFriends(friendsArray);
      saveToCache(userId!, friendsArray);
    } catch (err) {
      console.error('Failed to refresh friends:', err);
      setError('Failed to refresh friends');
    } finally {
      setLoading(false);
    }
  }, [userId, fetchFriendsFromServer, saveToCache]);

  return useMemo(() => ({
    friends,
    loading,
    error,
    refresh: fetchFriends
  }), [friends, loading, error, fetchFriends]);
}
