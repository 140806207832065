/**
 * useUserGames Hook
 *
 * This hook manages the user's games data, providing efficient caching and
 * optimistic updates for a responsive user experience.
 *
 * Functionality:
 * 1. Immediately returns cached games data if available and not expired.
 * 2. Asynchronously fetches and updates games data when needed.
 * 3. Implements local storage caching with a 24-hour expiration.
 * 4. Provides optimistic updates for adding new games.
 * 5. Manages loading and error states.
 *
 * Usage:
 * const { userGames, isGamesLoading, error, addGame } = useUserGames();
 *
 * - userGames: Array of Game objects or null if not loaded
 * - isGamesLoading: Boolean indicating if games are being fetched
 * - error: Error object if an error occurred during fetching
 * - addGame: Function to optimistically add a new game
 *
 * The hook will cause a re-render when:
 * - Initial cached data is loaded
 * - New games data is fetched
 * - A game is added (optimistically and after confirmation)
 * - Loading state changes
 * - An error occurs
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, new data is fetched from the server
 * - Cache expiration is set to 24 hours
 *
 * Note: This hook depends on the useUserProfile hook for the current user's ID.
 */

import { useState, useEffect, useMemo } from 'react';
import { useUserProfile } from './useUserProfile';
import { Game } from '../models/games';

const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Function to sort games by their scheduled date (ascending order)
const sortGamesByDate = (games: Game[]): Game[] => {
	return games.sort((a, b) => new Date(a.scheduled_at).getTime() - new Date(b.scheduled_at).getTime());
};

export const useUserGames = () => {
	const { userProfile } = useUserProfile();
	const [userGames, setUserGames] = useState<Game[] | null>(null);
	const [nearbyGames, setNearbyGames] = useState<Game[] | null>(null);
	const [isGamesLoading, setIsGamesLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [refreshKey, setRefreshKey] = useState(0);

	const refreshGames = () => {
		setRefreshKey((prev) => prev + 1);
	};

	// Function to deduplicate games
	const dedupeGames = (games: Game[]): Game[] => {
		const uniqueGames = new Map<string, Game>();
		games.forEach(game => {
			uniqueGames.set(game.game_id, game);
		});
		return Array.from(uniqueGames.values());
	};

	// Function to merge and sort games
	const mergeAndSortGames = (userGames: Game[] | null, nearbyGames: Game[] | null): Game[] => {
		const allGames = [...(userGames || []), ...(nearbyGames || [])];
		const uniqueGames = dedupeGames(allGames);
		return sortGamesByDate(uniqueGames);
	};

	useEffect(() => {
		if (!userProfile) {
			setIsGamesLoading(false);
			return;
		}

		const USER_GAMES_CACHE_KEY = `userGamesCache_${userProfile.user_id}`;
		const NEARBY_GAMES_CACHE_KEY = `nearbyGamesCache_${userProfile.user_id}`;

		// Load cached data
		const loadCachedData = () => {
			try {
				// Load user games from cache
				const cachedUserGames = localStorage.getItem(USER_GAMES_CACHE_KEY);
				if (cachedUserGames) {
					const { data, timestamp } = JSON.parse(cachedUserGames);
					if (Date.now() - timestamp < CACHE_EXPIRY) {
						const validData = data.filter(isValidGame);
						setUserGames(validData);
					} else {
						localStorage.removeItem(USER_GAMES_CACHE_KEY);
					}
				}

				// Load nearby games from cache
				const cachedNearbyGames = localStorage.getItem(NEARBY_GAMES_CACHE_KEY);
				if (cachedNearbyGames) {
					const { data, timestamp } = JSON.parse(cachedNearbyGames);
					if (Date.now() - timestamp < CACHE_EXPIRY) {
						const validData = data.filter(isValidGame);
						setNearbyGames(validData);
					} else {
						localStorage.removeItem(NEARBY_GAMES_CACHE_KEY);
					}
				}
			} catch (error) {
				console.error('Error loading cached games:', error);
			}
		};

		// Fetch fresh data
		const fetchFreshData = async () => {
			setIsGamesLoading(true);
			try {
				// Fetch user games
				const userGamesPromise = Game.getByUserId(userProfile.user_id);
				// Fetch nearby games
				const nearbyGamesPromise = Game.getNearby(userProfile.user_id);

				const [userGamesResult, nearbyGamesResult] = await Promise.all([
					userGamesPromise,
					nearbyGamesPromise
				]);

				// Update user games
				const validUserGames = userGamesResult.filter(isValidGame);
				setUserGames(validUserGames);
				localStorage.setItem(USER_GAMES_CACHE_KEY, JSON.stringify({
					data: validUserGames,
					timestamp: Date.now()
				}));

				// Update nearby games
				const validNearbyGames = nearbyGamesResult.filter(isValidGame);
				setNearbyGames(validNearbyGames);
				localStorage.setItem(NEARBY_GAMES_CACHE_KEY, JSON.stringify({
					data: validNearbyGames,
					timestamp: Date.now()
				}));

				setError(null);
			} catch (err) {
				console.error('Error fetching games:', err);
				setError(err as Error);
			} finally {
				setIsGamesLoading(false);
			}
		};

		// Load cached data immediately
		loadCachedData();
		// Fetch fresh data
		fetchFreshData();

	}, [userProfile, refreshKey]);

	// Combine and dedupe all games
	const allGames = useMemo(() => mergeAndSortGames(userGames, nearbyGames), [userGames, nearbyGames]);

	return {
		userGames: allGames,
		isGamesLoading,
		error,
		refreshGames
	};
};

function isValidGame(game: Game): boolean {
	// Define your validation logic here
	// Example validation: Check for required fields and valid data types
	return (
		typeof game.game_id === 'string' &&
		typeof game.name === 'string' &&
		!isNaN(new Date(game.scheduled_at).getTime()) &&
		typeof game.location === 'string'
	);
}
