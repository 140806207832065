import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className="py-0 px-2 sm:px-3 lg:px-4">
    <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
      <Link to="/" className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-0 hover:text-yellow-400 transition">Kickoff Championship</Link>
      <nav>
        <ul className="flex space-x-4">
          <li><Link to="/about" className="hover:text-yellow-400 transition">About</Link></li>
          <li><Link to="/rules" className="hover:text-yellow-400 transition">Rules</Link></li>
          {/* <li><Link to="/signin" className="hover:text-yellow-400 transition">Sign In</Link></li> */}
        </ul>
      </nav>
      </div>
    </header>
  );
};

export default Header;