import React, { useState } from 'react';
import FormField from './FormField';
import { FaFutbol, FaFileAlt, FaGlobe, FaLock, FaChevronUp, FaChevronDown, FaTimesCircle } from 'react-icons/fa';
import { Game } from '../models/games';
import LoadingButton from './LoadingButton';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import LocationPicker from './LocationPicker';
import TimePicker from './TimePicker';

interface GameFormProps {
	initialGameData?: Game; // Optional for create, used for edit
	onSubmit: (gameData: Partial<Game>) => Promise<void>;
	onCancel: () => void;
}

const GameForm: React.FC<GameFormProps> = ({ initialGameData, onSubmit, onCancel }) => {
	const [name, setName] = useState(initialGameData?.name || '');
	const [description, setDescription] = useState(initialGameData?.description || '');
	const [scheduledAt, setScheduledAt] = useState<Date | null>(initialGameData ? new Date(initialGameData.scheduled_at) : new Date());
	const [location, setLocation] = useState(initialGameData?.location || '');
	const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(
		initialGameData ? { lat: initialGameData.latitude || 0, lng: initialGameData.longitude || 0 } : null
	);
	// const [maxMembers, setMaxMembers] = useState<number>(initialGameData?.max_members || 22);
	const [reserveCount, setReserveCount] = useState<number>(initialGameData?.reserve_count || 4);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [selectedTime, setSelectedTime] = useState<Date | null>(initialGameData ? new Date(initialGameData.scheduled_at) : new Date());
	const [showMoreOptions, setShowMoreOptions] = useState(false);
	const [playersTeamLeft, setPlayersTeamLeft] = useState<number>(
		initialGameData ? Math.floor((initialGameData.max_members - (initialGameData.reserve_count || 0)) / 2) || 11 : 11
	);
	const [playersTeamRight, setPlayersTeamRight] = useState<number>(
		initialGameData ? Math.floor((initialGameData.max_members - (initialGameData.reserve_count || 0)) / 2) || 11 : 11
	);
	const [gameVisibility, setGameVisibility] = useState<'public' | 'unlisted'>(
		initialGameData
			? initialGameData.visibility === 'public' || initialGameData.visibility === 'unlisted'
				? initialGameData.visibility
				: 'public'
			: 'public'
	);
	const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

	const handleFormSubmit = async () => {
		setIsSubmitting(true);
		try {
			let combinedDate = scheduledAt;
			if (scheduledAt && selectedTime) {
				combinedDate = new Date(
					scheduledAt.getFullYear(),
					scheduledAt.getMonth(),
					scheduledAt.getDate(),
					selectedTime.getHours(),
					selectedTime.getMinutes(),
					0,
					0
				);
			}

			const gameData: Partial<Game> = {
				name,
				description,
				scheduled_at: combinedDate,
				location,
				latitude: coordinates ? coordinates.lat : null,
				longitude: coordinates ? coordinates.lng : null,
				max_members: playersTeamLeft + playersTeamRight + reserveCount,
				reserve_count: reserveCount,
				visibility: gameVisibility,
			};

			await onSubmit(gameData);
		} catch (error) {
			console.error('Failed to submit game:', error);
			setSubmitError('Failed to submit game. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		// <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl mx-auto">
		// 	<div className="space-y-6">
		<div>
			{/* Game Name */}
			<FormField label="Title" icon={<FaFutbol className="text-gray-400" />}>
				<input
					type="text"
					className="w-full focus:outline-none rounded-md min-h-[24px]"
					value={name}
					onChange={(e) => setName(e.target.value)}
					placeholder="Game Title"
				/>
			</FormField>

			{/* Game Description */}
			<FormField label="Description" icon={<FaFileAlt className="text-gray-300" />}>
				<textarea
					className="w-full p-3 focus:outline-none rounded-md min-h-[80px] border border-gray-300"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					placeholder="Describe your game..."
				/>
			</FormField>

			{/* Date and Time */}
			<div className="flex space-x-4">
				<FormField label="Date">
					<DayPicker
						mode="single"
						selected={scheduledAt}
						onSelect={(date: Date) => setScheduledAt(date)}
						className="w-full p-3 focus:outline-none rounded-md min-h-[42px]"
					/>
				</FormField>
			</div>
			<FormField label="Kickoff Time">
				<TimePicker selectedTime={selectedTime} onChange={setSelectedTime} />
			</FormField>

			{/* Location */}
			<LocationPicker location={location} setLocation={setLocation} setCoordinates={setCoordinates} />

			{/* More Options */}
			<div>
				<button
					onClick={() => setShowMoreOptions(!showMoreOptions)}
					className="text-indigo-600 font-medium hover:underline flex items-center"
					style={{ minWidth: '120px' }}
				>
					<div className="flex items-center">
						{showMoreOptions ? <FaChevronUp /> : <FaChevronDown />}
						<span className="ml-1">{showMoreOptions ? 'Less' : 'More'}</span>
					</div>
				</button>
			</div>

			{showMoreOptions && (
				<>
					{/* Team Size and Reserve Count */}
					<div className="mt-4">
						<label className="block text-sm font-medium text-gray-500 mb-1">Players per Team</label>
						<div className="flex items-center space-x-4">
							<input
								type="number"
								value={playersTeamLeft}
								onFocus={(e) => e.target.select()}
								onChange={(e) => {
									const value = Math.max(1, parseInt(e.target.value) || 0);
									setPlayersTeamRight(value);
								}}
								className="bg-white border border-gray-100 rounded px-3 py-1 w-20"
								
							/>
							<span>vs</span>
							<input
								type="number"
								value={playersTeamRight}
								className="bg-gray-50 border border-gray-100 rounded px-3 py-1 w-20"
								disabled
							/>
						</div>

						{/* Reserve Count */}
						<div className="mt-6">
							<label className="block text-sm font-medium text-gray-500 mb-1">Reserve Players</label>
							<div className="flex items-center space-x-4">
								<input
									type="number"
									min="0"
									max="20"
									className="bg-white border border-gray-100 rounded px-3 py-1 w-20"
									value={reserveCount}
									onChange={(e) => setReserveCount(Math.max(0, parseInt(e.target.value) || 0))}
									placeholder="0"
								/>
							</div>
							{/* <p className="text-sm text-gray-500 mt-1">Total game size: {maxMembers} players</p> */}
						</div>

						<div className="mt-6">
							<p className="text-sm text-gray-500 mt-1">Total players: {playersTeamLeft + playersTeamRight + reserveCount}</p>
						</div>

						{/* Game Visibility */}
						<div className="mt-6">
							<div className="flex space-x-4">
								<label className="flex items-center">
									<input
										type="radio"
										name="visibility"
										value="public"
										checked={gameVisibility === 'public'}
										onChange={() => setGameVisibility('public')}
										className="form-radio text-indigo-600"
									/>
									<FaGlobe className="ml-2 text-gray-400" />
									<span className="ml-1">Public</span>
								</label>
								<label className="flex items-center">
									<input
										type="radio"
										name="visibility"
										value="unlisted"
										checked={gameVisibility === 'unlisted'}
										onChange={() => setGameVisibility('unlisted')}
										className="form-radio text-indigo-600"
									/>
									<FaLock className="ml-2 text-gray-400" />
									<span className="ml-1">Unlisted</span>
								</label>
							</div>
						</div>
					</div>
				</>
			)}

			{/* Error Message */}
			{submitError && <p className="text-red-300 mt-2">{submitError}</p>}

			{/* Submit and Cancel Buttons */}
			<div className="flex flex-col space-y-4 mt-6">
				<LoadingButton
					isLoading={isSubmitting}
					onClick={handleFormSubmit}
					aria-label="Submitting..."
					disabled={isSubmitting}
					buttonClass={`w-full ${
						isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
					} text-white py-3 rounded-full transition duration-300`}
				>
					{initialGameData ? 'Submit' : 'Create Game'}
				</LoadingButton>

				{initialGameData && (
					<>
						<button
							onClick={() => setShowCancelConfirm(true)}
							className="w-full bg-gray-100 text-rose-500 px-6 py-3 rounded-lg hover:bg-gray-200 transition duration-300 flex items-center justify-center gap-2"
						>
							<FaTimesCircle className="text-lg" />
							Cancel Game
						</button>

						{showCancelConfirm && (
							<>
								<div className="fixed inset-0 bg-black/30 z-40" onClick={() => setShowCancelConfirm(false)} />
								<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg border border-gray-200 p-6 z-50 w-96">
									<h3 className="text-xl font-semibold text-gray-800 mb-4">Cancel Game?</h3>
									<p className="text-gray-600 mb-6">
										Are you sure you want to cancel this game?
									</p>
									<div className="flex gap-4">
										<button
											onClick={onCancel}
											className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors flex items-center justify-center gap-1"
										>
											<FaTimesCircle className="text-sm" />
											Yes
										</button>
										<button
											onClick={() => setShowCancelConfirm(false)}
											className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
										>
											No
										</button>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default GameForm;
