import React, { useState, useEffect } from 'react';

interface EmailVerificationNoticeProps {
  email: string;
  onVerify: () => Promise<boolean>;
  isEmailVerified: boolean;
}

const EmailVerificationNotice: React.FC<EmailVerificationNoticeProps> = ({
  email,
  onVerify,
  isEmailVerified,
}) => {
  const [countdown, setCountdown] = useState(() => {
    const storedCountdown = localStorage.getItem('emailVerificationCountdown');
    const storedTimestamp = localStorage.getItem('emailVerificationTimestamp');
    if (storedCountdown && storedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - parseInt(storedTimestamp)) / 1000);
      const remainingTime = Math.max(0, parseInt(storedCountdown) - elapsedTime);
      return remainingTime;
    }
    return 0;
  });
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      localStorage.setItem('emailVerificationCountdown', countdown.toString());
      localStorage.setItem('emailVerificationTimestamp', Date.now().toString());
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      localStorage.removeItem('emailVerificationCountdown');
      localStorage.removeItem('emailVerificationTimestamp');
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleVerify = async () => {
    setIsVerifying(true);
    const verified = await onVerify();
    if (!verified) {
      setCountdown(60);
    }
    setIsVerifying(false);
  };

  if (isEmailVerified) {
    return null;
  }

  return (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
      <p className="font-semibold">
        Please verify your email address to unlock all features.
      </p>
      <p className="mt-2">
        We've sent a verification link to <strong>{email}</strong>.
      </p>
      <button
        onClick={handleVerify}
        disabled={isVerifying || countdown > 0}
        className={`mt-4 ${
          isVerifying || countdown > 0
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-yellow-500 hover:bg-yellow-600'
        } text-white px-4 py-2 rounded`}
      >
        {isVerifying
          ? 'Verifying...'
          : countdown > 0
          ? `Resend in ${countdown}s`
          : 'Resend Verification Email'}
      </button>
    </div>
  );
};

export default EmailVerificationNotice;
