import React, { useEffect, useRef, useState } from 'react';

interface TurnstileProps {
  siteKey: string;
  onTokenChange: (token: string | null) => void;
  resetKey?: number;
}

interface TurnstileOptions {
  sitekey: string;
  callback: (token: string) => void;
  'expired-callback'?: () => void;
  'error-callback'?: () => void;
}

const Turnstile: React.FC<TurnstileProps> = ({ siteKey, onTokenChange, resetKey }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const widgetId = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded && containerRef.current && window.turnstile) {
      widgetId.current = window.turnstile.render(containerRef.current, {
        sitekey: siteKey,
        callback: (token: string) => {
          onTokenChange(token);
        },
        'expired-callback': () => {
          onTokenChange(null);
        },
        'error-callback': () => {
          onTokenChange(null);
        },
      } as TurnstileOptions);
    }

    return () => {
      if (widgetId.current !== null && window.turnstile) {
        window.turnstile.remove(widgetId.current);
      }
    };
  }, [isScriptLoaded, siteKey, onTokenChange]);

  // Reset the widget whenever resetKey changes
  useEffect(() => {
    if (widgetId.current !== null && window.turnstile) {
      window.turnstile.reset(widgetId.current);
    }
  }, [resetKey]);

  return <div ref={containerRef}></div>;
};

export default Turnstile;
