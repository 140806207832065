import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../models/api';
import TopNavBar from '../components/TopNavBar';
import EmailVerificationNotice from '../components/EmailVerificationNotice';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

const Verify: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');

      if (!code) {
        setVerificationStatus('error');
        setErrorMessage('Invalid verification code.');
        return;
      }

      try {
        await api.get(`/api/v1/user/me/verify?code=${code}`);
        setVerificationStatus('success');
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // User is not logged in, redirect to sign in
          localStorage.setItem('redirectAfterSignIn', `/verify?code=${code}`);
          navigate('/signin');
        } else {
          setVerificationStatus('error');
          setErrorMessage('Failed to verify email. Please try again.');
          // Fetch user email if logged in
          try {
            const response = await api.get('/api/v1/user/me');
            setUserEmail(response.data.email);
          } catch (e) {
            // User is not logged in, handle accordingly
          }
        }
      }
    };

    verifyEmail();
  }, [location.search, navigate]);

  return (
    <div className="min-h-screen bg-gray-100">
      <TopNavBar />
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-3xl font-semibold mb-6 text-center text-gray-800">Email Verification</h1>
          {verificationStatus === 'loading' && (
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-600"></div>
              <p className="mt-4 text-lg text-gray-600">Verifying your email...</p>
            </div>
          )}
          {verificationStatus === 'success' && (
            <div className="flex flex-col items-center">
              <FaCheckCircle className="text-6xl text-green-500 mb-4" />
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Email Verified!</h2>
              <p className="text-lg text-gray-600 text-center">
                Your email has been successfully verified. Redirecting to dashboard...
              </p>
            </div>
          )}
          {verificationStatus === 'error' && (
            <div className="flex flex-col items-center">
              <FaExclamationTriangle className="text-6xl text-red-500 mb-4" />
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Verification Failed</h2>
              <p className="text-lg text-red-600 text-center">{errorMessage}</p>
              {userEmail ? (
                <EmailVerificationNotice
                  email={userEmail}
                  onVerify={() => api.post('/api/v1/user/me/resend-verification')}
                  isEmailVerified={false}
                />
              ) : (
                <button
                  onClick={() => navigate('/profile')}
                  className="mt-6 bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-2 rounded-md transition duration-300"
                >
                  Go to Profile
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verify;
