import React from 'react';
import Header from '../components/Header';

const About: React.FC = () => {
  return (
    <div className="bg-gradient-to-br from-green-700 to-blue-800 text-white min-h-screen py-12 flex flex-col">
      <Header />

      <main className="flex-grow flex flex-col items-center justify-center py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-5xl sm:text-7xl font-extrabold text-white text-center mb-12 font-sans leading-tight">About Kickoff Championship</h1>
          <div className="bg-white text-blue-900 rounded-lg shadow-2xl p-8 max-w-4xl mx-auto">
            <div className="text-center">
              <p className="text-2xl mb-8 font-serif leading-relaxed">
                We are two passionate soccer players from the Bay Area who wanted to determine once and for all who is the best Indian soccer team in the Bay.
              </p>
              <p className="text-xl mb-8 font-sans leading-relaxed">
                Our love for the game and our community inspired us to create the Kickoff Championship Cup. We believe in the power of soccer to bring people together, foster friendly competition, and showcase the incredible talent within our local Indian community.
              </p>
              <p className="text-xl font-sans leading-relaxed italic">
                Join us in this exciting journey as we celebrate our shared passion for soccer and crown the best Indian soccer team in the Bay Area!
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;