import React, { useState } from 'react';

interface TimePickerProps {
  selectedTime: Date | null;
  onChange: (time: Date) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ selectedTime, onChange }) => {
  const [hour, setHour] = useState<number>(
    selectedTime ? ((selectedTime.getHours() + 11) % 12) + 1 : 12
  );
  const [minute, setMinute] = useState<number>(
    selectedTime ? selectedTime.getMinutes() : 0
  );
  const [amPm, setAmPm] = useState<'AM' | 'PM'>(
    selectedTime && selectedTime.getHours() >= 12 ? 'PM' : 'AM'
  );

  const updateTime = (newHour: number, newMinute: number, newAmPm: 'AM' | 'PM') => {
    let hours = newHour % 12;
    if (newAmPm === 'PM') {
      hours += 12;
    }
    if (hours === 24) hours = 0;
    const updatedTime = new Date();
    updatedTime.setHours(hours);
    updatedTime.setMinutes(newMinute);
    updatedTime.setSeconds(0);
    updatedTime.setMilliseconds(0);
    onChange(updatedTime);
  };

  const handleHourInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let value = parseInt(inputValue) || 1;
    
    // Only update and blur if it's a valid hour (1-12)
    if (value >= 1 && value <= 12) {
      setHour(value);
      updateTime(value, minute, amPm);
      if (inputValue.length >= 2) {
        e.target.blur();
      }
    }
  };

  const handleMinuteInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let value = parseInt(inputValue) || 0;
    
    // Only update and blur if it's a valid minute (0-59)
    if (value >= 0 && value <= 59) {
      setMinute(value);
      updateTime(hour, value, amPm);
      if (inputValue.length >= 2) {
        e.target.blur();
      }
    }
  };

  const handleHourScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? -1 : 1;
    let newHour = hour + delta;
    if (newHour < 1) newHour = 12;
    if (newHour > 12) newHour = 1;
    setHour(newHour);
    updateTime(newHour, minute, amPm);
    e.currentTarget.blur();
  };

  const handleMinuteScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? -1 : 1;
    let newMinute = minute + delta;
    if (newMinute < 0) newMinute = 59;
    if (newMinute > 59) newMinute = 0;
    setMinute(newMinute);
    updateTime(hour, newMinute, amPm);
    e.currentTarget.blur();
  };

  return (
    <div className="w-full max-w-sm mx-auto">
      <div className="flex items-center justify-center bg-white rounded-lg">
        {/* Time Inputs */}
        <div className="flex items-center space-x-2">
          {/* Hour Input */}
          <input
            type="number"
            value={hour.toString().padStart(2, '0')}
            onChange={handleHourInput}
            onWheel={handleHourScroll}
            onFocus={(e) => e.target.select()}
            onMouseUp={(e) => e.preventDefault()} // Prevent mouse selection
            onKeyDown={(e) => {
              if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.preventDefault();
              }
            }}
            className="text-5xl font-semibold text-center text-gray-700 font-mono w-24 rounded-md focus:outline-none bg-gray-50 select-all"
            min={1}
            max={12}
          />

          <span className="text-5xl font-semibold mx-2">:</span>

          {/* Minute Input */}
          <input
            type="number"
            value={minute.toString().padStart(2, '0')}
            onChange={handleMinuteInput}
            onWheel={handleMinuteScroll}
            onFocus={(e) => e.target.select()}
            onMouseUp={(e) => e.preventDefault()} // Prevent mouse selection
            onKeyDown={(e) => {
              if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.preventDefault();
              }
            }}
            className="text-5xl font-semibold text-center text-gray-700 font-mono w-24 rounded-md focus:outline-none bg-gray-50 select-all"
            min={0}
            max={59}
          />
        </div>

        {/* AM/PM Selector */}
        <div className="flex flex-col items-center ml-4">
          <button
            onClick={() => {
              setAmPm('AM');
              updateTime(hour, minute, 'AM');
            }}
            className={`text-xl font-semibold focus:outline-none ${
              amPm === 'AM' ? 'text-gray-700' : 'text-gray-300'
            }`}
          >
            AM
          </button>
          <button
            onClick={() => {
              setAmPm('PM');
              updateTime(hour, minute, 'PM');
            }}
            className={`text-xl font-semibold focus:outline-none ${
              amPm === 'PM' ? 'text-gray-700' : 'text-gray-300'
            } mt-1`}
          >
            PM
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
