import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from '../components/TopNavBar';
import EmailVerificationNotice from '../components/EmailVerificationNotice';
import CompleteProfileNotice from '../components/CompleteProfileNotice';
import PersonalInfo from '../components/PersonalInfo';
import AccountSettings from '../components/AccountSettings';
import SecuritySettings from '../components/SecuritySettings';
import PrivacySettings from '../components/PrivacySettings';
import { User } from '../models/user';
import Loading from '../components/Loading';
import CoverPhoto from '../components/CoverPhoto';
import PaymentHistory from '../components/PaymentHistory';
import { useUserProfile } from '../hooks/useUserProfile';

const EditProfilePage: React.FC = () => {
	const [profileComplete, setProfileComplete] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const { userProfile, isLoading } = useUserProfile();

	const handleVerifyEmail = async () => {
		if (!userProfile) return;
		try {
			const user = new User(userProfile);
			await user.sendVerificationEmail();
		} catch (error) {
			console.error('Failed to send verification email:', error);
		}
		return userProfile.email_verified;
	};

	const handleUpdateUserPrivacy = async (updatedFields: Partial<User>) => {
		if (!userProfile) return;
		try {
			const user = new User(userProfile);
			const updatedUser = await user.updateUser(updatedFields);
			// Optionally update the userProfile state if needed
			// setUserProfile(updatedUser);
		} catch (error) {
			console.error('Failed to update user:', error);
			setError('Failed to update user. Please try again.');
		}
	};

	const handleUpdateUserPassword = async (current_password: string, new_password: string) => {
		if (!userProfile) return;
		try {
			const user = new User(userProfile);
			const updatedUser = await user.updatePassword(current_password, new_password);
			// Optionally update the userProfile state if needed
			// setUserProfile(updatedUser);
		} catch (error) {
			console.error('Failed to update password:', error);
			setError('Failed to update password. Please try again.');
		}
	};

	const handleUpdateUserPersonal = async (updatedFields: Partial<User>) => {
		if (!userProfile) return;
		try {
			const user = new User(userProfile);
			const updatedUser = await user.updateUser(updatedFields);
			// Optionally update the userProfile state if needed
			// setUserProfile(updatedUser);
		} catch (error) {
			console.error('Failed to update user:', error);
		}
	};

	const handleUpdateCoverPhoto = async (updatedFields: Partial<User>) => {
		try {
			const updatedUser = await (userProfile as User).updateUser(updatedFields);
			// setUser(updatedUser);
		} catch (error) {
			console.error('Failed to update cover photo:', error);
		}
	};

	const uploadUserImage = async (file: File) => {
		const user = new User(userProfile);
		return await user.uploadUserImage(file);
	};

	if (error) {
		return (
			<div className="min-h-screen bg-gray-50 flex items-center justify-center">
				<p className="text-red-500">{error}</p>
			</div>
		);
	}

	if (!userProfile) {
		return <Loading />;
	}

	return (
		<div className="min-h-screen bg-gray-50">
			<TopNavBar />

			{/* Add Cover Photo Component */}
			{/* <CoverPhoto user={userProfile} onUpdate={handleUpdateCoverPhoto} /> */}

			<div className="container mx-auto px-4 py-8">
				{/* {!userProfile.email_verified && (
					<EmailVerificationNotice email={userProfile.email} isEmailVerified={userProfile.email_verified} onVerify={handleVerifyEmail} />
				)} */}
				{/* {!userProfile.isComplete() && <CompleteProfileNotice user={userProfile} />} */}

				<div className="bg-white rounded-lg shadow-lg p-6">
					{/* <h1 className="text-2xl font-semibold mb-6 text-gray-800">Account Settings</h1> */}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						<PersonalInfo user={userProfile} onUpdate={handleUpdateUserPersonal} uploadUserImage={uploadUserImage} />
						<AccountSettings user={userProfile} />
						<SecuritySettings user={userProfile} onUpdate={handleUpdateUserPassword} />
						<PrivacySettings user={userProfile} onUpdate={handleUpdateUserPrivacy} />
						{/* <PaymentHistory user={userProfile} /> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditProfilePage;
