import React from 'react';
// import Standings from '../components/Standings';
import TournamentFormat from '../components/TournamentFormat';
// import TournamentSchedule from '../components/TournamentSchedule';
// import { TeamsDataAccessor } from '../models/teams';
// Dummy data (replace with API calls in the future)

// const teamsDataAccessor = new TeamsDataAccessor();
// const teams = teamsDataAccessor.teams();

const teams = [
  { id: 0, name: 'Desi Milan', emblem: '🦊' }, //fox
  { id: 1, name: 'GG', emblem: '🐯' },    // tiger
  { id: 2, name: 'Goal Diggers', emblem: '🐺' }, // wolves
  { id: 3, name: 'Hopeful FC', emblem: '🦅' }, // eagle
  { id: 4, name: 'Kroos Control', emblem: '🐴' }, // horse
  { id: 5, name: 'MFC', emblem: '🦁' }, // lion
]

// const fixtures = [
//   { id: 1, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '09:00 AM' },
//   { id: 2, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '10:00 AM' },
//   { id: 3, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '11:00 AM' },
//   { id: 4, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '01:00 PM' },
//   { id: 5, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '02:00 PM' },
//   { id: 6, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '03:00 PM' },
//   { id: 7, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '04:00 PM' },
//   { id: 8, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '05:00 PM' },
//   { id: 9, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '06:00 PM' },
//   { id: 10, homeTeamId: 0, awayTeamId: 0, date: '2024-09-22', time: '07:00 PM' },
// ];

const Tournament: React.FC = () => {
  return (
      // <main className="py-20">
        <section className="bg-white text-blue-900 py-20">
          <div className="container mx-auto px-4 items-center">
            {/* <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-center">Teams</h2> */}
            
            {/* Team Emblems */}
            {/* <div className="flex justify-center space-x-4 mb-12">
              {teams.map(team => (
                <div key={team.id} className="team-emblem w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center text-3xl cursor-pointer relative">
                  <span className="team-name absolute bottom-full left-1/2 transform -translate-x-1/2 bg-black text-white px-2 py-1 rounded text-sm opacity-0 transition-opacity duration-300">{team.name}</span>
                  {team.emblem}
                </div>
              )
              )}
            </div> */}
{/*             
            <div className="flex flex-col items-center space-y-12 mb-12">
              <div className="w-full md:w-3/4 lg:w-2/3">
                <Standings />
              </div> */}

              {/* <div className="w-full md:w-3/4 lg:w-2/3">
                <TournamentSchedule fixtures={fixtures} />
              </div> */}

              <div className="w-full md:w-3/4 lg:w-2/3 mx-auto">
                <TournamentFormat />
              </div>

          </div>
        </section>
      // </main>
    );
};

export default Tournament;