import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../models/user'; 

const RedirectIfAuthenticated: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const checkAuthentication = async () => {
      try {
        await User.fetchCurrentUser();
        if (isMounted) {
            const redirectPath = localStorage.getItem('redirectAfterSignIn');
            if (redirectPath) {
              localStorage.removeItem('redirectAfterSignIn');
              navigate(redirectPath);
            }
        }
      } catch (error) {
        // User is not authenticated; do nothing
      }
    };

    checkAuthentication();

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  return null;
};

export default RedirectIfAuthenticated;
