/**
 * UserFriendsCard Component
 * 
 * This component displays the user's friends list.
 * 
 * Behavior:
 * 1. Shows a skeleton loader (UserFriendsCardSkeleton) if any data is loading.
 * 2. Displays the full profile card when all data is available.
 * 3. Automatically updates with the latest data as it becomes available.
 * 
 * Dependencies:
 * - useUserFriends hook for friends data
 * - UserFriendsCardSkeleton component for loading state
 * 
 * Note: This component will re-render when the friends data
 * is updated asynchronously by the hooks.
 */

import React, { memo, useMemo, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { User } from '../models/user';
import { useUserFriends } from '../hooks/useUserFriends';
import UserFriendsCardSkeleton from './UserFriendsCardSkeleton';
import { useImageLoader } from '../hooks/useImageLoader';
import { Link } from 'react-router-dom';

interface UserFriendsCardProps {
  userId: string;
}

const FriendListItem: React.FC<{ friend: User }> = memo(({ friend }) => {
  const profileImage = useImageLoader(friend.profile_picture_url || null);

  return (
    <li className="mb-2">
      <Link
        to={`/u/${friend.username}`}
        className="flex items-center hover:bg-indigo-100 p-2 rounded-lg w-full"
      >
        {profileImage ? (
          <img
            src={profileImage}
            alt={`${friend.first_name} ${friend.last_name}`}
            className="w-12 h-12 rounded-full object-cover mr-4 border-4 border-gray-200"
          />
        ) : (
          <FaUserCircle className="w-12 h-12 text-gray-400 mr-4 bg-white rounded-full border-4 border-gray-200" />
        )}
        <div className="min-w-0 flex-1">
          <p
            className="text-lg font-medium text-gray-600 truncate"
            title={`${friend.first_name} ${friend.last_name}`}
          >
            {friend.first_name} {friend.last_name}
          </p>
          <p
            className="text-sm text-gray-500 truncate"
            title={`@${friend.username}`}
          >
            @{friend.username}
          </p>
        </div>
      </Link>
    </li>
  );
});

const LoadingSpinner = memo(() => (
  <span className="inline-block w-4 h-4 ml-2">
    <svg className="animate-spin h-4 w-4 text-gray-500" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
        fill="none"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </span>
));

LoadingSpinner.displayName = 'LoadingSpinner';

const FriendsList: React.FC<{ friends: User[] }> = memo(({ friends }) => {
  return (
    <ul className="space-y-2">
      {friends.map((friend) => (
        friend &&
        <FriendListItem key={friend.user_id} friend={friend} />
      ))}
      {friends.length === 0 && (
        <li className="text-gray-500 text-center py-4">No friends yet</li>
      )}
    </ul>
  );
});

FriendsList.displayName = 'FriendsList';

const UserFriendsCard: React.FC<UserFriendsCardProps> = memo(({ userId }) => {
  const { friends, loading } = useUserFriends(userId);

  // Remove the useEffect that calls refresh on mount
  // useEffect(() => {
  //   refresh();
  // }, [refresh]);

  // Only show skeleton loader when loading AND no friends data
  if (loading && friends.length === 0) {
    return <UserFriendsCardSkeleton />;
  }

  return (
    <div className="bg-white rounded-lg shadow-lg mb-6 p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-6 truncate">
        Friends
      </h2>
      <FriendsList friends={friends} />
    </div>
  );
});


UserFriendsCard.displayName = 'UserFriendsCard';
FriendListItem.displayName = 'FriendListItem';

export default UserFriendsCard;
