import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Turnstile from '../components/Turnstile';
import RedirectIfAuthenticated from '../components/RedirectIfAuthenticated';
import api, { apiClient } from '../models/api';

const SignIn: React.FC = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [turnstileResetKey, setTurnstileResetKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    if (!turnstileToken) {
      setErrorMessage('Please complete the Turnstile challenge.');
      return;
    }

    try {
      const response = await api.post(
        '/api/auth/signin',
        {
          username: usernameOrEmail,
          password,
        },
        {
          headers: {
            'cf-turnstile-response': turnstileToken,
          },
        }
      );

      const { access_token, refresh_token } = response.data;

      if (access_token && refresh_token) {
        apiClient.setAccessToken(access_token);
        apiClient.setRefreshToken(refresh_token);
      } else {
        throw new Error('Tokens not received from server.');
      }

      const redirectPath = localStorage.getItem('redirectAfterSignIn') || '/dashboard';
      localStorage.removeItem('redirectAfterSignIn');
      navigate(redirectPath);
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message || 'An error occurred during sign-in.');
      }
      setTurnstileToken('');
      setTurnstileResetKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <div className="bg-gradient-to-br from-green-700 to-blue-800 text-white min-h-screen flex items-center justify-center">
      <RedirectIfAuthenticated />

      <div className="bg-white text-blue-900 p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center">Welcome to Kickoff</h1>
        <p className="text-center mb-8">Sign in to participate in games, tournaments, and leagues</p>

        {errorMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}

        <form onSubmit={handleSignIn} className="mb-6">
          <div className="mb-4 relative">
            <input
              type="text"
              id="usernameOrEmail"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
              required
              placeholder="Username or Email"
            />
          </div>
          <div className="mb-6 relative">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400"
              required
              placeholder="Password"
            />
          </div>

          <div className="mb-6">
            <Turnstile
              siteKey="0x4AAAAAAAjuZz4sYx8SxbrU"
              onTokenChange={setTurnstileToken}
              resetKey={turnstileResetKey}
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-full mb-4"
          >
            Sign In
          </button>
        </form>

        <div className="text-center mb-6">
          <p className="text-sm">
            Don't have an account?{' '}
            <a
              href="/signup"
              onClick={(e) => {
                e.preventDefault();
                navigate('/signup');
              }}
              className="text-blue-500 hover:underline font-semibold"
            >
              Sign Up
            </a>
          </p>
        </div>

        <div className="text-center text-sm">
          By signing in, you agree to our
          <a
            href="/terms"
            onClick={(e) => {
              e.preventDefault();
              navigate('/terms');
            }}
            className="text-blue-500 hover:underline"
          >
            {' '}
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
